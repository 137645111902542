import { useState, useEffect } from 'react'
import { FaRegStar } from 'react-icons/fa'
import { FaStar } from 'react-icons/fa'
import { PiWarningCircleBold } from 'react-icons/pi'
import { useDispatch } from 'react-redux'
import {
  addFabricSample,
  removeFabricSample,
  deleteFabricSample,
} from '../store/slices/fabricSampleSlice'
import { toast } from 'react-toastify'

const FabricSample = ({ fabric }) => {
  const [selectedFabric, setSelectedFabric] = useState(fabric.name)
  const [isSelect, setIsSelect] = useState(false)
  const [selectedColor, setSelectedColor] = useState('')
  const [selectedColorBg, setSelectedColorBg] = useState('')
  const [selectedSample, setSelectedSample] = useState({})

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(deleteFabricSample())
  }, [])

  useEffect(() => {
    setSelectedSample({
      id: fabric._id,
      fabric: selectedFabric,
      color: selectedColor,
      bg: selectedColorBg,
    })
  }, [selectedColor])

  const ChangeColorHandler = ({ colorName, colorBg }) => {
    setSelectedColor(colorName.target.value)
    setSelectedColorBg(colorBg)
  }

  const selectHander = () => {
    if (!isSelect) {
      if (!selectedColor) {
        toast.error('生地の色を選んでください')
        setIsSelect(false)
        return
      }
      setIsSelect(true)
      dispatch(addFabricSample(selectedSample))
    } else {
      setIsSelect(false)
      dispatch(removeFabricSample(fabric._id))
    }
  }

  return (
    <div
      className={`border-2 ${
        isSelect ? 'border-neutral-700' : 'border-neutral-300'
      }  p-5 rounded `}
    >
      <div className="flex items-center justify-between mb-2 border-b border-neutral-300 pb-1">
        <h2 className="text-2xl italic font-bold">{fabric.name}</h2>
        {isSelect ? (
          <FaStar
            onClick={selectHander}
            className="text-yellow-500 cursor-pointer"
          />
        ) : (
          <FaRegStar onClick={selectHander} className="cursor-pointer" />
        )}
      </div>
      <p className="text-sm">{fabric.fabric}</p>
      <p className="text-sm mb-5">重さ：{fabric.weight}g/㎡</p>

      {/* カラー */}
      <div className="mb-2">
        <div className="text-sm mb-2">
          <span>カラー：{selectedColor}</span>
        </div>
        <div className="flex">
          {fabric.colors.map((color) => (
            <div key={color.colorName}>
              <input
                type="radio"
                name={color._id}
                value={color.colorName}
                onChange={(e) =>
                  ChangeColorHandler({ colorName: e, colorBg: color.colorBg })
                }
                id={color._id + color.colorName}
                className="hidden colorBadge"
                checked={color.colorName === selectedColor}
              />
              <label
                htmlFor={color._id + color.colorName}
                style={{ backgroundColor: `${color.colorBg}` }}
                className="relative w-6 h-6 inline-block rounded-full border border-gray-500 mr-2 colorBadge-checked:border-black cursor-pointer"
              >
                <div className="colorChecked2 absolute inset-0 w-[22px] h-[22px] rounded-full border border-gray-700 hidden"></div>
              </label>
            </div>
          ))}
        </div>
      </div>

      <p className="mb-3">
        {fabric.features.map((feature, index) => (
          <span
            key={index}
            className="mr-1 mb-1 inline-block text-xs py-1 px-2 border border-neutral-500 rounded-full"
          >
            {feature}
          </span>
        ))}
      </p>
      <p className="text-sm mb-5">
        対応：
        {fabric.category.map((type, index) => (
          <span
            key={index}
            className="text-sm bg-neutral-200 py-1 px-2 rounded mr-2"
          >
            {type}
          </span>
        ))}
      </p>
      <p className="text-sm mb-5">{fabric.description}</p>
      <div className="text-sm flex">
        <div className="flex justify-center flex-none w-5 mr-3">
          <PiWarningCircleBold className="text-xl" />
        </div>
        <div>{fabric.care}</div>
      </div>
    </div>
  )
}

export default FabricSample
