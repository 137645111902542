import { Link } from 'react-router-dom'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { toast } from 'react-toastify'
import {
  useGetCustomizeTshirtsQuery,
  useCreateCustomizeTshirtMutation,
} from '../../store/apis/customizeTshirtsApi'
import { FaEdit, FaTrash } from 'react-icons/fa'

const CustomizeListScreen = () => {
  const {
    data: customizeTshirts,
    isLoading,
    error,
    refetch,
  } = useGetCustomizeTshirtsQuery()

  const [createCustomizeTshirt, { isLoading: loadingCreate }] =
    useCreateCustomizeTshirtMutation()

  const deleteHandler = (id) => {
    console.log(id)
  }

  const createCustomizeTshirtHandler = async () => {
    if (window.confirm('商品を追加しますか？')) {
      try {
        await createCustomizeTshirt()
        refetch()
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  return (
    <div className="flex justify-center">
      <div className="screen-container mt-5 w-full mb-20">
        <div className="mb-10 flex justify-between items-center">
          <h2 className="page-title">Customize Products</h2>
          <button onClick={createCustomizeTshirtHandler} className="btn btn-sm">
            商品追加
          </button>
        </div>
        {loadingCreate && <Loader />}
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant={'alert-error'}>商品がありません</Message>
        ) : (
          <div className="overflow-x-auto mb-20">
            <table className="table">
              {/* head */}
              <thead>
                <tr>
                  <th></th>
                  <th>商品名</th>
                  <th>素材</th>
                  <th>カラー</th>
                  <th>レビュー数</th>
                  <th>価格</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {customizeTshirts.map((item) => (
                  <tr key={item._id}>
                    <td className="w-20">
                      <img src={item.image} alt="" />
                    </td>
                    <td>{item.name}</td>
                    <td>{item.fabric}</td>
                    <td>{item.colors.length}</td>
                    <td>{item.reviews.length}</td>
                    <td>{item.price.toLocaleString()}円</td>
                    <td>
                      <Link to={`/admin/customize/${item._id}/edit`}>
                        <button className="btn btn-sm btn-ghost text-xs">
                          <FaEdit />
                        </button>
                      </Link>
                      <button
                        onClick={() => deleteHandler(item._id)}
                        className="btn btn-sm btn-ghost text-xs"
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default CustomizeListScreen
