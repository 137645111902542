import { useState, useEffect, useRef } from 'react'

const LengthAdjustment = ({ changedSizeLength, type }) => {
  let length = changedSizeLength

  const [circle, setCircle] = useState(321.9)
  const [line, setLine] = useState(118.5)
  const [side, setSide] = useState(213.1)
  const [hem, setHem] = useState(321.9)
  const [stetch, setStetch] = useState(317.9)

  function usePrevious(value) {
    const ref = useRef(null)
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const prevLength = usePrevious(length)

  useEffect(() => {
    const num = 3
    if (prevLength > changedSizeLength) {
      setCircle((prev) => prev - num)
      setLine((prev) => prev - num)
      setSide((prev) => prev - num)
      setHem((prev) => prev - num)
      setStetch((prev) => prev - num)
    } else if (prevLength < changedSizeLength) {
      setCircle((prev) => prev + num)
      setLine((prev) => prev + num)
      setSide((prev) => prev + num)
      setHem((prev) => prev + num)
      setStetch((prev) => prev + num)
    }
  }, [changedSizeLength, prevLength])

  return type === 'T-shirts' ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="\u30EC\u30A4\u30E4\u30FC_1"
      x={0}
      y={0}
      style={{
        enableBackground: 'new 0 0 450 400',
      }}
      viewBox="0 0 450 400"
    >
      <style>
        {
          '.st0,.st1{fill:none;stroke:#000;stroke-linejoin:round;stroke-miterlimit:10}.st1{stroke-linecap:round}.st2{fill:#aaa}.st3{fill:none;stroke:#aaa;stroke-miterlimit:10}'
        }
      </style>
      <path
        d="M256.3 17.6c2.4 4.7 3.8 10 3.8 15.7 0 18.9-15.3 34.2-34.2 34.2h.3c-18.9 0-34.2-15.3-34.2-34.2 0-5.6 1.4-11 3.8-15.7"
        className="st0"
      />
      <path
        d="M256.3 21.4s-7 7.9-30.3 7.9h.3c-23.3 0-30.3-7.9-30.3-7.9"
        className="st0"
      />
      <path
        d="M261.2 19.4c1.6 4.2 2.7 9.4 2.7 13.9 0 20.8-16.9 37.8-37.7 38h-.3c-20.8-.2-37.7-17.1-37.7-38 0-4.4 1-9.5 2.5-13.8M79.2 128.1l47.1 25.6"
        className="st0"
      />
      <path
        d="m314.1 138.8 14.4 17.6 45.7-24.8c-5-18.5-19.6-52.3-29.8-67.3-12.1-17.8-24.8-23.5-24.8-23.5l-63.2-23.2s-7 7.9-30.3 7.9h.3c-23.3 0-30.3-7.9-30.3-7.9l-63.2 23.2s-12.6 5.7-24.8 23.5c-10.2 15-24.8 48.8-29.8 67.3l45.7 24.8 14.4-17.6M373.1 128.1l-47.2 25.6"
        className="st0"
      />
      <path
        d={`M323.6 51.8s-7.8 30.6-9.1 57c-1.2 26.4 0 213.1 0 ${side}M128.7 51.8s7.8 30.6 9.1 57 0 213.1 0 ${side}M314.5 ${stetch}H137.7`}
        className="st1"
      />
      <circle cx={226.5} cy={25.5} r={2.5} className="st2" />
      <path d="M226.5 25.5v146.9" className="st3" />
      <text
        style={{
          fontSize: 13,
          fontFamily: '&quot',
        }}
        transform="translate(219.285 191.342)"
      >
        {length}
      </text>
      <path d={`M314.5 ${hem}H137.7`} className="st1" />
      <path d={`M226.5 203.4v${line}`} className="st3" />
      <circle cx={226.5} cy={circle} r={2.5} className="st2" />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="\u30EC\u30A4\u30E4\u30FC_1"
      x={0}
      y={0}
      style={{
        enableBackground: 'new 0 0 450 400',
      }}
      viewBox="0 0 450 400"
    >
      <style>
        {
          '.st0,.st1{fill:none;stroke:#000;stroke-linejoin:round;stroke-miterlimit:10}.st1{stroke-linecap:round}.st2{fill:#aaa}.st3{fill:none;stroke:#aaa;stroke-miterlimit:10}'
        }
      </style>
      <path
        d="M256.3 17.6c2.4 4.7 3.8 10 3.8 15.7 0 18.9-15.3 34.2-34.2 34.2h.3c-18.9 0-34.2-15.3-34.2-34.2 0-5.6 1.4-11 3.8-15.7"
        className="st0"
      />
      <path
        d="M256.3 21.4s-7 7.9-30.3 7.9h.3c-23.3 0-30.3-7.9-30.3-7.9"
        className="st0"
      />
      <path
        d="M261.2 19.4c1.6 4.2 2.7 9.4 2.7 13.9 0 20.8-16.9 37.8-37.7 38h-.3c-20.8-.2-37.7-17.1-37.7-38 0-4.4 1-9.5 2.5-13.8"
        className="st0"
      />
      <path
        d="M311 149.2s16 35.8 19.9 62.2c3.5 23.6 10.7 110.5 10.7 110.5l37.3-3s1.9-77.6-4.1-121.3c-6.8-49.9-25.2-107-32.5-130.6-6.4-20.5-22.8-26.3-22.8-26.3l-63.2-23.2s-7 7.9-30.3 7.9h.3c-23.3 0-30.3-7.9-30.3-7.9l-63.2 23.2S116.4 46.4 110 67c-7.3 23.5-25.7 80.7-32.5 130.6-6 43.7-4.1 121.3-4.1 121.3l37.3 3s7.2-86.9 10.7-110.5c3.9-26.3 19.9-62.2 19.9-62.2M341.5 317.9l37.4-3M73.4 314.9l37.3 3"
        className="st0"
      />
      <path
        d={`M323.6 49.8s-10.8 32.6-12.1 59c-1.2 26.4 0 213.1 0 ${side}M128.7 49.8s10.8 32.6 12.1 59c1.2 26.4 0 213.1 0 ${side}M311.5 ${hem}H140.7M311.5 ${stetch}H140.7`}
        className="st1"
      />
      <circle cx={226.5} cy={25.5} r={2.5} className="st2" />
      <path d={`M226.5 25.5v146.9M226.5 203.4v${line}`} className="st3" />
      <text
        style={{
          fontSize: 13,
          fontFamily: '&quot',
        }}
        transform="translate(219.285 191.342)"
      >
        {length}
      </text>
      <circle cx={226.5} cy={circle} r={2.5} className="st2" />
    </svg>
  )
}
export default LengthAdjustment
