import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { changeCustomSlideState } from '../../store/slices/customSlideSlice'
import { useGetCustomizeTshirtsSizeSpecQuery } from '../../store/apis/sizeSpecApi'

const CustomSleeveLength = ({ changeSleeveLength, selectedValue }) => {
  const [selectedSleeveLength, setSelectedSleeveLength] = useState(
    selectedValue.selectedSleeveLength
  )
  const [selectSize, setSelectSize] = useState()
  const [sleevelengthNum, setSleeveLengthNum] = useState()

  const {
    data: sizeSpecTshirts,
    isLoading,
    error,
  } = useGetCustomizeTshirtsSizeSpecQuery()

  const dispatch = useDispatch()

  const changeSleeveLengthHandler = () => {
    changeSleeveLength(selectedSleeveLength)
    dispatch(changeCustomSlideState())
  }

  useEffect(() => {
    if (sizeSpecTshirts) {
      setSelectSize(
        sizeSpecTshirts.find((size) => size.name === selectedValue.selectedSize)
      )
    }
    if (selectSize) {
      setSleeveLengthNum(selectSize.size[0].spec.longSleeve)
    }
  }, [selectedValue, sizeSpecTshirts, selectSize])

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error.data.message}</div>
      ) : (
        <div className="w-full h-full pb-28 lg:pb-10 overflow-y-auto flex flex-col items-center">
          <div className="w-full flex gap-5">
            <div className="text-center mb-10">
              <div className="relative">
                <img
                  src="/images/custom_items/sleeve_50.jpg"
                  alt="ロングTシャツ5分袖"
                  className="mb-3"
                />
                <p className="absolute top-2 left-2 px-2 py-1 bg-white text-sm">
                  <span className="text-xs">袖丈：</span>
                  {Math.round(sleevelengthNum * 0.6)}cm
                </p>
              </div>
              <input
                type="radio"
                name="sleeveLenght"
                id="50"
                value="5分袖"
                onChange={(e) => setSelectedSleeveLength(e.target.value)}
                checked={selectedSleeveLength === '5分袖'}
                className="hidden"
              />
              <label
                className={`btn btn-sm btn-outline rounded-full w-32 border-gray-400 ${
                  selectedSleeveLength === '5分袖' && 'bg-black text-white'
                }`}
                htmlFor="50"
              >
                ５分袖
              </label>
            </div>

            <div className="text-center">
              <div className="relative">
                <img
                  src="/images/custom_items/sleeve_70.jpg"
                  alt="ロングTシャツ7分袖"
                  className="mb-3"
                />
                <p className="absolute top-2 left-2 px-2 py-1 bg-white text-sm">
                  <span className="text-xs">袖丈：</span>
                  {Math.round(sleevelengthNum * 0.75)}cm
                </p>
              </div>
              <input
                type="radio"
                name="sleeveLenght"
                id="70"
                value="7分袖"
                onChange={(e) => setSelectedSleeveLength(e.target.value)}
                checked={selectedSleeveLength === '7分袖'}
                className="hidden"
              />
              <label
                className={`btn btn-sm btn-outline rounded-full w-32 border-gray-400 ${
                  selectedSleeveLength === '7分袖' && 'bg-black text-white'
                }`}
                htmlFor="70"
              >
                ７分袖
              </label>
            </div>
          </div>

          <div className="text-center mb-5">
            <input
              type="radio"
              name="sleeveLenght"
              id="100"
              value="長袖"
              onChange={(e) => setSelectedSleeveLength(e.target.value)}
              checked={selectedSleeveLength === '長袖'}
              className="hidden"
            />
            <label
              className={`btn btn-sm btn-outline rounded-full w-32 border-gray-400 ${
                selectedSleeveLength === '長袖' && 'bg-black text-white'
              }`}
              htmlFor="100"
            >
              長袖
            </label>
          </div>

          <div className="w-full text-center">
            <button
              className="btn btn-primary btn-sm w-72"
              onClick={changeSleeveLengthHandler}
            >
              決定する
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default CustomSleeveLength
