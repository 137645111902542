import { Link } from 'react-router-dom'
import CustomIndexItem from '../components/CustomIndexItem'
import { useGetCustomizeTshirtsQuery } from '../store/apis/customizeTshirtsApi'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Meta from '../components/Meta'

const CustomizeTshirtsScreen = () => {
  const {
    data: customizeTshirts,
    isLoading,
    error,
  } = useGetCustomizeTshirtsQuery()

  return (
    <>
      <Meta title="カスタマイズTシャツ | m&s Fabric" />
      <div className="flex justify-center grow">
        <div className="screen-container mt-5 mb-14 w-full">
          <div className="text-sm breadcrumbs text-gray-500 mb-10">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Customize T-shirts</li>
            </ul>
          </div>
          {isLoading ? (
            <Loader />
          ) : error ? (
            <Message variant={'alert-error'}>{error.data.message}</Message>
          ) : (
            <>
              <h2 className="page-title">Customize Long Sleeve T-shirts</h2>
              <p className="page-description">
                ロングTシャツを生地から選んで、好きな仕様にカスタマイズ
              </p>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {customizeTshirts.map((item) => {
                  if (item.type === 'Long T-shirts') {
                    return <CustomIndexItem item={item} key={item._id} />
                  }
                })}
              </div>

              <h2 className="page-title mt-10">Customize T-shirts</h2>
              <p className="page-description">
                Tシャツを生地から選んで、好きな仕様にカスタマイズ
              </p>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {customizeTshirts.map((item) => {
                  if (item.type === 'T-shirts') {
                    return <CustomIndexItem item={item} key={item._id} />
                  }
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default CustomizeTshirtsScreen
