import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { changeCustomSlideState } from '../../store/slices/customSlideSlice'
import { useGetCustomizeTshirtsSizeSpecQuery } from '../../store/apis/sizeSpecApi'

const CustomStyle = ({ changeStyle, selectedValue, type }) => {
  const [selectedStyle, setSelectedStyle] = useState(
    selectedValue.selectedStyle
  )
  const [selectSize, setSelectSize] = useState()
  const [fitSize, setFitSize] = useState()
  const [tightSize, setTightSize] = useState()
  const [looseSize, setLooseSize] = useState()
  const {
    data: sizeSpecTshirts,
    isLoading,
    error,
  } = useGetCustomizeTshirtsSizeSpecQuery()
  const dispatch = useDispatch()

  useEffect(() => {
    if (sizeSpecTshirts) {
      setSelectSize(
        sizeSpecTshirts.find((size) => size.name === selectedValue.selectedSize)
      )
    }
  }, [selectedValue, sizeSpecTshirts])

  useEffect(() => {
    if (selectSize) {
      selectSize.size.forEach((style) => {
        if (style.name === 'Fit') {
          setFitSize(style)
        } else if (style.name === 'Tight') {
          setTightSize(style)
        } else {
          setLooseSize(style)
        }
      })
    }
  }, [selectSize])

  const changeStyleHandler = () => {
    changeStyle(selectedStyle)
    dispatch(changeCustomSlideState())
  }

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error.data.message}</div>
      ) : (
        <div className="w-full h-full pb-28 lg:pb-10 overflow-y-auto">
          {type === 'T-shirts' ? (
            <div className="mb-3 flex justify-center">
              {selectedStyle === 'Fit' ? (
                <img
                  src="/images/custom_items/style-fit-half.svg"
                  alt="Fit Style"
                  className="w-full lg:w-[70%]"
                />
              ) : selectedStyle === 'Tight' ? (
                <img
                  src="/images/custom_items/style-tight-half.svg"
                  alt="Tight Style"
                  className="w-full lg:w-[70%]"
                />
              ) : (
                <img
                  src="/images/custom_items/style-loose-half.svg"
                  alt="Loose Style"
                  className="w-full lg:w-[70%]"
                />
              )}
            </div>
          ) : (
            <div className="mb-3 flex justify-center">
              {selectedStyle === 'Fit' ? (
                <img
                  src="/images/custom_items/style-fit-long.svg"
                  alt="Fit Style"
                  className="w-full lg:w-[70%]"
                />
              ) : selectedStyle === 'Tight' ? (
                <img
                  src="/images/custom_items/style-tight-long.svg"
                  alt="Tight Style"
                  className="w-full lg:w-[70%]"
                />
              ) : (
                <img
                  src="/images/custom_items/style-loose-long.svg"
                  alt="Loose Style"
                  className="w-full lg:w-[70%]"
                />
              )}
            </div>
          )}

          <div className="overflow-x-auto mb-10">
            <table className="table text-center mt-5">
              {/* head */}
              <thead>
                <tr>
                  <th className="text-black">
                    {selectedValue.selectedSize} size
                  </th>
                  <th>着丈</th>
                  <th>着幅</th>
                  <th>裾幅</th>
                  <th>肩幅</th>
                  <th>脇高</th>
                  <th>袖丈</th>
                  <th>袖幅</th>
                </tr>
              </thead>
              <tbody>
                <tr className={`${selectedStyle === 'Tight' && 'bg-gray-100'}`}>
                  <th>Tight</th>
                  {tightSize && (
                    <>
                      <td>{tightSize.spec.length}</td>
                      <td>{tightSize.spec.chest}</td>
                      <td>{tightSize.spec.hem}</td>
                      <td>{tightSize.spec.shoulder}</td>
                      <td>{tightSize.spec.armpit}</td>
                      <td>
                        {type === 'T-shirts'
                          ? tightSize.spec.sleeve
                          : tightSize.spec.longSleeve}
                      </td>
                      <td>
                        {type === 'T-shirts'
                          ? tightSize.spec.arm
                          : tightSize.spec.wrist}
                      </td>
                    </>
                  )}
                </tr>

                <tr className={`${selectedStyle === 'Fit' && 'bg-gray-100'}`}>
                  <th>Fit</th>
                  {fitSize && (
                    <>
                      <td>{fitSize.spec.length}</td>
                      <td>{fitSize.spec.chest}</td>
                      <td>{fitSize.spec.hem}</td>
                      <td>{fitSize.spec.shoulder}</td>
                      <td>{fitSize.spec.armpit}</td>
                      <td>
                        {type === 'T-shirts'
                          ? fitSize.spec.sleeve
                          : fitSize.spec.longSleeve}
                      </td>
                      <td>
                        {type === 'T-shirts'
                          ? fitSize.spec.arm
                          : fitSize.spec.wrist}
                      </td>
                    </>
                  )}
                </tr>

                <tr className={`${selectedStyle === 'Loose' && 'bg-gray-100'}`}>
                  <th>Loose</th>
                  {looseSize && (
                    <>
                      <td>{looseSize.spec.length}</td>
                      <td>{looseSize.spec.chest}</td>
                      <td>{looseSize.spec.hem}</td>
                      <td>{looseSize.spec.shoulder}</td>
                      <td>{looseSize.spec.armpit}</td>
                      <td>
                        {type === 'T-shirts'
                          ? looseSize.spec.sleeve
                          : looseSize.spec.longSleeve}
                      </td>
                      <td>
                        {type === 'T-shirts'
                          ? looseSize.spec.arm
                          : looseSize.spec.wrist}
                      </td>
                    </>
                  )}
                </tr>
              </tbody>
            </table>
            <p className="text-xs text-right mt-3">
              ※数値は生地の状態や計測方法によって異なる事があります。
            </p>
          </div>
          <div className="flex justify-center mb-10">
            <div className="flex gap-3 w-full lg:w-[70%]">
              <div className="basis-1/3">
                <input
                  type="radio"
                  name="style"
                  id="Tight"
                  value="Tight"
                  onChange={(e) => setSelectedStyle(e.target.value)}
                  checked={selectedStyle === 'Tight'}
                  className="hidden"
                />
                <label
                  className={`btn btn-sm btn-outline rounded-full border-gray-400 w-full ${
                    selectedStyle === 'Tight' && 'bg-black text-white'
                  }`}
                  htmlFor="Tight"
                >
                  Tight
                </label>
              </div>
              <div className="basis-1/3">
                <input
                  type="radio"
                  name="style"
                  id="Fit"
                  value="Fit"
                  onChange={(e) => setSelectedStyle(e.target.value)}
                  checked={selectedStyle === 'Fit'}
                  className="hidden"
                />
                <label
                  className={`btn btn-sm btn-outline rounded-full border-gray-400 w-full ${
                    selectedStyle === 'Fit' && 'bg-black text-white'
                  }`}
                  htmlFor="Fit"
                >
                  Fit
                </label>
              </div>
              <div className="basis-1/3">
                <input
                  type="radio"
                  name="style"
                  id="Loose"
                  value="Loose"
                  onChange={(e) => setSelectedStyle(e.target.value)}
                  checked={selectedStyle === 'Loose'}
                  className="hidden"
                />
                <label
                  className={`btn btn-sm btn-outline rounded-full border-gray-400 w-full ${
                    selectedStyle === 'Loose' && 'bg-black text-white'
                  }`}
                  htmlFor="Loose"
                >
                  Loose
                </label>
              </div>
            </div>
          </div>
          <div className="w-full text-center">
            <button
              className="btn btn-primary btn-sm w-72"
              onClick={changeStyleHandler}
            >
              決定する
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default CustomStyle
