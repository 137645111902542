import { useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toggleMenuState } from '../store/slices/menuSlice'
import { useLogoutMutation } from '../store/apis/usersApi'
import { logout } from '../store/slices/authSlice'

const Header = () => {
  const isChecked = useRef()
  const { isMenuOpen } = useSelector((state) => state.menu)
  const { cartItems } = useSelector((state) => state.cart)
  const { userInfo } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [logoutApiCall] = useLogoutMutation()

  useEffect(() => {
    if (isMenuOpen) {
      isChecked.current.checked = true
    } else {
      isChecked.current.checked = false
    }
  }, [isMenuOpen])

  const clickHandler = () => {
    dispatch(toggleMenuState())
  }

  const logoutHanlder = async () => {
    try {
      await logoutApiCall().unwrap()
      dispatch(logout())
      navigate('/login')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div
      data-theme="black"
      className="absolute inset-0 h-14 z-40 flex items-center justify-center text-white"
    >
      <div className="screen-container navbar justify-between">
        <div className="md:w-60">
          <label className="swap swap-rotate">
            {/* this hidden checkbox controls the state */}
            <input ref={isChecked} type="checkbox" onClick={clickHandler} />

            <svg
              className="swap-off fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 512 512"
            >
              <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
            </svg>

            {/* close icon */}
            <svg
              className="swap-on fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 512 512"
            >
              <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
            </svg>
          </label>
        </div>
        <div className="flex-none">
          <Link
            to="/"
            className={`text-2xl font-bold italic pt-1 ${
              isMenuOpen && 'pointer-events-none'
            }`}
          >
            m&s Fabric
          </Link>
        </div>
        <div className="flex-none flex justify-end md:w-60">
          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="btn btn-ghost btn-circle">
              <div className="indicator">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-7 w-7"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
                {cartItems.length > 0 && (
                  <span
                    data-theme="lofi"
                    className="badge badge-sm indicator-item rounded-full"
                  >
                    {cartItems.reduce((acc, item) => acc + Number(item.qty), 0)}
                  </span>
                )}
              </div>
            </label>
            <div
              tabIndex={0}
              className="mt-3 z-[1] card card-compact dropdown-content w-52 bg-base-100 shadow"
            >
              <div data-theme="lofi" className="card-body">
                <p className="text-sm">カートの内容</p>
                <ul>
                  <li>
                    商品：
                    <span className="text-lg">
                      {cartItems.reduce(
                        (acc, item) => acc + Number(item.qty),
                        0
                      )}
                    </span>
                    点
                  </li>
                  <li className="text-gray-700">
                    小計：
                    <span className="text-lg">
                      {cartItems
                        .reduce((a, c) => a + c.price, 0)
                        .toLocaleString()}
                    </span>
                    円
                  </li>
                </ul>

                <div className="card-actions">
                  <Link to="/cart" className="btn btn-sm btn-primary btn-block">
                    カートを見る
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {userInfo ? (
            <div className="dropdown dropdown-end ml-5 mt-1 hidden md:block">
              <label tabIndex={0} className="cursor-pointer">
                <div className="text-sm">
                  {userInfo.lastname} {userInfo.firstname} 様
                </div>
              </label>
              <ul
                tabIndex={0}
                className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52"
              >
                <li>
                  <Link to="/profile" className="justify-between my-3">
                    マイページ
                  </Link>
                </li>
                <li className="mb-3">
                  <span onClick={logoutHanlder}>ログアウト</span>
                </li>
              </ul>
            </div>
          ) : (
            <Link
              to="/login"
              className={`hidden md:block md:ml-5 text-sm ${
                isMenuOpen && 'pointer-events-none'
              }`}
            >
              ログイン
            </Link>
          )}
          {userInfo && userInfo.isAdmin && (
            <div className="dropdown dropdown-end ml-5 mt-1 hidden md:block">
              <label tabIndex={0} className="cursor-pointer">
                <div className="text-sm">管理者</div>
              </label>
              <ul
                tabIndex={0}
                className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52"
              >
                <li>
                  <Link to="/admin/userlist" className="justify-between my-3">
                    顧客一覧
                  </Link>
                </li>
                <li>
                  <Link to="/admin/orderlist" className="justify-between my-3">
                    注文一覧
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/admin/productlist"
                    className="justify-between my-3"
                  >
                    商品一覧
                  </Link>
                </li> */}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
