import { Link } from 'react-router-dom'
import Meta from '../components/Meta'

const TransactionLawScreen = () => {
  return (
    <>
      <Meta title="特定商取引法に基づく表記 | m&s Fabric" />
      <div className="flex justify-center grow">
        <div className="screen-container mt-5 mb-14 w-full">
          <div className="text-sm breadcrumbs text-gray-500 mb-10">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>特定商取引法に基づく表記</li>
            </ul>
          </div>
          <div className="container my-14 lg:my-20 flex justify-center">
            <div className="w-full lg:w-[768px]">
              <h2 className="text-2xl font-bold mb-14">
                特定商取引法に基づく表記
              </h2>
              <div className="overflow-x-auto ">
                <table className="table">
                  <tbody className="transaction-law">
                    <tr>
                      <th className="w-36 md:w-48">販売業社の名称</th>
                      <td>松尾 孝宏</td>
                    </tr>
                    <tr>
                      <th>販売店舗</th>
                      <td>m&s Fabric</td>
                    </tr>
                    <tr>
                      <th>所在地</th>
                      <td>
                        〒261-0023 千葉県千葉市美浜区中瀬1-3
                        幕張テクノガーデンCB棟3階 MBP
                      </td>
                    </tr>
                    <tr>
                      <th>電話番号</th>
                      <td>請求があった場合は遅滞なく開示します</td>
                    </tr>
                    <tr>
                      <th>URL</th>
                      <td>https://mnsfabric.com</td>
                    </tr>
                    <tr>
                      <th>メールアドレス</th>
                      <td>
                        <img
                          className="h-3"
                          src="/images/mailaddress.svg"
                          alt="email"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>運営統括責任者</th>
                      <td>松尾 孝宏</td>
                    </tr>
                    <tr>
                      <th>販売責任者</th>
                      <td>斉藤 博夫</td>
                    </tr>
                    <tr>
                      <th>追加手数料等の追加料金</th>
                      <td>配送料、銀行振込手数料</td>
                    </tr>
                    <tr>
                      <th>
                        交換および返品
                        <br />
                        （返金ポリシー）
                      </th>
                      <td>
                        <p className="mb-1">＜お客様都合の返品・交換の場合＞</p>
                        <p>
                          【製作前の商品】：メールにてご連絡いただき、進行状況の確認後、製作工程に入っていなけばキャンセルの手続きをさせていただきます。
                        </p>
                        <p>
                          【製作後の商品】：商品は全て受注生産・オーダーメイド商品のため、返品・交換はお断りいただいております。
                        </p>
                        <p className="mt-3 mb-1">＜商品に不備がある場合＞</p>
                        当社の送料負担にて返金又は新しい商品と交換いたします。まずはメールでご連絡ください。
                      </td>
                    </tr>
                    <tr>
                      <th>引渡時期</th>
                      <td>
                        お支払い確認後、通常土日を除く10日以内に発送いたします。注文が集中した場合や注文数によって異なる場合がありますので予めご了承ください。発送準備が整い次第、改めてお届け日のご連絡をいたします。
                      </td>
                    </tr>
                    <tr>
                      <th>受け付け可能な決済手段</th>
                      <td>クレジットカードまたは国内の銀行振込</td>
                    </tr>
                    <tr>
                      <th>決済期間</th>
                      <td>
                        クレジットカード決済の場合はただちに処理されますが、国内の銀行振込の場合は注文から７日以内にお振り込みいただく必要があります。
                      </td>
                    </tr>
                    <tr>
                      <th>販売価格</th>
                      <td>商品ごとの表示金額（税込）となります。</td>
                    </tr>
                  </tbody>
                </table>
                <h3 className="text-lg font-bold mt-20 border-b border-gray-300 mb-5">
                  個人情報保護ポリシーについて
                </h3>
                <p className="text-sm mb-5">
                  当店では個人情報について以下の取り組みを厳正に実施致しております。
                </p>
                <table className="table">
                  <tbody className="transaction-law">
                    <tr>
                      <th className="w-36 md:w-48">収集の目的</th>
                      <td>
                        ご注文いただいた商品の発送に必要な情報（お名前、
                        住所、電話番号、メールアドレス等）、オーダーメイドTシャツ製作に必要なサイズ情報に限り収集させて頂きます。
                      </td>
                    </tr>
                    <tr>
                      <th>情報の利用</th>
                      <td>
                        上記の目的以外には、お客様の有益と思われる当店のサービスやキャンペーンのお知らせ等をメールにてお知らせする場合がございます。ただし、お客様より中止の申し出があった場合、即刻送信を中止致します。
                        また収集した個人情報はお客様の承諾無く第三者に提供、開示は一切致しません。
                      </td>
                    </tr>
                    <tr>
                      <th>管理責任</th>
                      <td>
                        収集した個人情報は当社の厳正なる管理下において責任を持って保管し、当店以外の者には閲覧、持ち出しを一切禁止致します。
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TransactionLawScreen
