import { createSlice } from '@reduxjs/toolkit'

const initialState = localStorage.getItem('tailor')
  ? JSON.parse(localStorage.getItem('tailor'))
  : { tailorItems: {}, tailorRepeat: {} }

export const tailorSpecSlice = createSlice({
  name: 'tailorSpec',
  initialState,
  reducers: {
    changeTailorSpec: (state, action) => {
      const item = action.payload
      state.tailorItems = { ...state.tailorItems, ...item }
      localStorage.setItem('tailor', JSON.stringify(state))
    },
    changeTailorSpecForRepeat: (state, action) => {
      const item = action.payload
      state.tailorRepeat = { ...state.tailorRepeat, ...item }
      localStorage.setItem('tailor', JSON.stringify(state))
    },
    clearTailorItems: (state) => {
      state.tailorItems = {}
      localStorage.setItem('tailor', JSON.stringify(state))
    },
  },
})

export const { changeTailorSpec, clearTailorItems, changeTailorSpecForRepeat } =
  tailorSpecSlice.actions

export default tailorSpecSlice.reducer
