import { Link } from 'react-router-dom'

const PaginateOrders = ({ pages, page }) => {
  return (
    pages > 1 && (
      <div className="join">
        {[...Array(pages).keys()].map((x) => (
          <Link key={x + 1} to={`/admin/orderlist/page/${x + 1}`}>
            <button
              className={`join-item btn ${x + 1 === page ? 'btn-active' : ''}`}
            >
              {x + 1}
            </button>
          </Link>
        ))}
      </div>
    )
  )
}

export default PaginateOrders
