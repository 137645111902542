import { IoIosStar, IoIosStarHalf, IoIosStarOutline } from 'react-icons/io'

const Rating = ({ value, total, starColor, textColor }) => {
  return (
    <div className={`flex items-center text-md ${starColor}`}>
      <span>
        {value >= 1 ? (
          <IoIosStar />
        ) : value >= 0.5 ? (
          <IoIosStarHalf />
        ) : (
          <IoIosStarOutline />
        )}
      </span>
      <span>
        {value >= 2 ? (
          <IoIosStar />
        ) : value >= 1.5 ? (
          <IoIosStarHalf />
        ) : (
          <IoIosStarOutline />
        )}
      </span>
      <span>
        {value >= 3 ? (
          <IoIosStar />
        ) : value >= 2.5 ? (
          <IoIosStarHalf />
        ) : (
          <IoIosStarOutline />
        )}
      </span>
      <span>
        {value >= 4 ? (
          <IoIosStar />
        ) : value >= 3.5 ? (
          <IoIosStarHalf />
        ) : (
          <IoIosStarOutline />
        )}
      </span>
      <span>
        {value >= 5 ? (
          <IoIosStar />
        ) : value >= 4.5 ? (
          <IoIosStarHalf />
        ) : (
          <IoIosStarOutline />
        )}
      </span>

      {total ? (
        <span className={`ml-2 ${textColor}`}>
          {total} <span className="text-xs">レビュー</span>
        </span>
      ) : total === 0 ? (
        <span className={`ml-2 ${textColor}`}>
          0 <span className="text-xs">レビュー</span>
        </span>
      ) : (
        <span></span>
      )}
    </div>
  )
}

export default Rating
