const SizeChartLong = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="\u30EC\u30A4\u30E4\u30FC_1"
    x={0}
    y={0}
    style={{
      enableBackground: 'new 0 0 450 400',
    }}
    viewBox="0 0 450 400"
  >
    <style>
      {
        '.st1{stroke:#000;stroke-linejoin:round}.st1,.st2,.st3{fill:none;stroke-miterlimit:10}.st2{stroke:#000;stroke-linecap:round;stroke-linejoin:round}.st3{stroke:#aaa}.st4{font-family:"NotoSansCJKjp-Regular-83pv-RKSJ-H"}.st5{font-size:13px}.st6{fill:#aaa}'
      }
    </style>
    <path
      d="M311 179.2c-.2 63.1.5 172.7.5 172.7H140.7s.7-109.6.5-172.7c0 0-16 35.8-19.9 62.2-3.5 23.6-10.7 110.5-10.7 110.5l-37.3-3s-1.9-77.6 4.1-121.3c6.8-49.9 25.2-107 32.5-130.6 6.4-20.5 22.8-26.3 22.8-26.3l63.2-23.2s7 7.9 30.3 7.9h-.3c23.3 0 30.3-7.9 30.3-7.9l63.2 23.2s16.4 5.7 22.8 26.3c7.3 23.5 25.7 80.7 32.5 130.6 6 43.7 4.1 121.3 4.1 121.3l-37.3 3s-7.2-86.9-10.7-110.5C327 215 311 179.2 311 179.2z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M256.3 47.6c2.4 4.7 3.8 10 3.8 15.7 0 18.9-15.3 34.2-34.2 34.2h.3c-18.9 0-34.2-15.3-34.2-34.2 0-5.6 1.4-11 3.8-15.7"
      className="st1"
    />
    <path
      d="M256.3 51.4s-7 7.9-30.3 7.9h.3c-23.3 0-30.3-7.9-30.3-7.9"
      className="st1"
    />
    <path
      d="M261.2 49.4c1.6 4.2 2.7 9.4 2.7 13.9 0 20.8-16.9 37.8-37.7 38h-.3c-20.8-.2-37.7-17.1-37.7-38 0-4.4 1-9.5 2.5-13.8"
      className="st1"
    />
    <path
      d="M311 179.2s16 35.8 19.9 62.2c3.5 23.6 10.7 110.5 10.7 110.5l37.3-3s1.9-77.6-4.1-121.3c-6.8-49.9-25.2-107-32.5-130.6-6.4-20.5-22.8-26.3-22.8-26.3l-63.2-23.2s-7 7.9-30.3 7.9h.3c-23.3 0-30.3-7.9-30.3-7.9l-63.2 23.2S116.4 76.4 110 97c-7.3 23.5-25.7 80.7-32.5 130.6-6 43.7-4.1 121.3-4.1 121.3l37.3 3s7.2-86.9 10.7-110.5c3.9-26.3 19.9-62.2 19.9-62.2M341.5 347.9l37.4-3M73.4 344.9l37.3 3"
      className="st1"
    />
    <path
      d="M323.6 79.8s-10.8 32.6-12.1 59c-1.2 26.4 0 213.1 0 213.1H140.7s1.2-186.7 0-213.1c-1.2-26.4-12.1-59-12.1-59M311.5 347.9H140.7"
      className="st2"
    />
    <path
      d="M336.1 74.8h75.5M374 169.1h37.6M129.1 30.2h77.4M245 30.2h78.5M397.6 72v38.4M397.6 136.7v32.8M127.6 15.2v34.3M140.7 356v30M311.5 356v30M70 383.4l1.8-29.9M109.9 385.8l1.8-30M77.7 68.2l28.8 8.2M29 345.1l29.9 2.6M323.5 15v44.2M111.2 364.6l-39.9-2.4M141.2 370.9h64.5M247.7 370.9h63.8"
      className="st3"
    />
    <text className="st4 st5" transform="translate(389.877 127.824)">
      {size.armpit}
    </text>
    <text className="st4 st5" transform="translate(218.785 35.308)">
      {size.shoulder}
    </text>
    <text className="st4 st5" transform="translate(219.878 376.308)">
      {size.hem}
    </text>
    <text className="st4 st5" transform="translate(79.315 379.842)">
      {size.wrist}
    </text>
    <circle cx={127.6} cy={30.5} r={2.5} className="st6" />
    <circle cx={92.8} cy={72.5} r={2.5} className="st6" />
    <circle cx={323.5} cy={30.5} r={2.5} className="st6" />
    <circle cx={44} cy={346.4} r={2.5} className="st6" />
    <circle cx={71.3} cy={362.2} r={2.5} className="st6" />
    <circle cx={111.2} cy={364.6} r={2.5} className="st6" />
    <circle cx={397.5} cy={169.5} r={2.5} className="st6" />
    <circle cx={397.5} cy={74.5} r={2.5} className="st6" />
    <circle cx={140.7} cy={370.5} r={2.5} className="st6" />
    <circle cx={311.5} cy={370.5} r={2.5} className="st6" />
    <circle cx={226.5} cy={55.5} r={2.5} className="st6" />
    <path d="M226.5 55.5v146.9M226.5 233.4v118.5" className="st3" />
    <text className="st4 st5" transform="translate(219.285 221.342)">
      {size.length}
    </text>
    <text className="st4 st5" transform="translate(46.373 205.713)">
      {size.longSleeve}
    </text>
    <circle cx={226.5} cy={351.9} r={2.5} className="st6" />
    <path d="M141.2 168.9h22.1M194.1 168.9H311" className="st3" />
    <text className="st4 st5" transform="translate(171.878 174.307)">
      {size.chest}
    </text>
    <circle cx={141.2} cy={168.9} r={2.5} className="st6" />
    <circle cx={311} cy={168.9} r={2.5} className="st6" />
    <path
      d="M51.1 215.9C44.6 255 44 292.7 44 346.4M92.8 72.5S71 119.1 56.2 189.1"
      className="st3"
    />
  </svg>
)
export default SizeChartLong
