const registarMail = (userInfo) => {
  return `
  この度はm&s Fabricに会員登録いただき誠にありがとうございます。
  以下の内容で会員の登録を受け付けさせていただきました。

  【お名前】
  ${userInfo.lastname} ${userInfo.firstname}

  【フリガナ】
  ${userInfo.lastnameYomi} ${userInfo.firstnameYomi}

  【メールアドレス】
  ${userInfo.email}
  `
}

export { registarMail }
