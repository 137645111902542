import { rootApi } from './rootApi'
import { SENDMAIL_URL } from '../../constants'

export const sendMailApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    sendMail: builder.mutation({
      query: (msg) => ({
        url: SENDMAIL_URL,
        method: 'POST',
        body: msg,
      }),
    }),
  }),
})

export const { useSendMailMutation } = sendMailApi
