import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { saveShippingAddress } from '../store/slices/cartSlice'
import { saveShippingPrice } from '../store/slices/cartSlice'
import { useGetShippingCostQuery } from '../store/apis/shippingCostApi'
import Message from '../components/Message'
import Meta from '../components/Meta'

const ShippingScreen = () => {
  const { userInfo } = useSelector((state) => state.auth)
  const cart = useSelector((state) => state.cart)
  const { shippingAddress, cartItems } = cart

  const [postalCode, setPostalCode] = useState(
    shippingAddress?.postalCode || ''
  )
  const [prefecture, setPrefecture] = useState(
    shippingAddress?.prefecture || '都道府県を選択してください'
  )
  const [city, setCity] = useState(shippingAddress?.city || '')
  const [address, setAddress] = useState(shippingAddress?.address || '')
  const [building, setBuilding] = useState(shippingAddress?.building || '')
  const [phoneNum, setPhoneNum] = useState(shippingAddress?.phoneNum || '')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { data: shippingCostApi } = useGetShippingCostQuery(prefecture)

  useEffect(() => {
    const calcShippingCost = async () => {
      if (shippingCostApi) {
        const res = await shippingCostApi.find((area) => {
          if (prefecture === '都道府県を選択してください') {
            return area.name === '東京都'
          } else {
            return area.name === prefecture
          }
        })
        dispatch(saveShippingPrice(res.cost))
      }
    }

    calcShippingCost()
  }, [prefecture, dispatch, shippingCostApi])

  const shippingHandler = (e) => {
    e.preventDefault()

    dispatch(
      saveShippingAddress({
        postalCode,
        prefecture,
        city,
        address,
        building,
        phoneNum,
      })
    )
    navigate('/payment')
  }

  // Add to cart for Google Ad
  const script = `gtag('event', 'conversion', {'send_to': 'AW-16646522219/plZtCIH_w8cZEOuK14E-'});`

  return (
    <>
      <Meta title="配送情報 | m&s Fabric" script={script} />
      <div className="flex justify-center">
        <div className="screen-container mt-5 w-full">
          <div className="text-sm breadcrumbs text-gray-500 mb-10">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/cart">Shopping Cart</Link>
              </li>
              <li>Shipping Address</li>
            </ul>
          </div>
          <h2 className="page-title">Shipping Address</h2>
          <p className="page-description">配送先情報</p>
          <form
            onSubmit={shippingHandler}
            className="flex flex-col lg:flex-row gap-10 mb-20"
          >
            <div className="basis-3/5">
              <h2 className="mb-5 font-bold">ご注文者</h2>
              {userInfo && (
                <ul className="text-sm/8 mb-10">
                  <li>
                    {userInfo.lastname} {userInfo.firstname}（
                    {userInfo.lastnameYomi} {userInfo.firstnameYomi}） 様
                  </li>
                  <li>{userInfo.email}</li>
                </ul>
              )}
              <h2 className="mb-5 font-bold">配送先</h2>
              <div>
                <div className="flex flex-row gap-5">
                  <label className="form-control mb-3 basis-1/2">
                    <div className="label">
                      <span className="label-text">郵便番号</span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full text-sm"
                      minLength="7"
                      maxLength="8"
                      autoComplete="postal-code"
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                      required
                    />
                  </label>
                  <label className="form-control mb-3 basis-1/2">
                    <div className="label">
                      <span className="label-text">都道府県</span>
                    </div>
                    <select
                      className="select select-bordered"
                      value={prefecture}
                      onChange={(e) => setPrefecture(e.target.value)}
                      autoComplete="address-level1"
                      required
                    >
                      <option disabled>都道府県を選択してください</option>
                      <option value="北海道">北海道</option>
                      <option value="青森県">青森県</option>
                      <option value="岩手県">岩手県</option>
                      <option value="宮城県">宮城県</option>
                      <option value="秋田県">秋田県</option>
                      <option value="山形県">山形県</option>
                      <option value="福島県">福島県</option>
                      <option value="茨城県">茨城県</option>
                      <option value="栃木県">栃木県</option>
                      <option value="群馬県">群馬県</option>
                      <option value="埼玉県">埼玉県</option>
                      <option value="千葉県">千葉県</option>
                      <option value="東京都">東京都</option>
                      <option value="神奈川県">神奈川県</option>
                      <option value="新潟県">新潟県</option>
                      <option value="富山県">富山県</option>
                      <option value="石川県">石川県</option>
                      <option value="福井県">福井県</option>
                      <option value="山梨県">山梨県</option>
                      <option value="長野県">長野県</option>
                      <option value="岐阜県">岐阜県</option>
                      <option value="静岡県">静岡県</option>
                      <option value="愛知県">愛知県</option>
                      <option value="三重県">三重県</option>
                      <option value="滋賀県">滋賀県</option>
                      <option value="京都府">京都府</option>
                      <option value="大阪府">大阪府</option>
                      <option value="兵庫県">兵庫県</option>
                      <option value="奈良県">奈良県</option>
                      <option value="和歌山県">和歌山県</option>
                      <option value="鳥取県">鳥取県</option>
                      <option value="島根県">島根県</option>
                      <option value="岡山県">岡山県</option>
                      <option value="広島県">広島県</option>
                      <option value="山口県">山口県</option>
                      <option value="徳島県">徳島県</option>
                      <option value="香川県">香川県</option>
                      <option value="愛媛県">愛媛県</option>
                      <option value="高知県">高知県</option>
                      <option value="福岡県">福岡県</option>
                      <option value="佐賀県">佐賀県</option>
                      <option value="長崎県">長崎県</option>
                      <option value="熊本県">熊本県</option>
                      <option value="大分県">大分県</option>
                      <option value="宮崎県">宮崎県</option>
                      <option value="鹿児島県">鹿児島県</option>
                      <option value="沖縄県">沖縄県</option>
                    </select>
                  </label>
                </div>
                <div className="flex flex-col lg:flex-row lg:gap-5">
                  <label className="form-control w-full mb-3 basis-1/2">
                    <div className="label">
                      <span className="label-text">市区町村</span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full text-sm"
                      autoComplete="address-level2"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      required
                    />
                  </label>
                  <label className="form-control w-full mb-3 basis-1/2">
                    <div className="label">
                      <span className="label-text">町名・番地</span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full text-sm"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      autoComplete="address-line1"
                      required
                    />
                  </label>
                </div>
                <div className="flex flex-col lg:flex-row lg:gap-5">
                  <label className="form-control w-full mb-3 basis-1/2">
                    <div className="label">
                      <span className="label-text">
                        建物（マンション・部屋番号）
                      </span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full text-sm"
                      autoComplete="address-line2"
                      value={building}
                      onChange={(e) => setBuilding(e.target.value)}
                    />
                  </label>
                  <label className="form-control w-full mb-3 basis-1/2">
                    <div className="label">
                      <span className="label-text">電話番号</span>
                    </div>
                    <input
                      type="tel"
                      minLength="10"
                      className="input input-bordered w-full text-sm"
                      value={phoneNum}
                      onChange={(e) => setPhoneNum(e.target.value)}
                      required
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="basis-2/5 bg-gray-100 p-5 flex flex-col justify-between">
              <div className="text-sm">
                {cartItems.map((item) => {
                  if (item.category === 'Tailor T-shirts' && item.isNew) {
                    return (
                      <div
                        className="flex flex-col w-full border-b border-gray-300 pb-5 mb-5"
                        key={item.cartId}
                      >
                        <div className="flex gap-5">
                          <div className="flex-none w-20">
                            <Link to="/tailor-tshirts/body">
                              <img src={item.image} alt={item.name} />
                            </Link>
                          </div>
                          <div className="flex-1">
                            <p className="text-xs rounded-full inline-block px-2 bg-black text-white">
                              {item.category}
                            </p>
                            <h3 className="text-lg font-bold italic">
                              {item.name}
                            </h3>
                            <p className="mb-2">
                              <span className="text-xs">価格：</span>
                              {item.price.toLocaleString()}
                              <span className="text-xs">円（税込）</span>
                            </p>
                            <ul className="text-sm">
                              <li className="inline-block mr-2">
                                カラー：{item.color}、
                              </li>
                              <li className="inline-block mr-2">
                                サイズ：オーダーメイド、
                              </li>
                              <li className="inline-block mr-2">
                                ネック：{item.neck}、
                              </li>
                              <li className="inline-block mr-2">
                                ネックリブ{item.neckLib}cm
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )
                  } else if (
                    item.category === 'Tailor T-shirts' &&
                    !item.isNew
                  ) {
                    return (
                      <div
                        className="flex flex-col w-full border-b border-gray-300 pb-5 mb-5"
                        key={item.cartId}
                      >
                        <div className="flex gap-5">
                          <div className="flex-none w-20">
                            <Link to={`/tailor-tshirts/${item._id}`}>
                              <img src={item.image} alt={item.name} />
                            </Link>
                          </div>
                          <div cl className="flex-1">
                            <p className="text-xs rounded-full inline-block px-2 bg-black text-white">
                              {item.category} / Repeat
                            </p>
                            <h3 className="text-lg font-bold italic">
                              {item.name}
                            </h3>
                            <p className="mb-2">
                              <span className="text-xs">価格：</span>
                              {item.price.toLocaleString()}
                              <span className="text-xs">円（税込）</span>
                            </p>
                            <ul className="text-sm">
                              <li className="inline-block mr-2">
                                枚数：{item.qty}、
                              </li>
                              <li className="inline-block mr-2">
                                カラー：{item.color}、
                              </li>
                              <li className="inline-block mr-2">
                                サイズ：{item.size}、
                              </li>
                              <li className="inline-block mr-2">
                                タグ：{item.tag}、
                              </li>
                              <li className="inline-block mr-2">
                                リブ：{item.lib}、
                              </li>
                              <li className="inline-block mr-2">
                                ポケット：
                                {item.pocket.pocketType !== '付けない' &&
                                  item.pocket.pocketPosition}
                                {item.pocket.pocketType !== '付けない' && (
                                  <span> / </span>
                                )}
                                {item.pocket.pocketType}、
                              </li>
                              <li className="inline-block mr-2">
                                名入れ：
                                {item.embroidery.isEmbroidery !== '無し' &&
                                  item.embroidery.embroideryChar}
                                {item.embroidery.isEmbroidery !== '無し' && (
                                  <span> / </span>
                                )}
                                {item.embroidery.isEmbroidery !== '無し' &&
                                  item.embroidery.embroideryColor}
                                {item.embroidery.isEmbroidery === '無し' &&
                                  item.embroidery.isEmbroidery}
                                、
                              </li>
                              <li className="inline-block mr-2">
                                サイズ変更：
                                {item.fixSize === '' ? 'なし' : 'あり'}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )
                  } else if (item.category === 'Customize T-shirts') {
                    return (
                      <div
                        className="flex flex-col w-full border-b border-gray-300 pb-5 mb-5"
                        key={item.cartId}
                      >
                        <div className="flex gap-5">
                          <div className="flex-none w-20">
                            <Link to={`/customize-tshirts/${item._id}`}>
                              <img src={item.image} alt={item.name} />
                            </Link>
                          </div>
                          <div className="flex-1">
                            <p className="text-xs border border-gray-400 rounded-full inline-block px-2 bg-white">
                              {item.category}
                            </p>
                            <h3 className="text-lg font-bold italic">
                              {item.name}
                            </h3>
                            <p className="mb-2">
                              <span className="text-xs">価格：</span>
                              {item.price.toLocaleString()}
                              <span className="text-xs">円（税込）</span>
                            </p>
                            <ul className="text-sm">
                              <li className="inline-block mr-2">
                                枚数：{item.qty}、
                              </li>
                              <li className="inline-block mr-2">
                                カラー：{item.color}、
                              </li>
                              <li className="inline-block mr-2">
                                サイズ：{item.size}、
                              </li>
                              <li className="inline-block mr-2">
                                ネック：{item.neck}、
                              </li>
                              <li className="inline-block mr-2">
                                スタイル：{item.style}、
                              </li>
                              <li className="inline-block mr-2">
                                着丈：{item.length}、
                              </li>
                              {item.type === 'Long T-shirts' && (
                                <li className="inline-block mr-2">
                                  袖丈：{item.sleeveLength}、
                                </li>
                              )}
                              <li className="text-[13px] inline-block mr-2">
                                リブ：{item.lib}、
                              </li>
                              <li className="text-[13px] inline-block mr-2">
                                ポケット：
                                {item.pocket.pocketType !== '付けない' &&
                                  item.pocket.pocketPosition}
                                {item.pocket.pocketType !== '付けない' && (
                                  <span> / </span>
                                )}
                                {item.pocket.pocketType}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )
                  } else {
                    return <Message>該当しない商品です</Message>
                  }
                })}
              </div>

              <div>
                <div className="text-right mb-5">
                  <ul className="text-sm">
                    <li>
                      小計：
                      <span className="text-xl mr-1">
                        {cartItems
                          .reduce(
                            (acc, item) => acc + Number(item.qty) * item.price,
                            0
                          )
                          .toLocaleString()}
                      </span>
                      円
                    </li>
                    {cart.shippingPrice && (
                      <li>
                        配送料：
                        <span className="text-xl mr-1">
                          {cart.shippingPrice.toLocaleString()}
                        </span>
                        円
                      </li>
                    )}
                    {cart.totalPrice && (
                      <li className="mt-3">
                        合計（税込）：
                        <span className="text-3xl font-semibold mr-1">
                          {cart.totalPrice.toLocaleString()}
                        </span>
                        円
                      </li>
                    )}
                  </ul>
                </div>
                <button typ="submit" className="btn btn-primary w-full">
                  お支払い方法に進む
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default ShippingScreen
