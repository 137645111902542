import { useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import gsap from 'gsap'
import TailorProcess from '../../components/TailorProcess'
import Confirm from '../../components/tailorItems/Confirm'
import { tailorAddToCart } from '../../store/slices/cartSlice'
import { clearTailorItems } from '../../store/slices/tailorSlice'

const TailorConfirmScreen = () => {
  const navigate = useNavigate()
  const comp = useRef()
  const { tailorItems } = useSelector((state) => state.tailorSpec)
  const dispatch = useDispatch()

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from('.g-items', {
        opacity: 0,
        ease: 'power2',
        duration: 1.5,
      })
    }, comp)
    return () => ctx.revert()
  }, [])

  const addToCartHandler = (e) => {
    e.preventDefault()

    dispatch(
      tailorAddToCart({
        ...tailorItems,
        qty: 1,
        isNew: true,
        cartId:
          new Date().getTime().toString(16) +
          Math.floor(1000 * Math.random()).toString(16),
      })
    )
    dispatch(clearTailorItems())
    navigate('/cart')
  }

  return (
    <div data-theme="black" className="flex flex-col items-center grow">
      <div className="grow screen-container mt-5 mb-5 flex flex-col w-full">
        <div ref={comp} className="flex flex-col grow">
          <form
            onSubmit={addToCartHandler}
            className="g-items grow lg:container h-full my-14 flex flex-col justify-between overscroll-x-auto "
          >
            <TailorProcess step1 step2 step3 step4 />
            <h3 className="text-xl text-center mb-20">
              <span className="text-xs inline-block w-14 h-5 text-white bg-gray-600 rounded-full mr-3 pt-0.5 align-middle mb-1">
                Step 4
              </span>
              確認
            </h3>
            <div className="grow flex flex-col items-center">
              <Confirm />
            </div>
            <button type="submit" className="btn btn-outline mt-20 lg:mt-16">
              カートに入れる
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default TailorConfirmScreen
