import { rootApi } from './rootApi'
import { TAILOR_TSHIRTS_URL } from '../../constants'

export const tailorTshirtsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getTailorTshirts: builder.query({
      query: () => ({
        url: TAILOR_TSHIRTS_URL,
      }),
      keepUnusedDataFor: 5,
    }),
    getTailorTshirtDetails: builder.query({
      query: (tshirtId) => ({
        url: `${TAILOR_TSHIRTS_URL}/${tshirtId}`,
      }),
      keepUnusedDataFor: 5,
    }),
    createReview: builder.mutation({
      query: (data) => ({
        url: `${TAILOR_TSHIRTS_URL}/${data.tshirtId}/reviews`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
})

export const {
  useGetTailorTshirtsQuery,
  useGetTailorTshirtDetailsQuery,
  useCreateReviewMutation,
} = tailorTshirtsApi
