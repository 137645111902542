import { useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import gsap from 'gsap'
import Meta from '../components/Meta'
import ReactGA from 'react-ga4'

const HomeScreen = () => {
  ReactGA.send({ hitType: 'pageview', page: '/', title: 'Home' })
  const { isMenuOpen } = useSelector((state) => state.menu)
  const comp = useRef()

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from('.main-title', {
        opacity: 0,
        delay: 0.5,
        y: -100,
        ease: 'power2',
        duration: 1,
      })
      gsap.from('.jp-title', {
        opacity: 0,
        delay: 0.5,
        y: 100,
        ease: 'power2',
        duration: 1,
      })
    }, comp)
    return () => ctx.revert()
  }, [])

  return (
    <div ref={comp}>
      <Meta title="オーダーTシャツ専門店 | m&s Fabric" />
      <div className="flex w-full h-screen grow flex-col lg:flex-row bg-gray-200">
        <div className="grow bg-top-1 bg-cover bg-center basis-1/2"></div>
        <div className="grow bg-top-2 bg-cover bg-center basis-1/2"></div>
      </div>
      <div
        className={`${
          isMenuOpen ? 'fixed' : 'absolute'
        } top-7 left-0 text-white italic font-bold text-center leading-none w-full h-full flex flex-col items-center justify-center`}
      >
        <h2 className="main-title text-[5.5rem] lg:text-[7rem] ">
          The best T-shirt ever,
          <br />
          just for you.
        </h2>
        <p className="jp-title block text-[2rem]/tight lg:text-[3rem] mt-5 lg:mt-10">
          あなたにとって今までで
          <br className="lg:hidden" />
          最高のTシャツを
        </p>
      </div>
      <div className="container flex flex-col md:flex-row items-center w-full py-14">
        <div className="basis-1/2 text-center mb-2">
          <h3 className="text-4xl lg:text-5xl font-bold italic mb-3">
            Fabric Samples
          </h3>
          <h4 className="text-lg italic mb-7 font-bold">
            Tシャツ生地見本サンプル
          </h4>
          <p className="text-sm/7 md:mb-5 text-left md:text-center lg:mb-10">
            実際のTシャツ生地を手に取って確認できる生地見本サンプルを無料でお渡ししています。
            <br />
            生地の厚みや伸縮性、肌触りから色味までご購入前にお確かめいただけます。
          </p>
          <Link to="/fabric-sample">
            <button className="btn btn-outline rounded-full px-5 hidden md:inline-block">
              生地見本サンプル請求
            </button>
          </Link>
        </div>
        <div className="basis-1/2 text-center">
          <img src="/images/fabric_samples.png" alt="" />
          <Link to="/fabric-sample">
            <button className="btn btn-outline rounded-full px-5 mt-2 md:hidden">
              生地見本サンプル請求
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default HomeScreen
