import { Helmet } from 'react-helmet-async'

const Meta = ({ title, description, keywords, script }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <script>{script}</script>
    </Helmet>
  )
}

Meta.defaultProps = {
  title: 'オーダーTシャツ専門店 | m&s Fabric',
  description:
    'オーダーTシャツの専門店です。Tシャツのパターン製作から裁断・縫製まで全てを国内の自社工房で行っています。採寸データを元に製作するフルオーダーメイドのテイラーTシャツと無地Tシャツを自分仕様にするカスタマイズTシャツをご用意しています。',
  keywords: 'オーダーTシャツ,無地Tシャツ,メンズTシャツ,高品質Tシャツ',
}

export default Meta
