const contactMail = (contents) => {
  return `
  ${contents.lastname} ${contents.firstname} 様

  この度はm&s Fabricにお問い合わせいただきありがとうございます。
  以下の内容にて受付させていただきました。

  --------------------------------------------------------------------------------------------------------

  【お名前】${contents.lastname} ${contents.firstname}（${contents.lastnameYomi} ${contents.firstnameYomi}）
  【メールアドレス】${contents.email}
  【お問い合わせ内容】${contents.comment}

  --------------------------------------------------------------------------------------------------------

  内容確認後、担当者より改めてご連絡させていただきますので今しばらくお待ちください。
  また48時間以内に返信がない場合は、大変お手数ですが<info@mnsfabric.com>宛までご連絡ください。

  引き続きよろしくお願いいたします。

  --------------------------------------------------------------------------------------------------------
  m&s Fabric | オーダーTシャツ専門店
  〒261-0023 千葉県千葉市美浜区中瀬1-3 幕張テクノガーデンCB棟3階 MBP
  `
}

export { contactMail }
