import { rootApi } from './rootApi'
import { SIZE_URL } from '../../constants'

export const sizeSpecApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomizeTshirtsSizeSpec: builder.query({
      query: () => ({
        url: SIZE_URL,
      }),
      keepUnusedDataFor: 5,
    }),
  }),
})

export const { useGetCustomizeTshirtsSizeSpecQuery } = sizeSpecApi
