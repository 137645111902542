import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useForgotPasswordMutation } from '../store/apis/forgetPasswordApi'
import { useSendMailMutation } from '../store/apis/sendMailApi'
import { toast } from 'react-toastify'

const ForgotPasswrodScreen = () => {
  const [email, setEmail] = useState('')
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation()
  const [sendMail] = useSendMailMutation()

  const submitHandler = async (e) => {
    e.preventDefault()

    try {
      const res = await forgotPassword({ email }).unwrap()
      const msg = {
        to: email,
        from: 'info@mnsfabric.com',
        subject: 'パスワードリセット',
        text: `24時間以内に下記リンクから新しいパスワードを設定してください。

      https://www.mnsfabric.com/reset-password?resetToken=${res}`,
      }
      await sendMail(msg)
      toast.success('リンクを送信しました')
      setEmail('')
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  return (
    <div className="container mt-14 lg:mt-20 flex justify-center">
      <div className="w-full md:w-96">
        <h2 className="page-title">Forgot Password</h2>
        <p className="page-description">パスワードをお忘れの方</p>
        <form onSubmit={submitHandler}>
          <div className="form-control w-full mb-5">
            <label className="label">
              <span className="label-text">メールアドレス</span>
            </label>
            <input
              type="email"
              className="input input-bordered w-full"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          {isLoading ? (
            <button className="btn w-full mt-10">
              <span className="loading loading-spinner"></span>
              ローディング
            </button>
          ) : (
            <button type="submit" className="btn btn-primary w-full mt-10">
              リンクを送信する
            </button>
          )}
        </form>
        <div className="mt-3 w-full">
          <div className="text-sm text-right hover:underline">
            <Link to="/login">ログインに戻る</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswrodScreen
