import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Message from '../components/Message'
import PayButton from '../components/PayButton'
import Meta from '../components/Meta'

const PlaceOrderScreen = () => {
  const navigate = useNavigate()
  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart
  const { userInfo } = useSelector((state) => state.auth)

  const addOrderHandler = () => {
    navigate('/checkout-success')
  }

  return (
    <>
      <Meta title="ご注文 | m&s Fabric" />
      <div className="flex justify-center">
        <div className="screen-container mt-5 w-full">
          <div className="text-sm breadcrumbs text-gray-500 mb-10">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/cart">Shopping Cart</Link>
              </li>
              <li>
                <Link to="/shipping">Shipping Address</Link>
              </li>
              <li>
                <Link to="/payment">Payment</Link>
              </li>
              <li>Place Order</li>
            </ul>
          </div>
          <h2 className="page-title">Order</h2>
          <p className="page-description">ご注文</p>
          <div className="flex flex-col items-start gap-10 mb-20 lg:flex-row">
            <div className="w-full lg:basis-3/5">
              <div className="overflow-x-auto mb-12">
                <table className="table">
                  <tbody>
                    {/* row 1 */}
                    <tr>
                      <th className="w-32">
                        <h2 className="font-bold">ご注文者</h2>
                      </th>
                      <td>
                        <ul className="text-sm/2">
                          <li>
                            {userInfo.lastname} {userInfo.firstname}（
                            {userInfo.lastnameYomi} {userInfo.firstnameYomi}）
                            様
                          </li>
                          <li>{userInfo.email}</li>
                        </ul>
                      </td>
                    </tr>
                    {/* row 2 */}
                    <tr>
                      <th>
                        <h2 className="font-bold">配送先</h2>
                      </th>
                      <td>
                        <ul className="text-sm/2">
                          <li>{cart.shippingAddress.postalCode}</li>
                          <li>
                            {cart.shippingAddress.prefecture}
                            {cart.shippingAddress.city}
                            {cart.shippingAddress.address}
                            {cart.shippingAddress.building}
                          </li>
                          <li>{cart.shippingAddress.phoneNum}</li>
                        </ul>
                      </td>
                    </tr>
                    {/* row 3 */}
                    <tr>
                      <th>
                        <h2 className="font-bold">お支払い方法</h2>
                      </th>
                      <td>
                        <ul className="text-sm/2">
                          <li>{cart.paymentMethod}</li>
                        </ul>
                      </td>
                    </tr>
                    {/* row 4 */}
                    <tr>
                      <th>
                        <h2 className="font-bold">コメント</h2>
                      </th>
                      <td>
                        <ul className="text-sm/2">
                          <li>{cart.comment}</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {cartItems.length === 0 ? (
                <Message>カートの中身はありません</Message>
              ) : (
                <div className="text-sm">
                  {cartItems.map((item) => {
                    if (item.category === 'Tailor T-shirts' && item.isNew) {
                      return (
                        <div
                          className="flex flex-col w-full border-b border-gray-300 pb-5 mb-5"
                          key={item.cartId}
                        >
                          <div className="flex gap-5">
                            <div className="flex-none w-20">
                              <Link to="/tailor-tshirts/body">
                                <img src={item.image} alt={item.name} />
                              </Link>
                            </div>
                            <div className="flex-1">
                              <p className="text-xs rounded-full inline-block px-2 bg-black text-white">
                                {item.category}
                              </p>
                              <h3 className="text-lg font-bold italic">
                                {item.name}
                              </h3>
                              <p className="mb-2">
                                <span className="text-xs">価格：</span>
                                {item.price.toLocaleString()}
                                <span className="text-xs">円（税込）</span>
                              </p>
                              <ul className="text-sm">
                                <li className="inline-block mr-2">
                                  カラー：{item.color}、
                                </li>
                                <li className="inline-block mr-2">
                                  サイズ：オーダーメイド、
                                </li>
                                <li className="inline-block mr-2">
                                  ネック：{item.neck}、
                                </li>
                                <li className="inline-block mr-2">
                                  ネックリブ{item.neckLib}cm
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )
                    } else if (
                      item.category === 'Tailor T-shirts' &&
                      !item.isNew
                    ) {
                      return (
                        <div
                          className="flex flex-col w-full border-b border-gray-300 pb-5 mb-5"
                          key={item.cartId}
                        >
                          <div className="flex gap-5">
                            <div className="flex-none w-20">
                              <Link to={`/tailor-tshirts/${item._id}`}>
                                <img src={item.image} alt={item.name} />
                              </Link>
                            </div>
                            <div className="flex-1">
                              <p className="text-xs rounded-full inline-block px-2 bg-black text-white">
                                {item.category} / Repeat
                              </p>
                              <h3 className="text-lg font-bold italic">
                                {item.name}
                              </h3>
                              <p className="mb-2">
                                <span className="text-xs">価格：</span>
                                {item.price.toLocaleString()}
                                <span className="text-xs">円（税込）</span>
                              </p>
                              <ul className="text-sm">
                                <li className="inline-block mr-2">
                                  枚数：{item.qty}、
                                </li>
                                <li className="inline-block mr-2">
                                  カラー：{item.color}、
                                </li>
                                <li className="inline-block mr-2">
                                  サイズ：{item.size}、
                                </li>
                                <li className="inline-block mr-2">
                                  タグ：{item.tag}、
                                </li>
                                <li className="inline-block mr-2">
                                  リブ：{item.lib}、
                                </li>
                                <li className="inline-block mr-2">
                                  ポケット：
                                  {item.pocket.pocketType !== '付けない' &&
                                    item.pocket.pocketPosition}
                                  {item.pocket.pocketType !== '付けない' && (
                                    <span> / </span>
                                  )}
                                  {item.pocket.pocketType}、
                                </li>
                                <li className="inline-block mr-2">
                                  名入れ：
                                  {item.embroidery.isEmbroidery !== '無し' &&
                                    item.embroidery.embroideryChar}
                                  {item.embroidery.isEmbroidery !== '無し' && (
                                    <span> / </span>
                                  )}
                                  {item.embroidery.isEmbroidery !== '無し' &&
                                    item.embroidery.embroideryColor}
                                  {item.embroidery.isEmbroidery === '無し' &&
                                    item.embroidery.isEmbroidery}
                                  、
                                </li>
                                <li className="inline-block mr-2">
                                  サイズ変更：
                                  {item.fixSize === '' ? 'なし' : 'あり'}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )
                    } else if (item.category === 'Customize T-shirts') {
                      return (
                        <div
                          className="flex flex-col w-full border-b border-gray-300 pb-5 mb-5"
                          key={item.cartId}
                        >
                          <div className="flex gap-5">
                            <div className="flex-none w-20">
                              <Link to={`/customize-tshirts/${item._id}`}>
                                <img src={item.image} alt={item.name} />
                              </Link>
                            </div>

                            <div className="flex-1">
                              <p className="text-xs border border-gray-400 rounded-full inline-block px-2 bg-white">
                                {item.category}
                              </p>
                              <h3 className="text-lg font-bold italic">
                                {item.name}
                              </h3>
                              <p className="mb-2">
                                <span className="text-xs">価格：</span>
                                {item.price.toLocaleString()}
                                <span className="text-xs">円（税込）</span>
                              </p>
                              <ul className="text-sm">
                                <li className="inline-block mr-2">
                                  枚数：{item.qty}、
                                </li>
                                <li className="inline-block mr-2">
                                  カラー：{item.color}、
                                </li>
                                <li className="inline-block mr-2">
                                  サイズ：{item.size}、
                                </li>
                                <li className="inline-block mr-2">
                                  ネック：{item.neck}、
                                </li>
                                <li className="inline-block mr-2">
                                  スタイル：{item.style}、
                                </li>
                                <li className="inline-block mr-2">
                                  着丈：{item.length}、
                                </li>
                                {item.type === 'Long T-shirts' && (
                                  <li className="inline-block mr-2">
                                    袖丈：{item.sleeveLength}、
                                  </li>
                                )}
                                <li className="text-[13px] inline-block mr-2">
                                  リブ：{item.lib}、
                                </li>
                                <li className="text-[13px] inline-block mr-2">
                                  ポケット：
                                  {item.pocket.pocketType !== '付けない' &&
                                    item.pocket.pocketPosition}
                                  {item.pocket.pocketType !== '付けない' && (
                                    <span> / </span>
                                  )}
                                  {item.pocket.pocketType}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )
                    } else {
                      return <Message>該当しない商品です</Message>
                    }
                  })}
                </div>
              )}
            </div>
            <div className="w-full lg:basis-2/5 bg-gray-100 p-5">
              <div className="mb-8 text-right">
                <p className="text-sm">
                  商品数：
                  <span className="text-xl">
                    {cartItems.reduce((acc, item) => acc + Number(item.qty), 0)}
                  </span>
                  点
                </p>
                <ul className="text-sm">
                  <li>
                    小計：
                    <span className="text-xl mr-1">
                      {cartItems
                        .reduce(
                          (acc, item) => acc + Number(item.qty) * item.price,
                          0
                        )
                        .toLocaleString()}
                    </span>
                    円
                  </li>
                  <li>
                    配送料：
                    <span className="text-xl mr-1">
                      {cart.shippingPrice.toLocaleString()}
                    </span>
                    円
                  </li>
                  <li className="mt-3">
                    合計（税込）：
                    <span className="text-3xl font-semibold mr-1">
                      {cart.totalPrice.toLocaleString()}
                    </span>
                    円
                  </li>
                </ul>
              </div>
              {cart.paymentMethod === 'クレジットカード' ? (
                <PayButton orderItems={cart} />
              ) : (
                <div>
                  <p className="text-xs/5 mb-3">
                    注文確定後、１週間以内に指定の銀行にてお振り込みをお願いいたします。恐れ入りますが振り込み手数料はお客様ご負担とさせていただいております。
                  </p>
                  <button
                    type="button"
                    className="btn btn-primary w-full"
                    onClick={addOrderHandler}
                  >
                    注文を確定する
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PlaceOrderScreen
