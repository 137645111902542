import { useRouteError } from 'react-router-dom'

export default function ErrorPage() {
  const error = useRouteError()
  console.error(error)

  return (
    <div
      className="prose lg:prose-xl max-w-full h-screen flex flex-col items-center justify-center"
      id="error-page"
    >
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i className="text-gray-400">{error.statusText || error.message}</i>
      </p>
    </div>
  )
}
