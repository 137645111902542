import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import SizeChartHalf from './SizeChartHalf'
import SizeChartLong from './SizeChartLong'
import { changeCustomSlideState } from '../../store/slices/customSlideSlice'
import { useGetCustomizeTshirtsSizeSpecQuery } from '../../store/apis/sizeSpecApi'

const CustomSize = ({ changeSize, selectedValue, type }) => {
  const [selectedSizeName, setSelectedSizeName] = useState()
  const [selectedSizeSpec, setSelectedSizeSpec] = useState({})
  const [selectedSize, setSelectedSize] = useState(selectedValue.selectedSize)
  const dispatch = useDispatch()
  const {
    data: sizeSpecTshirts,
    isLoading,
    error,
  } = useGetCustomizeTshirtsSizeSpecQuery()

  useEffect(() => {
    sizeSpecTshirts &&
      sizeSpecTshirts.forEach((size) => {
        if (size.name === selectedSize) {
          let fit = size.size.find((style) => style.name === 'Fit')
          setSelectedSizeName(size.name)
          setSelectedSizeSpec(fit.spec)
        }
      })
  }, [selectedSize, selectedSizeSpec, selectedSizeName, sizeSpecTshirts])

  const changeSizeHandler = () => {
    changeSize(selectedSize)
    dispatch(changeCustomSlideState())
  }

  return (
    <>
      {isLoading ? (
        <div>Loading</div>
      ) : error ? (
        <div>{error.data.message}</div>
      ) : (
        <div className="w-full h-full pb-28 lg:pb-10 overflow-y-auto">
          <div className="w-full h-80 lg:h-96 flex justify-center mb-5">
            {type === 'T-shirts' ? (
              <SizeChartHalf size={selectedSizeSpec} />
            ) : (
              <SizeChartLong size={selectedSizeSpec} />
            )}
          </div>

          {/* size chart */}
          <div className="overflow-x-auto mb-10">
            <table className="table text-center">
              {/* head */}
              <thead>
                <tr className="text-black">
                  <th className="font-normal">着丈</th>
                  <th className="font-normal">着幅</th>
                  <th className="font-normal">裾幅</th>
                  <th className="font-normal">肩幅</th>
                  <th className="font-normal">脇高</th>
                  <th className="font-normal">袖丈</th>
                  <th className="font-normal">袖幅</th>
                </tr>
              </thead>
              <tbody>
                {selectedSizeName === selectedSize && (
                  <tr className="text-base">
                    <td>
                      {selectedSizeSpec.length}{' '}
                      <span className="text-xs">cm</span>
                    </td>
                    <td>
                      {selectedSizeSpec.chest}{' '}
                      <span className="text-xs">cm</span>
                    </td>
                    <td>
                      {selectedSizeSpec.hem} <span className="text-xs">cm</span>
                    </td>
                    <td>
                      {selectedSizeSpec.shoulder}{' '}
                      <span className="text-xs">cm</span>
                    </td>
                    <td>
                      {selectedSizeSpec.armpit}{' '}
                      <span className="text-xs">cm</span>
                    </td>
                    <td>
                      {type === 'T-shirts'
                        ? selectedSizeSpec.sleeve
                        : selectedSizeSpec.longSleeve}{' '}
                      <span className="text-xs">cm</span>
                    </td>
                    <td>
                      {type === 'T-shirts'
                        ? selectedSizeSpec.arm
                        : selectedSizeSpec.wrist}{' '}
                      <span className="text-xs">cm</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <p className="text-xs text-right mt-3">
              ※数値は生地の状態や計測方法によって異なる事があります。
            </p>
          </div>

          <div className="grid grid-cols-2 gap-3 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-6 mb-10">
            <div>
              <input
                type="radio"
                name="size"
                id="XS"
                value="XS"
                onChange={(e) => setSelectedSize(e.target.value)}
                checked={selectedSize === 'XS'}
                className="hidden"
              />
              <label
                className={`btn btn-sm btn-outline rounded-full w-full border-gray-400 ${
                  selectedSize === 'XS' && 'bg-black text-white'
                }`}
                htmlFor="XS"
              >
                XS
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="size"
                id="S"
                value="S"
                onChange={(e) => setSelectedSize(e.target.value)}
                checked={selectedSize === 'S'}
                className="hidden"
              />
              <label
                className={`btn btn-sm btn-outline rounded-full w-full border-gray-400 ${
                  selectedSize === 'S' && 'bg-black text-white'
                }`}
                htmlFor="S"
              >
                S
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="size"
                id="M"
                value="M"
                onChange={(e) => setSelectedSize(e.target.value)}
                checked={selectedSize === 'M'}
                className="hidden"
              />
              <label
                className={`btn btn-sm btn-outline rounded-full w-full border-gray-400 ${
                  selectedSize === 'M' && 'bg-black text-white'
                }`}
                htmlFor="M"
              >
                M
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="size"
                id="L"
                value="L"
                onChange={(e) => setSelectedSize(e.target.value)}
                checked={selectedSize === 'L'}
                className="hidden"
              />
              <label
                className={`btn btn-sm btn-outline rounded-full w-full border-gray-400 ${
                  selectedSize === 'L' && 'bg-black text-white'
                }`}
                htmlFor="L"
              >
                L
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="size"
                id="XL"
                value="XL"
                onChange={(e) => setSelectedSize(e.target.value)}
                checked={selectedSize === 'XL'}
                className="hidden"
              />
              <label
                className={`btn btn-sm btn-outline rounded-full w-full border-gray-400 ${
                  selectedSize === 'XL' && 'bg-black text-white'
                }`}
                htmlFor="XL"
              >
                XL
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="size"
                id="2XL"
                value="2XL"
                onChange={(e) => setSelectedSize(e.target.value)}
                checked={selectedSize === '2XL'}
                className="hidden"
              />
              <label
                className={`btn btn-sm btn-outline rounded-full w-full border-gray-400 ${
                  selectedSize === '2XL' ? 'bg-black text-white' : undefined
                }`}
                htmlFor="2XL"
              >
                2XL
              </label>
            </div>
          </div>
          <div className="w-full text-center">
            <button
              className="btn btn-primary btn-sm w-72"
              onClick={changeSizeHandler}
            >
              決定する
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default CustomSize
