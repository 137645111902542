import { useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import gsap from 'gsap'
import ChangeBodyAndColor from '../../components/tailorItems/ChageBodyAndColor'
import { useGetTailorTshirtsQuery } from '../../store/apis/tailorTshirtsApi'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import TailorProcess from '../../components/TailorProcess'

const TailorBodyScreen = () => {
  const navigate = useNavigate()
  const comp = useRef()
  const { data: tailorTshirts, isLoading, error } = useGetTailorTshirtsQuery()
  const { tailorItems } = useSelector((state) => state.tailorSpec)

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from('.g-items', {
        opacity: 0,
        ease: 'power2',
        duration: 1.5,
      })
    }, comp)
    return () => ctx.revert()
  }, [])

  const nextStepHandler = () => {
    navigate('/tailor-tshirts/neck')
  }

  return (
    <div data-theme="black" className="flex flex-col items-center grow">
      <div className="grow screen-container mt-5 mb-5 flex flex-col w-full">
        <div ref={comp} className="flex flex-col grow">
          <div className="g-items grow lg:container h-full my-14 flex flex-col justify-between overscroll-x-auto relative">
            <TailorProcess step1 />
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant={'alert-error'}>{error.data.message}</Message>
            ) : (
              <div>
                <h3 className="text-xl text-center mb-12">
                  <span className="text-xs inline-block w-14 h-5 text-white bg-gray-600 rounded-full mr-3 pt-0.5 align-middle mb-1">
                    Step 1
                  </span>
                  ボディとカラー
                </h3>

                <h4 className="page-title">Tailor Long Sleeve T-shirts</h4>
                <p className="page-description">
                  お好きな生地のカラーを選んで「選択する」ボタンを押し、次に進んでください。
                </p>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 lg:gap-8">
                  {tailorTshirts.map((item) => {
                    if (item.type === 'Long T-shirts') {
                      return <ChangeBodyAndColor item={item} key={item._id} />
                    }
                  })}
                </div>

                <h4 className="page-title mt-10 lg:mt-20">Tailor T-shirts</h4>
                <p className="page-description">
                  お好きな生地のカラーを選んで「選択する」ボタンを押し、次に進んでください。
                </p>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 lg:gap-8">
                  {tailorTshirts.map((item) => {
                    if (item.type === 'T-shirts') {
                      return <ChangeBodyAndColor item={item} key={item._id} />
                    }
                  })}
                </div>
              </div>
            )}
            <div className="fixed w-full bottom-0 left-0 p-8 bg-neutral-700 flex justify-center">
              {tailorItems._id ? (
                <button
                  onClick={nextStepHandler}
                  className="container btn btn-outline leading-6 h-16 font-normal"
                  disabled={!tailorItems._id}
                >
                  【 {tailorItems.name} / {tailorItems.color} 】
                  <br className="hidden" />
                  で次に進む
                </button>
              ) : (
                <button
                  onClick={nextStepHandler}
                  className="container btn btn-outline leading-6 h-16 font-normal"
                  disabled={!tailorItems._id}
                >
                  商品を選択してください
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TailorBodyScreen
