const checkformMail = (userInfo, size) => {
  return `
  ${userInfo.lastname} ${userInfo.firstname} 様

  テイラーTシャツチェックフォームからのご返信ありがとうございます。

  ■ご返信内容
  ----------------------------------------------------
  返信日：${new Date().getFullYear()}年${
    new Date().getMonth() + 1
  }月${new Date().getDate()}日
  ----------------------------------------------------

  【お名前】${userInfo.lastname} ${userInfo.firstname}（${
    userInfo.lastnameYomi
  } ${userInfo.firstnameYomi}）
  【メールアドレス】${userInfo.email}

  ■修正希望サイズ
  ----------------------------------------------------
  【着丈】${size.length} cm
  【着幅】${size.chest} cm
  【ウエスト】${size.waist} cm
  【裾幅】${size.hem} cm
  【肩幅】${size.shoulder} cm
  【脇高】${size.armpit} cm
  【袖丈】${size.sleeve} cm
  【袖幅】${size.sleeveEnd} cm
  【首幅】${size.neckWidth} cm
  【首深】${size.neckHeight} cm
  【コメント】${size.comment}

  ----------------------------------------------------
  内容確認後、制作上不都合がある場合は担当者よりご連絡させていただきます。
  問題がなければ返信いただいた内容で本商品の制作に入らせていただきます。

  送信後に追加で変更のご要望などある場合はお早めのご連絡お願いいたします。
  本商品の製作過程に入った後の追加修正は不可とさせていただいております。

  本商品の納品まで引き続きよろしくお願いいたします。
  `
}

export { checkformMail }
