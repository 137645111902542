import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { changeTailorSpec } from '../../store/slices/tailorSlice'

const ChangeNeck = () => {
  const [selectedNeckType, setSelectedNeckType] = useState('O-Medium')
  const [selectedLibWide, setSelectedLibWide] = useState(1.5)
  const [selectedImages, setSelectedImages] = useState({})
  const dispatch = useDispatch()

  const libWide = [
    {
      name: 'O-Small',
      image: {
        one: '/images/tailorImg/lib_o-small-10.svg',
        onehalf: '/images/tailorImg/lib_o-small-15.svg',
        two: '/images/tailorImg/lib_o-small-20.svg',
      },
    },
    {
      name: 'O-Medium',
      image: {
        one: '/images/tailorImg/lib_o-medium-10.svg',
        onehalf: '/images/tailorImg/lib_o-medium-15.svg',
        two: '/images/tailorImg/lib_o-medium-20.svg',
      },
    },
    {
      name: 'O-Large',
      image: {
        one: '/images/tailorImg/lib_o-large-10.svg',
        onehalf: '/images/tailorImg/lib_o-large-15.svg',
        two: '/images/tailorImg/lib_o-large-20.svg',
      },
    },
    {
      name: 'V-Small',
      image: {
        one: '/images/tailorImg/lib_v-small-10.svg',
        onehalf: '/images/tailorImg/lib_v-small-15.svg',
        two: '/images/tailorImg/lib_v-small-20.svg',
      },
    },
    {
      name: 'V-Medium',
      image: {
        one: '/images/tailorImg/lib_v-medium-10.svg',
        onehalf: '/images/tailorImg/lib_v-medium-15.svg',
        two: '/images/tailorImg/lib_v-medium-20.svg',
      },
    },
    {
      name: 'V-Large',
      image: {
        one: '/images/tailorImg/lib_v-large-10.svg',
        onehalf: '/images/tailorImg/lib_v-large-15.svg',
        two: '/images/tailorImg/lib_v-large-20.svg',
      },
    },
  ]

  useEffect(() => {
    if (selectedNeckType) {
      const fileteredNeck = libWide.find(
        (neck) => neck.name === selectedNeckType
      )
      setSelectedImages(fileteredNeck.image)
    } else {
      setSelectedImages({
        one: '/images/tailorImg/lib_o-medium-10.svg',
        onehalf: '/images/tailorImg/lib_o-medium-15.svg',
        two: '/images/tailorImg/lib_o-medium-20.svg',
      })
    }
    // eslint-disable-next-line
  }, [selectedNeckType])

  const neckLibHandler = (value) => {
    if (value === 'narrow') {
      setSelectedLibWide((prev) => prev - 0.5)
    } else if (value === 'wide') {
      setSelectedLibWide((prev) => prev + 0.5)
    } else {
      setSelectedLibWide(selectedLibWide)
    }
  }

  const selectedHandler = () => {
    dispatch(
      changeTailorSpec({
        neck: selectedNeckType,
        neckLib: selectedLibWide.toString(),
      })
    )
  }

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div className="mb-5 w-full">
        <img
          className="w-full sm:h-64"
          src={
            selectedLibWide === 1
              ? selectedImages.one
              : selectedLibWide === 1.5
              ? selectedImages.onehalf
              : selectedImages.two
          }
          alt="リブの太さ"
        />
      </div>

      <div className="flex justify-center gap-7 mb-10">
        <button
          className="text-xs btn btn-sm btn-outline rounded-full w-24 lg:w-32"
          onClick={() => neckLibHandler('narrow')}
          disabled={selectedLibWide <= 1}
        >
          細くする
        </button>
        <span className="w-20 text-xl text-center lg:text-xl">
          {selectedLibWide} cm
        </span>
        <button
          className="text-xs btn btn-sm btn-outline rounded-full w-24 lg:w-32"
          onClick={() => neckLibHandler('wide')}
          disabled={selectedLibWide >= 2}
        >
          広くする
        </button>
      </div>

      <div className="grid gap-5 grid-cols-3 lg:gap-5">
        <div className="text-center">
          <input
            type="radio"
            name="neck"
            id="o-small"
            value="O-Small"
            onChange={(e) => setSelectedNeckType(e.target.value)}
            checked={selectedNeckType === 'O-Small'}
            className="hidden"
          />
          <label
            className={`btn btn-sm btn-outline rounded-full w-32 py-1 border hover:text-white hover:bg-neutral-700 ${
              selectedNeckType === 'O-Small'
                ? 'text-white border-white'
                : 'text-neutral-500 border-neutral-700'
            }`}
            htmlFor="o-small"
          >
            O-Small
          </label>
        </div>
        <div className="text-center">
          <input
            type="radio"
            name="neck"
            id="o-medium"
            value="O-Medium"
            onChange={(e) => setSelectedNeckType(e.target.value)}
            checked={selectedNeckType === 'O-Medium'}
            className="hidden"
          />
          <label
            className={`btn btn-sm btn-outline rounded-full w-32 py-1 border hover:text-white hover:bg-neutral-700 ${
              selectedNeckType === 'O-Medium'
                ? 'text-white border-white'
                : 'text-neutral-500 border-neutral-700'
            }`}
            htmlFor="o-medium"
          >
            O-Medium
          </label>
        </div>
        <div className="text-center">
          <input
            type="radio"
            name="neck"
            id="o-large"
            value="O-Large"
            onChange={(e) => setSelectedNeckType(e.target.value)}
            checked={selectedNeckType === 'O-Large'}
            className="hidden"
          />
          <label
            className={`btn btn-sm btn-outline rounded-full w-32 py-1 border hover:text-white hover:bg-neutral-700 ${
              selectedNeckType === 'O-Large'
                ? 'text-white border-white'
                : 'text-neutral-500 border-neutral-700'
            }`}
            htmlFor="o-large"
          >
            O-Large
          </label>
        </div>
        <div className="text-center">
          <input
            type="radio"
            name="neck"
            id="v-small"
            value="V-Small"
            onChange={(e) => setSelectedNeckType(e.target.value)}
            checked={selectedNeckType === 'V-Small'}
            className="hidden"
          />
          <label
            className={`btn btn-sm btn-outline rounded-full w-32 py-1 border hover:text-white hover:bg-neutral-700 ${
              selectedNeckType === 'V-Small'
                ? 'text-white border-white'
                : 'text-neutral-500 border-neutral-700'
            }`}
            htmlFor="v-small"
          >
            V-Small
          </label>
        </div>
        <div className="text-center">
          <input
            type="radio"
            name="neck"
            id="v-medium"
            value="V-Medium"
            onChange={(e) => setSelectedNeckType(e.target.value)}
            checked={selectedNeckType === 'V-Medium'}
            className="hidden"
          />
          <label
            className={`btn btn-sm btn-outline rounded-full w-32 py-1 border hover:text-white hover:bg-neutral-700 ${
              selectedNeckType === 'V-Medium'
                ? 'text-white border-white'
                : 'text-neutral-500 border-neutral-700'
            }`}
            htmlFor="v-medium"
          >
            V-Medium
          </label>
        </div>
        <div className="text-center">
          <input
            type="radio"
            name="neck"
            id="v-large"
            value="V-Large"
            onChange={(e) => setSelectedNeckType(e.target.value)}
            checked={selectedNeckType === 'V-Large'}
            className="hidden"
          />
          <label
            className={`btn btn-sm btn-outline rounded-full w-32 py-1 border hover:text-white hover:bg-neutral-700 ${
              selectedNeckType === 'V-Large'
                ? 'text-white border-white'
                : 'text-neutral-500 border-neutral-700'
            }`}
            htmlFor="v-large"
          >
            V-Large
          </label>
        </div>
      </div>

      <button
        onClick={selectedHandler}
        className={`mt-10 py-1 btn btn-sm btn-outline rounded-full w-full md:w-64 ${
          selectedNeckType &&
          'text-neutral-300 bg-neutral-800 border border-neutral-300 hover:border-white hover:bg-neutral-700 hover:text-white'
        }`}
        disabled={!selectedNeckType}
      >
        決定する
      </button>
    </div>
  )
}

export default ChangeNeck
