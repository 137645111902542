import { useRef, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import gsap from 'gsap'
import TailorProcess from '../../components/TailorProcess'
import MeasureSize from '../../components/tailorItems/MeasureSize'

const TailorSizeScreen = () => {
  const navigate = useNavigate()
  const comp = useRef()
  const [checkTab, setCheckTab] = useState(true)

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from('.g-items', {
        opacity: 0,
        ease: 'power2',
        duration: 1.5,
      })
    }, comp)
    return () => ctx.revert()
  }, [])

  const checkTabHandler = (tab) => {
    setCheckTab(tab)
  }

  const nextStepHandler = () => {
    navigate('/tailor-tshirts/confirm')
  }

  return (
    <div data-theme="black" className="flex flex-col items-center grow">
      <div className="grow screen-container mt-5 mb-5 flex flex-col w-full">
        <div ref={comp} className="flex flex-col grow">
          <div className="g-items grow container h-full my-14 flex flex-col justify-between relative">
            <TailorProcess step1 step2 step3 />
            <h3 className="text-xl text-center mb-10">
              <span className="text-xs inline-block w-14 h-5 text-white bg-gray-600 rounded-full mr-3 pt-0.5 align-middle mb-1">
                Step 3
              </span>
              サイズ
            </h3>
            <div className="grow">
              <MeasureSize checkTabHandler={checkTabHandler} />
            </div>
            <div className="fixed w-full bottom-0 left-0 p-8 bg-neutral-700 flex justify-center">
              {!checkTab ? (
                <button
                  onClick={nextStepHandler}
                  className="container btn btn-outline leading-6 h-16 font-normal"
                  disabled={checkTab}
                >
                  内容を確認する
                </button>
              ) : (
                <button
                  onClick={nextStepHandler}
                  className="container btn btn-outline leading-6 h-16 font-normal"
                  disabled={checkTab}
                >
                  各項目に記入または選択して決定してください
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TailorSizeScreen
