import { useSelector } from 'react-redux'

const Confirm = () => {
  const { tailorItems } = useSelector((state) => state.tailorSpec)

  return (
    <>
      <div className="w-full flex flex-col justify-center md:flex-row gap-10 xl:w-4/5">
        <div className="w-full md:w-1/3 overflow-x-auto">
          <table className="table">
            <tbody>
              <tr className="confirm-underline">
                <th className="w-28">生地</th>
                <td>{tailorItems.name}</td>
              </tr>
              <tr className="confirm-underline">
                <th>カラー</th>
                <td>{tailorItems.color}</td>
              </tr>
              <tr className="confirm-underline">
                <th>ネック</th>
                <td>{tailorItems.neck}</td>
              </tr>
              <tr className="confirm-underline">
                <th>ネックリブ</th>
                <td>{tailorItems.neckLib}cm</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="w-full md:w-1/3 overflow-x-auto">
          <table className="table">
            <tbody>
              <tr className="confirm-underline">
                <th className="w-28">身長</th>
                <td>{tailorItems.bodySize.height}cm</td>
              </tr>
              <tr className="confirm-underline">
                <th>胸囲</th>
                <td>{tailorItems.bodySize.chest}cm</td>
              </tr>
              <tr className="confirm-underline">
                <th>ウエスト</th>
                <td>{tailorItems.bodySize.waist}cm</td>
              </tr>
              <tr className="confirm-underline">
                <th>腕回り</th>
                <td>{tailorItems.bodySize.arm}cm</td>
              </tr>
              <tr className="confirm-underline">
                <th>肩幅</th>
                <td>{tailorItems.shoulderSize.shoulderLength}</td>
              </tr>
              <tr className="confirm-underline">
                <th>肩の傾斜</th>
                <td>{tailorItems.shoulderSize.shoulderLine}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="w-fll md:w-1/3 overflow-x-auto">
          <table className="table">
            <tbody>
              <tr className="confirm-underline">
                <th className="w-28">着丈</th>
                <td>
                  {tailorItems.tshirtSize.length === '0.0'
                    ? ''
                    : tailorItems.tshirtSize.length}
                  {tailorItems.tshirtSize.length !== '0.0' ? 'cm' : ''}
                </td>
              </tr>
              <tr className="confirm-underline">
                <th>着幅</th>
                <td>
                  {tailorItems.tshirtSize.chestWidth === '0.0'
                    ? ''
                    : tailorItems.tshirtSize.chestWidth}
                  {tailorItems.tshirtSize.chestWidth !== '0.0' ? 'cm' : ''}
                </td>
              </tr>
              <tr className="confirm-underline">
                <th>ウエスト</th>
                <td>
                  {tailorItems.tshirtSize.waistWidth === '0.0'
                    ? ''
                    : tailorItems.tshirtSize.waistWidth}
                  {tailorItems.tshirtSize.waistWidth !== '0.0' ? 'cm' : ''}
                </td>
              </tr>
              <tr className="confirm-underline">
                <th>裾幅</th>
                <td>
                  {tailorItems.tshirtSize.hemWidth === '0.0'
                    ? ''
                    : tailorItems.tshirtSize.hemWidth}
                  {tailorItems.tshirtSize.hemWidth !== '0.0' ? 'cm' : ''}
                </td>
              </tr>
              <tr className="confirm-underline">
                <th>肩幅</th>
                <td>
                  {tailorItems.tshirtSize.shoulderWidth === '0.0'
                    ? ''
                    : tailorItems.tshirtSize.shoulderWidth}
                  {tailorItems.tshirtSize.shoulderWidth !== '0.0' ? 'cm' : ''}
                </td>
              </tr>
              <tr className="confirm-underline">
                <th>袖丈</th>
                {tailorItems.type === 'T-shirts' ? (
                  <td>
                    {tailorItems.tshirtSize.sleeveLength === '0.0'
                      ? ''
                      : tailorItems.tshirtSize.sleeveLength}
                    {tailorItems.tshirtSize.sleeveLength !== '0.0' ? 'cm' : ''}
                  </td>
                ) : (
                  <td>
                    {tailorItems.tshirtSize.longSleeveLength === '0.0'
                      ? ''
                      : tailorItems.tshirtSize.longSleeveLength}
                    {tailorItems.tshirtSize.longSleeveLength !== '0.0'
                      ? 'cm'
                      : ''}
                  </td>
                )}
              </tr>
              <tr className="confirm-underline">
                <th>袖幅</th>
                {tailorItems.type === 'T-shirts' ? (
                  <td>
                    {tailorItems.tshirtSize.armWidth === '0.0' ||
                    tailorItems.tshirtSize.armWidth === NaN
                      ? ''
                      : tailorItems.tshirtSize.armWidth}
                    {tailorItems.tshirtSize.armWidth !== '0.0' ||
                    !tailorItems.tshirtSize.armWidth === NaN
                      ? 'cm'
                      : ''}
                  </td>
                ) : (
                  <td>
                    {tailorItems.tshirtSize.wristWidth === '0.0' ||
                    tailorItems.tshirtSize.armWidth === NaN
                      ? ''
                      : tailorItems.tshirtSize.wristWidth}
                    {tailorItems.tshirtSize.wristWidth !== '0.0' ||
                    !tailorItems.tshirtSize.armWidth === NaN
                      ? 'cm'
                      : ''}
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="w-full xl:w-4/5 mt-10">
        <table className="table">
          <tbody>
            <tr className="confirm-underline">
              <th className="w-32">目的</th>
              <td>{tailorItems.questions.isYourPurpose}</td>
            </tr>
            <tr className="confirm-underline">
              <th>好みの生地</th>
              <td>{tailorItems.questions.favoriteTshirt}</td>
            </tr>
            <tr className="confirm-underline">
              <th>理想のサイズ</th>
              <td>{tailorItems.questions.favoriteSize}</td>
            </tr>
            <tr className="confirm-underline">
              <th>コメント</th>
              <td>{tailorItems.questions.comment}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Confirm
