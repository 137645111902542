import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { changeCustomSlideState } from '../../store/slices/customSlideSlice'

const CustomTag = ({ changeTag, selectedValue }) => {
  const [selectedTag, setSelectedTag] = useState(selectedValue.selectedTag)
  const dispatch = useDispatch()

  const changeTagHandler = () => {
    changeTag(selectedTag)
    dispatch(changeCustomSlideState())
  }
  return (
    <div className="w-full overflow-y-auto h-full flex items-center pb-28 lg:pb-10">
      <div className="w-full">
        <div className="flex items-center justify-center mb-10">
          <div className="flex gap-10 w-full">
            <div className="basis-1/2 flex flex-col items-center">
              <img
                src="/images/tailorImg/oriname.jpg"
                alt="Tシャツ織りネーム"
                className="mb-5"
              />
              <p className="text-sm mb-10">
                織りネームを使用した高級感のあるタグです。織りネームの中でも品質が良く、細かい表現が可能な綾織のタグとなります。
              </p>
              <input
                type="radio"
                name="tag"
                id="oriname"
                value="織りネーム"
                checked={selectedTag === '織りネーム'}
                className="hidden"
                onChange={(e) => setSelectedTag(e.target.value)}
              />
              <label
                className={`btn btn-sm btn-outline rounded-full border-gray-400 w-full xl:w-64 ${
                  selectedTag === '織りネーム' && 'bg-black text-white'
                }`}
                htmlFor="oriname"
              >
                織りネーム
              </label>
            </div>
            <div className="basis-1/2 flex flex-col items-center">
              <img
                src="/images/tailorImg/printname.jpg"
                alt="Tシャツプリントネーム"
                className="mb-5"
              />
              <p className="text-sm mb-10">
                アイロンプリントを使用したプリントタグです。織りネームが肌に当たって気になる方はこちらをお選びください。
              </p>
              <input
                type="radio"
                name="tag"
                id="printname"
                value="プリントネーム"
                checked={selectedTag === 'プリントネーム'}
                className="hidden"
                onChange={(e) => setSelectedTag(e.target.value)}
              />
              <label
                className={`btn btn-sm btn-outline rounded-full border-gray-400 w-full xl:w-64 ${
                  selectedTag === 'プリントネーム' && 'bg-black text-white'
                }`}
                htmlFor="printname"
              >
                プリントネーム
              </label>
            </div>
          </div>
        </div>
        <div className="w-full text-center">
          <button
            className="btn btn-primary btn-sm w-72"
            onClick={changeTagHandler}
          >
            決定する
          </button>
        </div>
      </div>
    </div>
  )
}

export default CustomTag
