import ReactGA from 'react-ga4'
import { Outlet, ScrollRestoration } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import Menu from './components/Menu'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const App = () => {
  ReactGA.initialize('G-TW3ERX992W')

  return (
    <div className="flex flex-col justify-between min-h-screen">
      <Header />
      <Menu />
      <div className="flex flex-col mt-14 w-full calc-height-screen">
        <Outlet />
      </div>
      <Footer />
      <ToastContainer
        bodyClassName={() => 'flex text-sm font-med block p-2'}
        autoClose={3000}
      />
      <ScrollRestoration />
    </div>
  )
}

export default App
