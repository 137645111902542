import { useState } from 'react'
import { Link } from 'react-router-dom'
import OrderInfo from '../components/profile/OrderInfo'
import CustomerInfo from '../components/profile/CustomerInfo'
import TailorSize from '../components/profile/TailorSize'
import Checkform from '../components/profile/Checkform'
import Meta from '../components/Meta'

const ProfileScreen = () => {
  const [tabState, setTabState] = useState('tab1')

  return (
    <>
      <Meta title="マイページ | m&s Fabric" />
      <div className="flex justify-center">
        <div className="screen-container mt-5 w-full">
          <div className="text-sm breadcrumbs text-gray-500 mb-10">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>My Page</li>
            </ul>
          </div>

          <div className="container">
            <div
              role="tablist"
              className="tabs tabs-boxed mb-14 overflow-x-scroll"
            >
              <Link
                role="tab"
                className={`tab ${
                  tabState === 'tab1' ? 'tab-active' : ''
                } text-xs md:text-sm whitespace-nowrap`}
                onClick={() => setTabState('tab1')}
              >
                注文履歴
              </Link>
              <Link
                role="tab"
                className={`tab ${
                  tabState === 'tab2' ? 'tab-active' : ''
                } text-xs md:text-sm whitespace-nowrap`}
                onClick={() => setTabState('tab2')}
              >
                追加購入
              </Link>
              <Link
                role="tab"
                className={`tab ${
                  tabState === 'tab3' ? 'tab-active' : ''
                } text-xs md:text-sm whitespace-nowrap`}
                onClick={() => setTabState('tab3')}
              >
                フォーム
              </Link>
              <Link
                role="tab"
                className={`tab ${
                  tabState === 'tab4' ? 'tab-active' : ''
                } text-xs md:text-sm whitespace-nowrap`}
                onClick={() => setTabState('tab4')}
              >
                お客様情報
              </Link>
            </div>
            <div>
              {tabState === 'tab1' ? (
                <OrderInfo />
              ) : tabState === 'tab2' ? (
                <TailorSize />
              ) : tabState === 'tab3' ? (
                <Checkform />
              ) : (
                <CustomerInfo />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileScreen
