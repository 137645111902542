const newsletterInform = (contents) => {
  return `
  以下のメールアドレスでニュースレター購読の登録がありました。

  ${contents.email}

  SendGridに登録してください。
  `
}

export { newsletterInform }
