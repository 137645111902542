import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  useVerifyTokenQuery,
  useResetPasswordMutation,
} from '../store/apis/forgetPasswordApi'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { toast } from 'react-toastify'

const PasswordResetScreen = () => {
  const [password, setPassword] = useState('')
  const [tokenVerified, setTokenVerified] = useState(false)
  const [searchParams] = useSearchParams()
  const { data: token, isLoading, error } = useVerifyTokenQuery()
  const [resetPassword] = useResetPasswordMutation()
  const navigate = useNavigate()

  useEffect(() => {
    if (searchParams.get('resetToken') === token) {
      setTokenVerified(true)
    } else {
      setTokenVerified(false)
    }
  }, [searchParams, token])

  const submitHandler = async (e) => {
    e.preventDefault()

    try {
      await resetPassword({ password }).unwrap()
      navigate('/login')
      toast.success('パスワードはリセットされました')
    } catch (error) {
      toast.error('送信できませんでした')
    }
  }

  return (
    <div className="container mt-14 lg:mt-20 flex justify-center">
      <div className="w-full md:w-96">
        <h2 className="page-title">Reset Password</h2>
        <p className="page-description">パスワードリセット</p>
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant={'alert-warning'}>認証されていません</Message>
        ) : tokenVerified ? (
          <form onSubmit={submitHandler}>
            <div className="form-control w-full mb-5">
              <label className="label">
                <span className="label-text">新しいパスワード</span>
              </label>
              <input
                type="password"
                className="input input-bordered w-full"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {isLoading ? (
              <button className="btn w-full mt-10">
                <span className="loading loading-spinner"></span>
                ローディング
              </button>
            ) : (
              <button type="submit" className="btn btn-primary w-full mt-10">
                パスワードをリセットする
              </button>
            )}
          </form>
        ) : (
          <Message variant={'alert-warning'}>トークンが一致しません</Message>
        )}
      </div>
    </div>
  )
}

export default PasswordResetScreen
