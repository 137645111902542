import { useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import gsap from 'gsap'
import TailorProcess from '../../components/TailorProcess'
import ChangeNeck from '../../components/tailorItems/ChangeNeck'

const TailorNeckScreen = () => {
  const navigate = useNavigate()
  const comp = useRef()
  const { tailorItems } = useSelector((state) => state.tailorSpec)

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from('.g-items', {
        opacity: 0,
        ease: 'power2',
        duration: 1.5,
      })
    }, comp)
    return () => ctx.revert()
  }, [])

  const nextStepHandler = () => {
    navigate('/tailor-tshirts/size')
  }

  return (
    <div data-theme="black" className="flex flex-col items-center grow">
      <div className="grow screen-container mt-5 mb-5 flex flex-col w-full">
        <div ref={comp} className="flex flex-col grow">
          <div className="g-items grow lg:container h-full my-14 flex flex-col justify-between overscroll-x-auto relative">
            <TailorProcess step1 step2 />
            <h3 className="text-xl text-center mb-20">
              <span className="text-xs inline-block w-14 h-5 text-white bg-gray-600 rounded-full mr-3 pt-0.5 align-middle mb-1">
                Step 2
              </span>
              ネックとリブ
            </h3>
            <div className="grow">
              <ChangeNeck />
            </div>
            <div className="fixed w-full bottom-0 left-0 p-8 bg-neutral-700 flex justify-center">
              {tailorItems.neck ? (
                <button
                  onClick={nextStepHandler}
                  className="container btn btn-outline leading-6 h-16 font-normal"
                  disabled={!tailorItems.neck}
                >
                  【 {tailorItems.neck} / {tailorItems.neckLib}cm 】
                  <br className="hidden" />
                  で次に進む
                </button>
              ) : (
                <button
                  onClick={nextStepHandler}
                  className="container btn btn-outline leading-6 h-16 font-normal"
                  disabled={!tailorItems.neck}
                >
                  各項目を選択して決定してください
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TailorNeckScreen
