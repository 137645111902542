import { rootApi } from './rootApi'
import { SHIPPING_URL } from '../../constants'

export const shippingCostApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getShippingCost: builder.query({
      query: () => ({
        url: SHIPPING_URL,
      }),
      keepUnusedDataFor: 5,
    }),
  }),
})

export const { useGetShippingCostQuery } = shippingCostApi
