import { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { toast } from 'react-toastify'
import {
  useGetCustomizeTshirtDetailsQuery,
  useUpdateCustomizeTshirtMutation,
} from '../../store/apis/customizeTshirtsApi'

const CustomizeEditScreen = () => {
  const { id: customizeId } = useParams()

  const [name, setName] = useState('')
  const [image, setImage] = useState('')
  const [colors, setColors] = useState([])
  const [description, setDescription] = useState('')
  const [fabric, setFabric] = useState('')
  const [category, setCategory] = useState('')
  const [type, setType] = useState('')
  const [features, setFeatures] = useState([])
  const [care, setCare] = useState('')
  const [price, setPrice] = useState(0)
  const [newItem, setNewItem] = useState(false)

  const {
    data: customizeTshirt,
    isLoading,
    refetch,
    error,
  } = useGetCustomizeTshirtDetailsQuery(customizeId)

  console.log(customizeTshirt)
  console.log(colors)

  const [updateCustomizeTshirt, { isLoading: loadingUpdate }] =
    useUpdateCustomizeTshirtMutation()

  const navigate = useNavigate()

  useEffect(() => {
    if (customizeTshirt) {
      setName(customizeTshirt.name)
      setImage(customizeTshirt.image)
      setColors(customizeTshirt.colors)
      setDescription(customizeTshirt.description)
      setFabric(customizeTshirt.fabric)
      setCategory(customizeTshirt.category)
      setFeatures(customizeTshirt.features)
      setType(customizeTshirt.type)
      setCare(customizeTshirt.care)
      setPrice(customizeTshirt.price)
      setNewItem(customizeTshirt.newItem)
    }
  }, [customizeTshirt])

  const colorHandler = (e) => {
    console.log('color')
  }

  return (
    <div className="flex justify-center">
      <div className="screen-container mt-5 w-full mb-20">
        <div className="text-sm breadcrumbs text-gray-500 mb-10">
          <ul>
            <li>
              <Link to="/admin/productlist">Product List</Link>
            </li>
            <li>{customizeId}</li>
          </ul>
        </div>
        {loadingUpdate && <Loader />}
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message />
        ) : (
          <div className="container">
            <div className="mb-10">
              <h2 className="page-title">{customizeTshirt.name}</h2>
            </div>
            <div className="flex w-full">
              <div className="basis-1/3">
                <img src={customizeTshirt.image} alt="" />
              </div>
              <form className="basis-2/3"></form>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CustomizeEditScreen
