import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useGetUserDetailsQuery } from '../../store/apis/usersApi'
import CustomerInfoEdit from '../../components/admin/CustomerInfoEdit'
import CreateTailorSize from '../../components/admin/CreateTailorSize'
import TailorSizeEdit from '../../components/admin/TailorSizeEdit'
import Loader from '../../components/Loader'
import Message from '../../components/Message'

const UserEditScreen = () => {
  const [tabState, setTabState] = useState('tab1')
  const { id: userId } = useParams()
  const { data: user, isLoading, error } = useGetUserDetailsQuery(userId)

  return (
    <div className="flex justify-center">
      <div className="screen-container mt-5 w-full">
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message>ユーザー情報を取得できませんでした</Message>
        ) : (
          <>
            <div className="text-sm breadcrumbs text-gray-500 mb-10">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/admin/userlist">Users List</Link>
                </li>
                <li>{user.lastname + ' ' + user.firstname}</li>
              </ul>
            </div>

            <div className="container">
              <div role="tablist" className="tabs tabs-boxed mb-14">
                <a
                  role="tab"
                  className={`tab ${tabState === 'tab1' ? 'tab-active' : ''}`}
                  onClick={() => setTabState('tab1')}
                >
                  お客様情報
                </a>
                <a
                  role="tab"
                  className={`tab ${tabState === 'tab2' ? 'tab-active' : ''}`}
                  onClick={() => setTabState('tab2')}
                >
                  テイラーサイズ追加
                </a>
                <a
                  role="tab"
                  className={`tab ${tabState === 'tab3' ? 'tab-active' : ''}`}
                  onClick={() => setTabState('tab3')}
                >
                  テイラーサイズ一覧
                </a>
              </div>
              <div>
                {tabState === 'tab1' ? (
                  <CustomerInfoEdit user={user} />
                ) : tabState === 'tab2' ? (
                  <CreateTailorSize />
                ) : (
                  <TailorSizeEdit user={user} />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default UserEditScreen
