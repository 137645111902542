import { Link } from 'react-router-dom'
import Loader from '../Loader'
import Message from '../Message'
import { useGetMyOrdersQuery } from '../../store/apis/ordersApi'
import { GrDocumentText } from 'react-icons/gr'

const OrderInfo = () => {
  const { data: orders, isLoading, error } = useGetMyOrdersQuery()

  return (
    <div className="flex mb-20 w-full justify-center items-center overflow-hidden">
      <div className="grow overflow-x-scroll">
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant={'alert-error'}>
            一度ログアウトしてから再度ログインしてください。解決しない場合はショップにお問い合わせください。
          </Message>
        ) : (
          <>
            {orders.length < 1 ? (
              <Message variant={'alert-info'}>注文された商品ありません</Message>
            ) : (
              <table className="table text-sm text-center">
                <thead>
                  <tr className="text-xs h-16 text-center">
                    <th className="hidden flex-none w-48 lg:w-80 md:table-cell">
                      注文No
                    </th>
                    <th className="px-0">日付</th>
                    <th className="px-0">ご注文金額</th>
                    <th className="px-0">決済</th>
                    <th className="px-0">発送</th>
                    <th className="px-0">詳細</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr key={order._id} className="h-12 text-center">
                      <td className="hidden md:table-cell">{order._id}</td>
                      <td className="whitespace-nowrap">
                        {order.createdAt.substring(0, 10)}
                      </td>
                      <td className="whitespace-nowrap">
                        {order.totalPrice.toLocaleString()}円
                      </td>
                      <td className="text-xs">
                        {order.isPaid ? (
                          <span className="text-teal-600 font-bold whitespace-nowrap">
                            支払済
                          </span>
                        ) : (
                          <span className="text-yellow-600 font-bold whitespace-nowrap">
                            確認中
                          </span>
                        )}
                      </td>
                      <td className="text-xs">
                        {order.isDelivered ? (
                          <span className="text-teal-600 font-bold whitespace-nowrap">
                            発送済
                          </span>
                        ) : (
                          <span className="text-yellow-600 font-bold whitespace-nowrap">
                            準備中
                          </span>
                        )}
                      </td>
                      <td>
                        <Link to={`/orders/${order._id}`}>
                          <button className="btn btn-sm btn-ghost text-base">
                            <GrDocumentText />
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default OrderInfo
