import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useGetTailorSizeQuery } from '../../store/apis/usersApi'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import TailorBodySizeHalfO from '../tailorItems/TailorBodySizeHalfO'
import TailorBodySizeHalfV from '../tailorItems/TailorBodySizeHalfV'
import TailorBodySizeLongO from '../tailorItems/TailorBodySizeLongO'
import TailorBodySizeLongV from '../tailorItems/TailorBodySizeLongV'
import TailorNeckSizeO from '../tailorItems/TailorNeckSizeO'
import TailorNeckSizeV from '../tailorItems/TailorNeckSizeV'
import { changeTailorSpecForRepeat } from '../../store/slices/tailorSlice'

const TailorSize = () => {
  const { data: tailorSizes, isLoading, error } = useGetTailorSizeQuery()
  const [tailorSize, setTailorSize] = useState('')
  const [fixSizeRequest, setFixSizeRequest] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const modalHandler = (size) => {
    document.getElementById('my_modal_4').showModal()
    setTailorSize(size)
  }

  const repeatOrderHandler = (name, bodyType) => {
    dispatch(
      changeTailorSpecForRepeat({
        size: name,
        type: bodyType,
        fixSize: fixSizeRequest,
      })
    )
    navigate('/repeat-order')
  }

  return (
    <div className="flex justify-center w-full mb-20">
      <div className="grow">
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant="alert-error">エラー</Message>
        ) : tailorSizes.length <= 0 ? (
          <Message variant="alert-info">
            テイラーTシャツのご利用はありません。
          </Message>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 w-full justify-center mb-20">
            <div className="text-sm flex flex-col justify-center bg-sky-100 rounded p-5">
              <p className="mb-2">
                テイラーTシャツを一度ご利用されたお客様はこちらから以前のサイズを使用して追加注文いただけます。
              </p>
              <p>
                ご注文は表示されているサイズから使用したいサイズを選択してお進みください。
              </p>
            </div>
            {tailorSizes.map((size) => (
              <div
                key={size._id}
                className="border rounded p-5 hover:bg-neutral-100 cursor-pointer flex"
                onClick={() => modalHandler(size)}
              >
                <div className="w-28 mr-5">
                  {size.bodyType === 'Tシャツ' && size.neckType === 'O-neck' ? (
                    <img src="/images/tailorImg/tshirt-o.svg" alt="" />
                  ) : size.bodyType === 'Tシャツ' &&
                    size.neckType === 'V-neck' ? (
                    <img src="/images/tailorImg/tshirt-v.svg" alt="" />
                  ) : size.bodyType === 'ロングTシャツ' &&
                    size.neckType === 'O-neck' ? (
                    <img src="/images/tailorImg/tshirt-o-long.svg" alt="" />
                  ) : (
                    <img src="/images/tailorImg/tshirt-v-long.svg" alt="" />
                  )}
                </div>
                <div>
                  <h4 className="mb-3">{size.name}</h4>
                  <ul className="text-sm">
                    <li>
                      <span className="text-xs">ボディタイプ：</span>
                      {size.bodyType}
                    </li>
                    <li>
                      <span className="text-xs">ネックタイプ：</span>
                      {size.neckType}
                    </li>
                    <li className="mt-2">
                      <span className="text-xs">最終更新日：</span>
                      {size.updatedAt.substring(0, 10)}
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <dialog id="my_modal_4" className="modal modal-bottom sm:modal-middle">
        <div className="modal-box lg:w-11/12 lg:max-w-5xl">
          <div className="flex flex-col lg:flex-row mt-10 lg:pr-14 mb-5">
            <div className="w-full lg:w-1/2">
              {tailorSize.bodyType === 'Tシャツ' &&
              tailorSize.neckType === 'O-neck' ? (
                <TailorBodySizeHalfO size={tailorSize} />
              ) : tailorSize.bodyType === 'Tシャツ' &&
                tailorSize.neckType === 'V-neck' ? (
                <TailorBodySizeHalfV size={tailorSize} />
              ) : tailorSize.bodyType === 'ロングTシャツ' &&
                tailorSize.neckType === 'O-neck' ? (
                <TailorBodySizeLongO size={tailorSize} />
              ) : (
                <TailorBodySizeLongV size={tailorSize} />
              )}
            </div>
            <div className="w-full lg:w-1/2 flex flex-col justify-between pb-5">
              {tailorSize.neckType === 'O-neck' ? (
                <TailorNeckSizeO size={tailorSize} />
              ) : (
                <TailorNeckSizeV size={tailorSize} />
              )}
              <div>
                {tailorSize.comment && (
                  <div className="flex mt-10 text-sm/6 mb-10">
                    <div className="flex flex-none items-center justify-center w-20 text-xs border p-1 mr-5 border-gray-700">
                      コメント
                    </div>
                    <p>{tailorSize.comment}</p>
                  </div>
                )}
                <textarea
                  className="textarea textarea-bordered w-full"
                  placeholder="サイズ変更をご希望の場合は番号とサイズを記入してください"
                  value={fixSizeRequest}
                  onChange={(e) => setFixSizeRequest(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>

          <ul className="text-sm list-disc p-3 pl-10 bg-red-50 mb-5">
            <li>
              大幅なサイズ変更は全体のバランスが崩れる原因になるため、修正は2ヶ所程度、±2cm程度の小規模なものに限らせていただきます。
            </li>
            <li>
              Tシャツの状態や計測方法でサイズは異なることがあるため、1cm未満の修正は反映されない場合があります。
            </li>
            <li>
              現在のサイズから大きくサイズを変更したい場合は、再度サンプル付きの新規テイラーTシャツをご利用ください。
            </li>
          </ul>

          <div
            onClick={() =>
              repeatOrderHandler(tailorSize.name, tailorSize.bodyType)
            }
            className="btn btn-primary w-full"
          >
            このサイズで注文に進む
          </div>

          <div className="modal-action">
            <form method="dialog">
              <button className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5">
                ✕
              </button>
            </form>
          </div>
        </div>
      </dialog>
    </div>
  )
}

export default TailorSize
