import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useCreateOrderMutation } from '../store/apis/ordersApi'
import { useSendMailMutation } from '../store/apis/sendMailApi'
import { clearCartItems } from '../store/slices/cartSlice'
import { orderMail } from '../mail/orderMail'
import Loader from '../components/Loader'
import Meta from '../components/Meta'

const CheckoutSuccessScreen = () => {
  const [order, setOrder] = useState()
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state) => state.auth)
  const cart = useSelector((state) => state.cart)
  const [createOrder, { isLoading }] = useCreateOrderMutation()
  const [sendMail] = useSendMailMutation()

  useEffect(() => {
    if (cart.cartItems.length !== 0) {
      const addOrderHandler = async () => {
        try {
          const res = await createOrder({
            orderItems: cart.cartItems,
            shippingAddress: cart.shippingAddress,
            paymentMethod: cart.paymentMethod,
            itemsPrice: cart.itemsPrice,
            shippingPrice: cart.shippingPrice,
            totalPrice: cart.totalPrice,
            comment: cart.comment,
          }).unwrap()
          setOrder(res)
        } catch (error) {
          console.log(error)
        }
      }
      addOrderHandler()
    }
  }, [cart, createOrder])

  useEffect(() => {
    if (order && order.orderItems.length !== 0) {
      const sendMailHandler = async () => {
        const msg = {
          to: userInfo.email,
          from: 'info@mnsfabric.com',
          bcc: 'info@mnsfabric.com',
          subject: `${userInfo.lastname} ${userInfo.firstname} 様 ご注文ありがとうございます`,
          text: orderMail(userInfo, order),
        }
        try {
          await sendMail(msg)
          dispatch(clearCartItems())
        } catch (error) {
          console.log(error)
        }
      }
      sendMailHandler()
    }
  }, [order, dispatch, sendMail, userInfo])

  // complete order for Google Ad
  const script = `gtag('event', 'conversion', {'send_to': 'AW-16646522219/DqqyCP7-w8cZEOuK14E-', 'transaction_id': ''});`

  return isLoading ? (
    <div className="container grow flex justify-center items-center h-full">
      <Loader />
    </div>
  ) : (
    <>
      <Meta title="注文完了 | m&s Fabric" script={script} />
      <div className="container grow flex justify-center items-center h-full">
        <div className="w-full text-center">
          <h2 className="text-2xl font-bold mb-5">
            ご注文ありがとうございました！
          </h2>
          <p className="text-sm/7">
            注文内容を確認の上、担当者より改めてご連絡させていただきます。
            <br />
            自動返信メールが届かない方はお手数ですがご連絡ください。
          </p>
          <Link to="/" className="btn btn-priary mt-10">
            トップに戻る
          </Link>
        </div>
      </div>
    </>
  )
}

export default CheckoutSuccessScreen
