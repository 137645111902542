import { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useLoginMutation } from '../store/apis/usersApi'
import { setCredentials } from '../store/slices/authSlice'
import { toast } from 'react-toastify'
import Meta from '../components/Meta'

const LoginScreen = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [login, { isLoading }] = useLoginMutation()

  const { userInfo } = useSelector((state) => state.auth)

  const { search } = useLocation()
  const sp = new URLSearchParams(search)
  const redirect = sp.get('redirect') || '/'

  useEffect(() => {
    if (userInfo) {
      navigate(redirect)
    }
  }, [userInfo, redirect, navigate])

  const loginHandler = async (e) => {
    e.preventDefault()

    try {
      const res = await login({ email, password }).unwrap()
      dispatch(setCredentials({ ...res }))
      navigate(redirect)
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  return (
    <>
      <Meta title="ログイン | m&s Fabric" />
      <div className="container mt-14 lg:mt-20 flex justify-center">
        <div className="w-full md:w-96">
          <h2 className="page-title">Login</h2>
          <p className="page-description">ログイン</p>
          <form onSubmit={loginHandler}>
            <div className="form-control w-full mb-5">
              <label className="label">
                <span className="label-text">メールアドレス</span>
              </label>
              <input
                type="email"
                className="input input-bordered w-full"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-control w-full mb-5">
              <label className="label">
                <span className="label-text">パスワード</span>
              </label>
              <input
                type="password"
                className="input input-bordered w-full"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            {isLoading ? (
              <button className="btn w-full mt-10">
                <span className="loading loading-spinner"></span>
                ローディング
              </button>
            ) : (
              <button type="submit" className="btn btn-primary w-full mt-10">
                ログイン
              </button>
            )}
          </form>
          <div className="mt-3 w-full">
            <div className="text-sm text-right hover:underline">
              <Link to="/forgot-password">パスワードをお忘れですか？</Link>
            </div>
          </div>
          <div className="flex items-center mt-10">
            <button className="btn btn-outline btn-sm text-xs mr-3">
              <Link
                to={redirect ? `/register?redirect=${redirect}` : '/register'}
              >
                サインアップ
              </Link>
            </button>
            <p className="text-sm">初めての方はこちらから登録してください</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginScreen
