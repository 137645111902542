import { Link, useParams } from 'react-router-dom'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import PaginateUsers from '../../components/PaginateUsers'
import SearchBox from '../../components/SearchBox'
import { toast } from 'react-toastify'
import {
  useGetUsersQuery,
  useDeleteUserMutation,
} from '../../store/apis/usersApi'
import { FaTrash, FaEdit, FaCheck } from 'react-icons/fa'

const UserListScreen = () => {
  const { pageNumber, keyword } = useParams()
  const { data, refetch, isLoading, error } = useGetUsersQuery({
    keyword,
    pageNumber,
  })

  const [deleteUser, { isLoading: loadingDelete }] = useDeleteUserMutation()

  const deleteHandler = async (id, name) => {
    if (window.confirm(`${name}さんの登録を消去してもよろしいですか？`)) {
      try {
        await deleteUser(id)
        toast.success(`${name}さんを消去しました`)
        refetch()
      } catch (error) {
        toast.error('消去できませんでした')
      }
    }
  }

  return (
    <div className="flex justify-center">
      <div className="screen-container mt-5 w-full mb-20">
        <div className="text-sm breadcrumbs text-gray-500 mb-10">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Users list</li>
          </ul>
        </div>
        <div className="flex items-center justify-between mb-10">
          <div className="flex">
            <h2 className="page-title">All Users</h2>
            {keyword && (
              <Link to="/admin/userlist" className="btn ml-5">
                戻る
              </Link>
            )}
          </div>
          <SearchBox />
        </div>
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant={'alert-error'}>ユーザーがいません</Message>
        ) : (
          <div className="overflow-x-auto mb-20">
            <table className="table">
              {/* head */}
              <thead>
                <tr>
                  <th>ID</th>
                  <th>名前</th>
                  <th>フリガナ</th>
                  <th>メール</th>
                  <th>管理者</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.users.map((user) => (
                  <tr key={user._id}>
                    <td>{user._id}</td>
                    <td>
                      {user.lastname} {user.firstname}
                    </td>
                    <td>
                      {user.lastnameYomi} {user.firstnameYomi}
                    </td>
                    <td>{user.email}</td>
                    <td>{user.isAdmin && <FaCheck />}</td>
                    <td>
                      <Link to={`/admin/user/${user._id}/edit`}>
                        <button className="btn btn-sm btn-ghost text-xs">
                          <FaEdit />
                        </button>
                      </Link>
                      {loadingDelete ? (
                        <button className="btn btn-square btn-sm">
                          <span className="loading loading-spinner"></span>
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn-ghost"
                          onClick={() => deleteHandler(user._id, user.lastname)}
                        >
                          <FaTrash />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="w-full flex justify-center">
          {data && (
            <PaginateUsers
              pages={data.pages}
              page={data.page}
              keyword={keyword ? keyword : ''}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default UserListScreen
