import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  fabricSamples: [],
}

export const fabricSampleSlice = createSlice({
  name: 'fabricSample',
  initialState,
  reducers: {
    addFabricSample: (state, action) => {
      const sample = action.payload
      state.fabricSamples = [...state.fabricSamples, sample]
    },
    removeFabricSample: (state, action) => {
      state.fabricSamples = state.fabricSamples.filter(
        (x) => x.id !== action.payload
      )
    },
    deleteFabricSample: (state, action) => {
      state.fabricSamples = []
    },
  },
})

export const { addFabricSample, removeFabricSample, deleteFabricSample } =
  fabricSampleSlice.actions

export default fabricSampleSlice.reducer
