import { useState, useEffect } from 'react'
import {
  useUpdateTailorSizeMutation,
  useDeleteTailorSizeMutation,
} from '../../store/apis/usersApi'
import { toast } from 'react-toastify'

const TailorSizeEdit = ({ user }) => {
  const { tailorSize: tailorSizes } = user
  const [tailorSize, setTailorSize] = useState('')
  const sortItems = [...tailorSizes].reverse()

  const [name, setName] = useState('')
  const [bodyType, setBodyType] = useState('')
  const [length, setLength] = useState('')
  const [chest, setChest] = useState('')
  const [waist, setWaist] = useState('')
  const [hem, setHem] = useState('')
  const [shoulder, setShoulder] = useState('')
  const [shoulderLine, setShoulderLine] = useState('')
  const [armpit, setArmpit] = useState('')
  const [sleeveHalf, setSleeveHalf] = useState('')
  const [sleeveLong, setSleeveLong] = useState('')
  const [arm, setArm] = useState('')
  const [wrist, setWrist] = useState('')
  const [neckType, setNeckType] = useState('')
  const [neckWidth, setNeckWidth] = useState('')
  const [neckHeight, setNeckHeight] = useState('')
  const [comment, setComment] = useState('')

  const [updateTailorSize, { isLoading: loadingUpdate }] =
    useUpdateTailorSizeMutation()
  const [deleteTailorSize, { isLoading: loadingDelete }] =
    useDeleteTailorSizeMutation()

  const modalHandler = (size) => {
    setTailorSize(size)
    document.getElementById('my_modal_4').showModal()
  }

  useEffect(() => {
    if (tailorSize) {
      setName(tailorSize.name === null ? '' : tailorSize.name)
      setBodyType(tailorSize.bodyType == null ? '' : tailorSize.bodyType)
      setLength(tailorSize.length === null ? '' : tailorSize.length)
      setChest(tailorSize.chest === null ? '' : tailorSize.chest)
      setWaist(tailorSize.waist === null ? '' : tailorSize.waist)
      setHem(tailorSize.hem === null ? '' : tailorSize.hem)
      setShoulder(tailorSize.shoulder === null ? '' : tailorSize.shoulder)
      setShoulderLine(
        tailorSize.shoulderLine === null ? '' : tailorSize.shoulderLine
      )
      setArmpit(tailorSize.armpit === null ? '' : tailorSize.armpit)
      setSleeveHalf(tailorSize.sleeveHalf === null ? '' : tailorSize.sleeveHalf)
      setSleeveLong(tailorSize.sleeveLong === null ? '' : tailorSize.sleeveLong)
      setArm(tailorSize.arm === null ? '' : tailorSize.arm)
      setWrist(tailorSize.wrist === null ? '' : tailorSize.wrist)
      setNeckType(tailorSize.neckType === null ? '' : tailorSize.neckType)
      setNeckWidth(tailorSize.neckWidth === null ? '' : tailorSize.neckWidth)
      setNeckHeight(tailorSize.neckHeight === null ? '' : tailorSize.neckHeight)
      setComment(tailorSize.comment === null ? '' : tailorSize.comment)
    }
  }, [tailorSize])

  const closeBtnHandler = () => {
    if (tailorSize) {
      setName(tailorSize.name === null ? '' : tailorSize.name)
      setBodyType(tailorSize.bodyType == null ? '' : tailorSize.bodyType)
      setLength(tailorSize.length === null ? '' : tailorSize.length)
      setChest(tailorSize.chest === null ? '' : tailorSize.chest)
      setWaist(tailorSize.waist === null ? '' : tailorSize.waist)
      setHem(tailorSize.hem === null ? '' : tailorSize.hem)
      setShoulder(tailorSize.shoulder === null ? '' : tailorSize.shoulder)
      setShoulderLine(
        tailorSize.shoulderLine === null ? '' : tailorSize.shoulderLine
      )
      setArmpit(tailorSize.armpit === null ? '' : tailorSize.armpit)
      setSleeveHalf(tailorSize.sleeveHalf === null ? '' : tailorSize.sleeveHalf)
      setSleeveLong(tailorSize.sleeveLong === null ? '' : tailorSize.sleeveLong)
      setArm(tailorSize.arm === null ? '' : tailorSize.arm)
      setWrist(tailorSize.wrist === null ? '' : tailorSize.wrist)
      setNeckType(tailorSize.neckType === null ? '' : tailorSize.neckType)
      setNeckWidth(tailorSize.neckWidth === null ? '' : tailorSize.neckWidth)
      setNeckHeight(tailorSize.neckHeight === null ? '' : tailorSize.neckHeight)
      setComment(tailorSize.comment === null ? '' : tailorSize.comment)
    }
  }

  const updateHandler = async () => {
    try {
      await updateTailorSize({
        _id: tailorSize._id,
        user: user._id,
        name,
        bodyType,
        length,
        chest,
        waist,
        hem,
        shoulder,
        shoulderLine,
        armpit,
        sleeveHalf,
        sleeveLong,
        arm,
        wrist,
        neckType,
        neckWidth,
        neckHeight,
        comment,
      })
      toast.success('サイズは更新されました')
    } catch (error) {
      toast.error(error)
    }
  }

  const deleteHandler = async () => {
    if (window.confirm('サイズを消去してもよろしいですか？')) {
      try {
        await deleteTailorSize({ _id: tailorSize._id, user: user._id })
        toast.success('サイズは削除されました')
      } catch (error) {
        toast.error(error)
      }
    }
  }

  const changeTimeFormat = (time) => {
    let date = new Date(time)
    const updateDateFormat =
      date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate()

    return updateDateFormat
  }

  return (
    <div className="flex justify-center w-full mb-20">
      <div className="grow">
        <div className="grid grid-cols-3 gap-5 w-full justify-center mb-20">
          {sortItems.map((size) => (
            <div
              key={size.updatedAt}
              className="border rounded p-5 hover:bg-neutral-100 cursor-pointer flex"
              onClick={() => modalHandler(size)}
            >
              <div className="w-28 mr-5">
                {size.bodyType === 'Tシャツ' && size.neckType === 'O-neck' ? (
                  <img src="/images/tailorImg/tshirt-o.svg" alt="" />
                ) : size.bodyType === 'Tシャツ' &&
                  size.neckType === 'V-neck' ? (
                  <img src="/images/tailorImg/tshirt-v.svg" alt="" />
                ) : size.bodyType === 'ロングTシャツ' &&
                  size.neckType === 'O-neck' ? (
                  <img src="/images/tailorImg/tshirt-o-long.svg" alt="" />
                ) : (
                  <img src="/images/tailorImg/tshirt-v-long.svg" alt="" />
                )}
              </div>
              <div className="flex flex-col w-full">
                <h4 className="mb-3">{size.name}</h4>
                <p className="text-sm">
                  <span className="text-xs">更新日：</span>
                  {changeTimeFormat(size.updatedAt)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <dialog id="my_modal_4" className="modal">
        <div className="modal-box w-11/12 max-w-5xl pr-14">
          <div className="flex mt-10">
            <div className="w-full">
              <div className="form-control w-full mb-5 flex flex-row items-center">
                <label className="flex-none text-sm w-24 text-right mr-3">
                  サイズネーム
                </label>
                <input
                  type="text"
                  className="input input-bordered text-sm w-96"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="flex gap-10 ">
                <div className="w-1/3">
                  <div className="form-control w-full mb-5 flex flex-row items-center">
                    <label className="flex-none text-sm w-24 text-right mr-3">
                      ボディタイプ
                    </label>
                    <select
                      className="select select-bordered grow"
                      value={bodyType}
                      onChange={(e) => setBodyType(e.target.value)}
                    >
                      <option value="Tシャツ">Tシャツ</option>
                      <option value="ロングTシャツ">ロングTシャツ</option>
                    </select>
                  </div>
                  <div className="form-control w-full mb-5 flex flex-row items-center">
                    <label className="flex-none text-sm w-24 text-right mr-3">
                      着丈
                    </label>
                    <input
                      type="number"
                      className="input input-bordered w-full"
                      value={length}
                      onChange={(e) => setLength(e.target.value)}
                    />
                  </div>
                  <div className="form-control w-full mb-5 flex flex-row items-center">
                    <label className="flex-none text-sm w-24 text-right mr-3">
                      着幅
                    </label>
                    <input
                      type="number"
                      className="input input-bordered w-full text-sm"
                      value={chest}
                      onChange={(e) => setChest(e.target.value)}
                    />
                  </div>
                  <div className="form-control w-full mb-5 flex flex-row items-center">
                    <label className="flex-none text-sm w-24 text-right mr-3">
                      ウエスト
                    </label>
                    <input
                      type="number"
                      className="input input-bordered w-full text-sm"
                      value={waist}
                      onChange={(e) => setWaist(e.target.value)}
                    />
                  </div>
                  <div className="form-control w-full mb-5 flex flex-row items-center">
                    <label className="flex-none text-sm w-24 text-right mr-3">
                      裾幅
                    </label>
                    <input
                      type="number"
                      className="input input-bordered w-full text-sm"
                      value={hem}
                      onChange={(e) => setHem(e.target.value)}
                    />
                  </div>
                  <div className="form-control w-full mb-5 flex flex-row items-center">
                    <label className="flex-none text-sm w-24 text-right mr-3">
                      肩幅
                    </label>
                    <input
                      type="number"
                      className="input input-bordered w-full text-sm"
                      value={shoulder}
                      onChange={(e) => setShoulder(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-1/3">
                  <div className="form-control w-full mb-5 flex flex-row items-center">
                    <label className="flex-none text-sm w-24 text-right mr-3">
                      肩傾斜
                    </label>
                    <input
                      type="number"
                      className="input input-bordered w-full text-sm"
                      placeholder="角度"
                      value={shoulderLine}
                      onChange={(e) => setShoulderLine(e.target.value)}
                    />
                  </div>
                  <div className="form-control w-full mb-5 flex flex-row items-center">
                    <label className="flex-none text-sm w-24 text-right mr-3">
                      脇高
                    </label>
                    <input
                      type="number"
                      className="input input-bordered w-full text-sm"
                      value={armpit}
                      onChange={(e) => setArmpit(e.target.value)}
                    />
                  </div>
                  <div className="form-control w-full mb-5 flex flex-row items-center">
                    <label className="flex-none text-sm w-24 text-right mr-3">
                      袖丈（半袖）
                    </label>
                    <input
                      type="number"
                      className="input input-bordered w-full text-sm"
                      value={sleeveHalf}
                      onChange={(e) => setSleeveHalf(e.target.value)}
                      disabled={bodyType === 'ロングTシャツ'}
                    />
                  </div>
                  <div className="form-control w-full mb-5 flex flex-row items-center">
                    <label className="flex-none text-sm w-24 text-right mr-3">
                      袖丈（長袖）
                    </label>
                    <input
                      type="number"
                      className="input input-bordered w-full text-sm"
                      value={sleeveLong}
                      onChange={(e) => setSleeveLong(e.target.value)}
                      disabled={bodyType === 'Tシャツ'}
                    />
                  </div>
                  <div className="form-control w-full mb-5 flex flex-row items-center">
                    <label className="flex-none text-sm w-24 text-right mr-3">
                      袖幅（半袖）
                    </label>
                    <input
                      type="number"
                      className="input input-bordered w-full text-sm"
                      value={arm}
                      onChange={(e) => setArm(e.target.value)}
                      disabled={bodyType === 'ロングTシャツ'}
                    />
                  </div>
                  <div className="form-control w-full mb-5 flex flex-row items-center">
                    <label className="flex-none text-sm w-24 text-right mr-3">
                      袖幅（長袖）
                    </label>
                    <input
                      type="number"
                      className="input input-bordered w-full text-sm"
                      value={wrist}
                      onChange={(e) => setWrist(e.target.value)}
                      disabled={bodyType === 'Tシャツ'}
                    />
                  </div>
                </div>
                <div className="1/3">
                  <div className="form-control w-full mb-5 flex flex-row items-center">
                    <label className="flex-none text-sm w-24 text-right mr-3">
                      ネックタイプ
                    </label>
                    <select
                      className="select select-bordered w-full max-w-xs"
                      value={neckType}
                      onChange={(e) => setNeckType(e.target.value)}
                    >
                      <option value="O-neck">O-neck</option>
                      <option value="V-neck">V-neck</option>
                    </select>
                  </div>
                  <div className="form-control w-full mb-5 flex flex-row items-center">
                    <label className="flex-none text-sm w-24 text-right mr-3">
                      首幅
                    </label>
                    <input
                      type="number"
                      className="input input-bordered w-full text-sm"
                      value={neckWidth}
                      onChange={(e) => setNeckWidth(e.target.value)}
                    />
                  </div>
                  <div className="form-control w-full mb-5 flex flex-row items-center">
                    <label className="flex-none text-sm w-24 text-right mr-3">
                      首深
                    </label>
                    <input
                      type="number"
                      className="input input-bordered w-full text-sm"
                      value={neckHeight}
                      onChange={(e) => setNeckHeight(e.target.value)}
                    />
                  </div>
                  <textarea
                    className="textarea textarea-bordered w-full my-5 h-40 leading-6"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="コメント"
                  ></textarea>
                </div>
              </div>

              <div className="flex gap-7 mt-5 w-full">
                <form method="dialog" className="w-1/2">
                  <button
                    onClick={updateHandler}
                    type="btn"
                    className="btn btn-primary w-full"
                  >
                    更新する
                  </button>
                </form>
                <form method="dialog" className="w-1/2">
                  <button
                    onClick={deleteHandler}
                    type="btn"
                    className="btn btn-outline w-full"
                  >
                    削除する
                  </button>
                </form>
              </div>
            </div>
          </div>

          <div className="modal-action">
            <form method="dialog">
              <button
                className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5"
                onClick={closeBtnHandler}
              >
                ✕
              </button>
            </form>
          </div>
        </div>
      </dialog>
    </div>
  )
}

export default TailorSizeEdit
