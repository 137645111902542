import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { changeTailorSpec } from '../../store/slices/tailorSlice'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { toast } from 'react-toastify'

const MeasureSize = ({ checkTabHandler }) => {
  const dispatch = useDispatch()
  const { tailorItems } = useSelector((state) => state.tailorSpec)

  const [tabState, setTabState] = useState('tab1')
  const [height, setHeight] = useState(
    tailorItems.bodySize ? tailorItems.bodySize.height : ''
  )
  const [chest, setChest] = useState(
    tailorItems.bodySize ? tailorItems.bodySize.chest : ''
  )
  const [waist, setWaist] = useState(
    tailorItems.bodySize ? tailorItems.bodySize.waist : ''
  )
  const [arm, setArm] = useState(
    tailorItems.bodySize ? tailorItems.bodySize.arm : ''
  )
  const [shoulderLength, setShoulderLength] = useState(
    tailorItems.shoulderSize ? tailorItems.shoulderSize.shoulderLength : ''
  )
  const [shoulderLine, setShoulderLine] = useState(
    tailorItems.shoulderSize ? tailorItems.shoulderSize.shoulderLine : ''
  )
  const [length, setLength] = useState(
    tailorItems.tshirtSize ? tailorItems.tshirtSize.length : ''
  )
  const [chestWidth, setChestWidth] = useState(
    tailorItems.tshirtSize ? tailorItems.tshirtSize.chestWidth : ''
  )
  const [waistWidth, setWaistWidth] = useState(
    tailorItems.tshirtSize ? tailorItems.tshirtSize.waistWidth : ''
  )
  const [hemWidth, setHemWidth] = useState(
    tailorItems.tshirtSize ? tailorItems.tshirtSize.hemWidth : ''
  )
  const [shoulderWidth, setShoulderWidth] = useState(
    tailorItems.tshirtSize ? tailorItems.tshirtSize.shoulderWidth : ''
  )
  const [armpitHeight, setArmpitHeight] = useState(
    tailorItems.tshirtSize ? tailorItems.tshirtSize.armpitHeight : ''
  )
  const [sleeveLength, setSleeveLength] = useState(
    tailorItems.tshirtSize ? tailorItems.tshirtSize.sleeveLength : ''
  )
  const [longSleeveLength, setLongSleeveLength] = useState(
    tailorItems.tshirtSize ? tailorItems.tshirtSize.longSleeveLength : ''
  )
  const [armWidth, setArmWidth] = useState(
    tailorItems.tshirtSize ? tailorItems.tshirtSize.armWidth : ''
  )
  const [wristWidth, setWristWidth] = useState(
    tailorItems.tshirtSize ? tailorItems.tshirtSize.wristWidth : ''
  )
  const [isYourPurpose, setIsYourPurpose] = useState(
    tailorItems.questions ? tailorItems.questions.isYourPurpose : '特にない'
  )
  const [favoriteTshirt, setFavoriteTshirt] = useState(
    tailorItems.questions ? tailorItems.questions.favoriteTshirt : '特にない'
  )
  const [favoriteSize, setFavoriteSize] = useState(
    tailorItems.questions ? tailorItems.questions.favoriteSize : '特にない'
  )
  const [comment, setComment] = useState(
    tailorItems.questions ? tailorItems.questions.comment : ''
  )

  const [hasDoneBody, setHasDoneBody] = useState(false)
  const [hasDoneBuild, setHasDoneBuild] = useState(false)
  const [hasDoneTshirt, setHasDoneTshirt] = useState(false)
  const [bodyOrTshirt, setBodyOrTshirt] = useState('')

  const [isAdmit, setIsAdmit] = useState(false)
  const [isCheckTerms, setIsCheckTerms] = useState(false)

  const bodySizeHandler = () => {
    if (height < 200 && chest < 160 && waist < 160 && arm < 50) {
      dispatch(
        changeTailorSpec({
          bodySize: {
            ...tailorItems.bodySize,
            height: Number(height).toFixed(1),
            chest: Number(chest).toFixed(1),
            waist: Number(waist).toFixed(1),
            arm: Number(arm).toFixed(1),
          },
        })
      )
      setHasDoneBody(true)
      setTabState('tab2')
    } else {
      toast.error('数値を範囲内に設定してください')
    }
  }

  const buildHandler = () => {
    dispatch(
      changeTailorSpec({
        shoulderSize: {
          ...tailorItems.shoulderSize,
          shoulderLength,
          shoulderLine,
        },
      })
    )
    setHasDoneBuild(true)
    setTabState('tab3')
  }

  const tshirtHandler = () => {
    if (tailorItems.type === 'T-shirts') {
      dispatch(
        changeTailorSpec({
          tshirtSize: {
            ...tailorItems.tshirtSize,
            length: Number(length).toFixed(1),
            chestWidth: Number(chestWidth).toFixed(1),
            waistWidth: Number(waistWidth).toFixed(1),
            hemWidth: Number(hemWidth).toFixed(1),
            shoulderWidth: Number(shoulderWidth).toFixed(1),
            armpitHeight: Number(armpitHeight).toFixed(1),
            sleeveLength: Number(sleeveLength).toFixed(1),
            armWidth: Number(armWidth).toFixed(1),
          },
        })
      )
    } else {
      dispatch(
        changeTailorSpec({
          tshirtSize: {
            ...tailorItems.tshirtSize,
            length: Number(length).toFixed(1),
            chestWidth: Number(chestWidth).toFixed(1),
            waistWidth: Number(waistWidth).toFixed(1),
            hemWidth: Number(hemWidth).toFixed(1),
            shoulderWidth: Number(shoulderWidth).toFixed(1),
            armpitHeight: Number(armpitHeight).toFixed(1),
            longSleeveLength: Number(longSleeveLength).toFixed(1),
            wristWidth: Number(wristWidth).toFixed(1),
          },
        })
      )
    }

    setHasDoneTshirt(true)
    setTabState('tab4')
  }

  const commentHandler = () => {
    dispatch(
      changeTailorSpec({
        questions: {
          ...tailorItems.questions,
          isYourPurpose,
          favoriteTshirt,
          favoriteSize,
          comment,
        },
      })
    )
    checkTabHandler(false)
  }

  const checkTermsHandler = () => {
    document.getElementById('my_modal_3').showModal()
    setIsCheckTerms(true)
  }

  console.log(tailorItems)

  return (
    <div id="tab">
      {/* タブメニュー */}
      <div role="tablist" className="tabs tabs-bordered mb-20">
        <Link
          role="tab"
          onClick={() => setTabState('tab1')}
          className={`tab ${tabState === 'tab1' && 'tab-active'}`}
        >
          計測
        </Link>
        <Link
          role="tab"
          onClick={() => hasDoneBody && setTabState('tab2')}
          className={`tab ${tabState === 'tab2' && 'tab-active'}`}
        >
          体格
        </Link>
        <Link
          role="tab"
          onClick={() => hasDoneBody && hasDoneBuild && setTabState('tab3')}
          className={`tab ${tabState === 'tab3' && 'tab-active'}`}
        >
          Tシャツ
        </Link>
        <Link
          role="tab"
          onClick={() =>
            hasDoneBody && hasDoneBuild && hasDoneTshirt && setTabState('tab4')
          }
          className={`tab ${tabState === 'tab4' && 'tab-active'}`}
        >
          質問
        </Link>
      </div>

      <div className="w-full flex justify-center">
        {/* タブメニュー計測 */}
        {tabState === 'tab1' ? (
          <div className="w-full flex flex-col md:flex-row justify-center items-center lg:gap-20">
            <div>
              <img
                className="w-48 mb-5"
                src="/images/tailorImg/body-normal.svg"
                alt=""
              />
            </div>
            <div className="w-80">
              <label className="form-control mb-3 flex flex-row items-center justify-between border-b border-gray-500">
                <div className="label">
                  <span className="label-text w-16 text-center mr-2">身長</span>
                </div>
                <input
                  type="number"
                  className="input w-full text-xl text-center"
                  value={height > 200 ? '' : height}
                  onChange={(e) => setHeight(e.target.value)}
                  max="200"
                  required
                />
                <span className="w-10 ml-2">cm</span>
              </label>
              {height > 200 && (
                <div className="text-red-500 text-sm text-right mt-2 w-full">
                  身長の上限は200cmです
                </div>
              )}

              <label className="form-control mb-3 flex flex-row items-center justify-between border-b border-gray-500">
                <div className="label">
                  <span className="label-text w-16 text-center mr-2">胸囲</span>
                </div>
                <input
                  type="number"
                  className="input w-full text-xl text-center"
                  value={chest > 160 ? '' : chest}
                  onChange={(e) => setChest(e.target.value)}
                />
                <span className="w-10 ml-2">cm</span>
              </label>
              {chest > 160 && (
                <div className="text-red-500 text-sm text-right mt-2 w-full">
                  胸幅の上限は160cmです
                </div>
              )}

              <label className="form-control mb-3 flex flex-row items-center justify-between border-b border-gray-500">
                <div className="label">
                  <span className="label-text w-16 text-center mr-2">
                    ウエスト
                  </span>
                </div>
                <input
                  type="number"
                  className="input w-full text-xl text-center"
                  value={waist > 160 ? '' : waist}
                  onChange={(e) => setWaist(e.target.value)}
                />
                <span className="w-10 ml-2">cm</span>
              </label>
              {waist > 160 && (
                <div className="text-red-500 text-sm text-right mt-2 w-full">
                  ウエストの上限は160cmです
                </div>
              )}

              <label className="form-control mb-3 flex flex-row items-center justify-between border-b border-gray-500">
                <div className="label">
                  <span className="label-text w-16 text-center mr-2">
                    腕回り
                  </span>
                </div>
                <input
                  type="number"
                  className="input w-full text-xl text-center"
                  value={arm > 50 ? '' : arm}
                  onChange={(e) => setArm(e.target.value)}
                />
                <span className="w-10 ml-2">cm</span>
              </label>
              {arm > 50 && (
                <div className="text-red-500 text-sm text-right mt-2 w-full">
                  腕回りの上限は50cmです
                </div>
              )}
              <AnchorLink offset="30" href="#tab" className="w-full">
                <button
                  onClick={bodySizeHandler}
                  className={`mt-7 btn btn-sm btn-outline rounded-full w-full bg-neutral-800 py-1 border-gray-500 hover:text-white hover:bg-neutral-700 ${
                    tailorItems.height &&
                    'bg-neutral-600 border border-white hover:border-white hover:bg-neutral-600'
                  }`}
                  disabled={!height || !chest || !waist || !arm}
                >
                  決定する
                </button>
              </AnchorLink>
            </div>
          </div>
        ) : tabState === 'tab2' ? (
          <div className="w-full flex flex-col md:flex-row justify-center items-center gap-10 md:gap-20">
            {/* タブメニュー体格 */}
            <div>
              <img
                className="w-64"
                src={
                  shoulderLength === '広い' && shoulderLine === '普通'
                    ? '/images/tailorImg/shoulder-wide-normal.svg'
                    : shoulderLength === '広い' && shoulderLine === 'いかり肩'
                    ? '/images/tailorImg/shoulder-wide-up.svg'
                    : shoulderLength === '広い' && shoulderLine === 'なで肩'
                    ? '/images/tailorImg/shoulder-wide-down.svg'
                    : shoulderLength === '広い' && shoulderLine === ''
                    ? '/images/tailorImg/shoulder-wide-normal.svg'
                    : shoulderLength === '狭い' && shoulderLine === '普通'
                    ? '/images/tailorImg/shoulder-narrow-normal.svg'
                    : shoulderLength === '狭い' && shoulderLine === 'いかり肩'
                    ? '/images/tailorImg/shoulder-narrow-up.svg'
                    : shoulderLength === '狭い' && shoulderLine === 'なで肩'
                    ? '/images/tailorImg/shoulder-narrow-down.svg'
                    : shoulderLength === '狭い' && shoulderLine === ''
                    ? '/images/tailorImg/shoulder-narrow-normal.svg'
                    : shoulderLength === '普通' && shoulderLine === 'いかり肩'
                    ? '/images/tailorImg/shoulder-normal-up.svg'
                    : shoulderLength === '普通' && shoulderLine === 'なで肩'
                    ? '/images/tailorImg/shoulder-normal-down.svg'
                    : '/images/tailorImg/shoulder-normal-normal.svg'
                }
                alt=""
              />
            </div>
            <div className="w-80">
              <div className="mb-5 md:mb-12">
                <p className="text-sm mb-5 text-center">
                  肩幅の広さはどれに該当しますか？
                </p>
                <div className="flex justify-between">
                  <div className="text-center mb-5 lg:mb-0">
                    <input
                      type="radio"
                      name="shoulderWide"
                      id="narrow"
                      value="狭い"
                      onChange={(e) => setShoulderLength(e.target.value)}
                      checked={shoulderLength === '狭い'}
                      className="hidden"
                    />
                    <label
                      className={`btn btn-sm btn-outline rounded-full w-24 py-1 border hover:text-white hover:bg-neutral-700 ${
                        shoulderLength === '狭い'
                          ? 'text-white border-white'
                          : 'text-neutral-500 border-neutral-700'
                      }`}
                      htmlFor="narrow"
                    >
                      狭い
                    </label>
                  </div>
                  <div className="text-center">
                    <input
                      type="radio"
                      name="shoulderWide"
                      id="normal"
                      value="普通"
                      onChange={(e) => setShoulderLength(e.target.value)}
                      checked={shoulderLength === '普通'}
                      className="hidden"
                    />
                    <label
                      className={`btn btn-sm btn-outline rounded-full w-24 py-1 border hover:text-white hover:bg-neutral-700 ${
                        shoulderLength === '普通'
                          ? 'text-white border-white'
                          : 'text-neutral-500 border-neutral-700'
                      }`}
                      htmlFor="normal"
                    >
                      普通
                    </label>
                  </div>
                  <div className="text-center">
                    <input
                      type="radio"
                      name="shoulderWide"
                      id="wide"
                      value="広い"
                      onChange={(e) => setShoulderLength(e.target.value)}
                      checked={shoulderLength === '広い'}
                      className="hidden"
                    />
                    <label
                      className={`btn btn-sm btn-outline rounded-full w-24 py-1 border hover:text-white hover:bg-neutral-700 ${
                        shoulderLength === '広い'
                          ? 'text-white border-white'
                          : 'text-neutral-500 border-neutral-700'
                      }`}
                      htmlFor="wide"
                    >
                      広い
                    </label>
                  </div>
                </div>
              </div>

              <div>
                <p className="text-sm mb-5 text-center">
                  肩の傾斜に特徴がありますか？
                </p>
                <div className="flex justify-between">
                  <div className="text-center mb-5 lg:mb-0">
                    <input
                      type="radio"
                      name="shoulderLine"
                      id="down"
                      value="なで肩"
                      onChange={(e) => setShoulderLine(e.target.value)}
                      checked={shoulderLine === 'なで肩'}
                      className="hidden"
                    />
                    <label
                      className={`btn btn-sm btn-outline rounded-full w-24 py-1 border hover:text-white hover:bg-neutral-700 ${
                        shoulderLine === 'なで肩'
                          ? 'text-white border-white'
                          : 'text-neutral-500 border-neutral-700'
                      }`}
                      htmlFor="down"
                    >
                      なで肩
                    </label>
                  </div>
                  <div className="text-center">
                    <input
                      type="radio"
                      name="shoulderLine"
                      id="normal2"
                      value="普通"
                      onChange={(e) => setShoulderLine(e.target.value)}
                      checked={shoulderLine === '普通'}
                      className="hidden"
                    />
                    <label
                      className={`btn btn-sm btn-outline rounded-full w-24 py-1 border hover:text-white hover:bg-neutral-700 ${
                        shoulderLine === '普通'
                          ? 'text-white border-white'
                          : 'text-neutral-500 border-neutral-700'
                      }`}
                      htmlFor="normal2"
                    >
                      普通
                    </label>
                  </div>
                  <div className="text-center">
                    <input
                      type="radio"
                      name="shoulderLine"
                      id="up"
                      value="いかり肩"
                      onChange={(e) => setShoulderLine(e.target.value)}
                      checked={shoulderLine === 'いかり肩'}
                      className="hidden"
                    />
                    <label
                      className={`btn btn-sm btn-outline rounded-full w-24 py-1 border hover:text-white hover:bg-neutral-700 ${
                        shoulderLine === 'いかり肩'
                          ? 'text-white border-white'
                          : 'text-neutral-500 border-neutral-700'
                      }`}
                      htmlFor="up"
                    >
                      いかり肩
                    </label>
                  </div>
                </div>
                <AnchorLink offset="30" href="#tab" className="w-full">
                  <button
                    onClick={buildHandler}
                    className={`mt-7 md:mt-14 btn btn-sm btn-outline rounded-full w-full bg-neutral-800 py-1 border-gray-500 hover:text-white hover:bg-neutral-700 ${
                      tailorItems.shoulderLength &&
                      tailorItems.shoulderLine &&
                      'bg-neutral-600 border border-white hover:border-white hover:bg-neutral-600'
                    }`}
                    disabled={!shoulderLength || !shoulderLine}
                  >
                    決定する
                  </button>
                </AnchorLink>
              </div>
            </div>
          </div>
        ) : tabState === 'tab3' ? (
          <div className="w-full xl:w-[80%] flex flex-col md:flex-row justify-center md:gap-20">
            <div className="w-full md:w-1/2 mb-5">
              <p className="text-center mb-3">
                ご希望の製作タイプを選んでください
              </p>
              <div className="flex justify-between mb-5 gap-5">
                <div className="text-center w-1/2">
                  <input
                    type="radio"
                    name="bodytshirt"
                    id="frombody"
                    value="ボディサイズから作る"
                    onChange={(e) => {
                      setHasDoneTshirt(false)
                      setBodyOrTshirt(e.target.value)
                    }}
                    checked={bodyOrTshirt === 'ボディサイズから作る'}
                    className="hidden"
                  />
                  <label
                    className={`w-full btn btn-sm btn-outline h-14 leading-5 pt-1 border hover:text-white hover:bg-neutral-700 ${
                      bodyOrTshirt === 'ボディサイズから作る'
                        ? 'text-white border-white'
                        : 'text-neutral-500 border-neutral-700'
                    }`}
                    htmlFor="frombody"
                  >
                    ボディサイズで製作
                  </label>
                </div>
                <div className="text-center w-1/2">
                  <input
                    type="radio"
                    name="bodytshirt"
                    id="fromtshirt"
                    value="Tシャツサイズから作る"
                    onChange={(e) => {
                      setHasDoneTshirt(false)
                      setBodyOrTshirt(e.target.value)
                    }}
                    checked={bodyOrTshirt === 'Tシャツサイズから作る'}
                    className="hidden"
                  />
                  <label
                    className={`w-full btn btn-sm btn-outline h-14 leading-5 pt-1 border hover:text-white hover:bg-neutral-700 ${
                      bodyOrTshirt === 'Tシャツサイズから作る'
                        ? 'text-white border-white'
                        : 'text-neutral-500 border-neutral-700'
                    }`}
                    htmlFor="fromtshirt"
                  >
                    Tシャツサイズで製作
                  </label>
                </div>
              </div>
              <p className="text-xs/5 mb-5">
                ボディサイズで製作の方も各箇所でご希望のサイズがあればご記入ください。Tシャツサイズで製作の方は全てご記入ください。
              </p>
              {tailorItems.type === 'T-shirts' ? (
                <img
                  src="/images/tailorImg/sizeChart_tailor-half.svg"
                  alt="テイラーサイズチャート"
                />
              ) : (
                <img
                  src="/images/tailorImg/sizeChart_tailor-long.svg"
                  alt="テイラーサイズチャート"
                />
              )}
            </div>
            <div className="w-full md:w-1/2">
              <label className="form-control mb-3 flex flex-row items-center justify-between border-b border-gray-500">
                <div className="label">
                  <span className="label-text w-16 text-center mr-2">着丈</span>
                </div>
                <input
                  type="number"
                  className="input w-full text-xl text-center"
                  value={length > 90 ? '' : length}
                  onChange={(e) => setLength(e.target.value)}
                />
                <span className="w-10 ml-2">cm</span>
              </label>
              {length > 90 && (
                <div className="text-red-500 text-sm text-right mt-2 w-full">
                  着丈の上限は90cmです
                </div>
              )}

              <label className="form-control mb-3 flex flex-row items-center justify-between border-b border-gray-500">
                <div className="label">
                  <span className="label-text w-16 text-center mr-2">着幅</span>
                </div>
                <input
                  type="number"
                  className="input w-full text-xl text-center"
                  value={chestWidth > 80 ? '' : chestWidth}
                  onChange={(e) => setChestWidth(e.target.value)}
                />
                <span className="w-10 ml-2">cm</span>
              </label>
              {chestWidth > 80 && (
                <div className="text-red-500 text-sm text-right mt-2 w-full">
                  着幅の上限は80cmです
                </div>
              )}

              <label className="form-control mb-3 flex flex-row items-center justify-between border-b border-gray-500">
                <div className="label">
                  <span className="label-text w-16 text-center mr-2">
                    ウエスト
                  </span>
                </div>
                <input
                  type="number"
                  className="input w-full text-xl text-center"
                  value={waistWidth > 80 ? '' : waistWidth}
                  onChange={(e) => setWaistWidth(e.target.value)}
                />
                <span className="w-10 ml-2">cm</span>
              </label>
              {waistWidth > 80 && (
                <div className="text-red-500 text-sm text-right mt-2 w-full">
                  ウエストの上限は80cmです
                </div>
              )}

              <label className="form-control mb-3 flex flex-row items-center justify-between border-b border-gray-500">
                <div className="label">
                  <span className="label-text w-16 text-center mr-2">裾幅</span>
                </div>
                <input
                  type="number"
                  className="input w-full text-xl text-center"
                  value={hemWidth > 80 ? '' : hemWidth}
                  onChange={(e) => setHemWidth(e.target.value)}
                />
                <span className="w-10 ml-2">cm</span>
              </label>
              {hemWidth > 80 && (
                <div className="text-red-500 text-sm text-right mt-2 w-full">
                  裾幅の上限は80cmです
                </div>
              )}

              <label className="form-control mb-3 flex flex-row items-center justify-between border-b border-gray-500">
                <div className="label">
                  <span className="label-text w-16 text-center mr-2">肩幅</span>
                </div>
                <input
                  type="number"
                  className="input w-full text-xl text-center"
                  value={shoulderWidth > 60 ? '' : shoulderWidth}
                  onChange={(e) => setShoulderWidth(e.target.value)}
                />
                <span className="w-10 ml-2">cm</span>
              </label>
              {shoulderWidth > 60 && (
                <div className="text-red-500 text-sm text-right mt-2 w-full">
                  肩幅の上限は60cmです
                </div>
              )}

              <label className="form-control mb-3 flex flex-row items-center justify-between border-b border-gray-500">
                <div className="label">
                  <span className="label-text w-16 text-center mr-2">脇高</span>
                </div>
                <input
                  type="number"
                  className="input w-full text-xl text-center"
                  value={armpitHeight > 30 ? '' : armpitHeight}
                  onChange={(e) => setArmpitHeight(e.target.value)}
                />
                <span className="w-10 ml-2">cm</span>
              </label>
              {armpitHeight > 35 && (
                <div className="text-red-500 text-sm text-right mt-2 w-full">
                  脇高の上限は35cmです
                </div>
              )}

              <label className="form-control mb-3 flex flex-row items-center justify-between border-b border-gray-500">
                <div className="label">
                  <span className="label-text w-16 text-center mr-2">袖丈</span>
                </div>
                {tailorItems.type === 'T-shirts' ? (
                  <input
                    type="number"
                    className="input w-full text-xl text-center"
                    value={sleeveLength > 40 ? '' : sleeveLength}
                    onChange={(e) => setSleeveLength(e.target.value)}
                  />
                ) : (
                  <input
                    type="number"
                    className="input w-full text-xl text-center"
                    value={longSleeveLength > 80 ? '' : longSleeveLength}
                    onChange={(e) => setLongSleeveLength(e.target.value)}
                  />
                )}
                <span className="w-10 ml-2">cm</span>
              </label>
              {tailorItems.type === 'T-shirts'
                ? sleeveLength > 40 && (
                    <div className="text-red-500 text-sm text-right mt-2 w-full">
                      袖丈の上限は40cmです
                    </div>
                  )
                : longSleeveLength > 80 && (
                    <div className="text-red-500 text-sm text-right mt-2 w-full">
                      袖丈の上限は80cmです
                    </div>
                  )}

              <label className="form-control mb-3 flex flex-row items-center justify-between border-b border-gray-500">
                <div className="label">
                  <span className="label-text w-16 text-center mr-2">袖幅</span>
                </div>
                {tailorItems.type === 'T-shirts' ? (
                  <input
                    type="number"
                    className="input w-full text-xl text-center"
                    value={armWidth > 50 ? '' : armWidth}
                    onChange={(e) => setArmWidth(e.target.value)}
                  />
                ) : (
                  <input
                    type="number"
                    className="input w-full text-xl text-center"
                    value={wristWidth > 20 ? '' : wristWidth}
                    onChange={(e) => setWristWidth(e.target.value)}
                  />
                )}
                <span className="w-10 ml-2">cm</span>
              </label>
              {tailorItems.type === 'T-shirts'
                ? armWidth > 50 && (
                    <div className="text-red-500 text-sm text-right mt-2 w-full">
                      袖幅の上限は50cmです
                    </div>
                  )
                : wristWidth > 20 && (
                    <div className="text-red-500 text-sm text-right mt-2 w-full">
                      袖幅の上限は20cmです
                    </div>
                  )}

              <AnchorLink offset="30" href="#tab" className="w-full">
                <button
                  onClick={tshirtHandler}
                  className={`mt-7 btn btn-sm btn-outline rounded-full w-full bg-neutral-800 py-1 border-gray-500 hover:text-white hover:bg-neutral-700 ${
                    tailorItems.height &&
                    'bg-neutral-600 border border-white hover:border-white hover:bg-neutral-600'
                  }`}
                  disabled={
                    bodyOrTshirt === 'ボディサイズから作る'
                      ? false
                      : bodyOrTshirt === 'Tシャツサイズから作る' &&
                        tailorItems.type === 'T-shirts'
                      ? !bodyOrTshirt ||
                        !height ||
                        !chestWidth ||
                        !waistWidth ||
                        !hemWidth ||
                        !shoulderWidth ||
                        !armpitHeight ||
                        !sleeveLength ||
                        !armWidth
                      : bodyOrTshirt === 'Tシャツサイズから作る' &&
                        tailorItems.type === 'Long T-shirts'
                      ? !bodyOrTshirt ||
                        !height ||
                        !chestWidth ||
                        !waistWidth ||
                        !hemWidth ||
                        !shoulderWidth ||
                        !armpitHeight ||
                        !longSleeveLength ||
                        !wristWidth
                      : true
                  }
                >
                  決定する
                </button>
              </AnchorLink>
            </div>
          </div>
        ) : (
          <div className="w-full lg:w-[80%] flex flex-col md:flex-row md:gap-14 justify-center items-start">
            <div className="w-full md:w-1/2">
              <label className="form-control w-full mb-7">
                <div className="label">
                  <span className="label-text">
                    オーダーメイドでTシャツを作ろうと思った理由は何ですか？
                  </span>
                </div>
                <select
                  className="select select-bordered bg-neutral-900"
                  onChange={(e) => setIsYourPurpose(e.target.value)}
                >
                  <option value="特にない">特にない</option>
                  <option value="体型に合ったTシャツが欲しい">
                    体型に合ったTシャツが欲しい
                  </option>
                  <option value="気に入っているTシャツを再現したい">
                    気に入っているTシャツを再現したい
                  </option>
                  <option value="こだわりが詰まったTシャツを作りたい">
                    こだわりが詰まったTシャツを作りたい
                  </option>
                  <option value="Tシャツ着用時のコンプレックスを緩和したい">
                    Tシャツ着用時のコンプレックスを緩和したい
                  </option>
                  <option value="どんなものか一度試してみたかった">
                    どんなものか一度試してみたかった
                  </option>
                </select>
              </label>

              <label className="form-control w-full mb-7">
                <div className="label">
                  <span className="label-text">
                    今回の製作で希望するサイズ感はありますか？
                  </span>
                </div>
                <select
                  className="select select-bordered bg-neutral-900"
                  onChange={(e) => setFavoriteSize(e.target.value)}
                >
                  <option value="特にない">特にない</option>
                  <option value="身体のラインを強調したタイトサイズ">
                    身体のラインを強調したタイトサイズ
                  </option>
                  <option value="体系に合わせて適度にゆとりのあるフィットサイズ">
                    体系に合わせて適度にゆとりのあるフィットサイズ
                  </option>
                  <option value="ゆったりとした余裕あるのルーズサイズ">
                    ゆったりとした余裕のあるルーズサイズ
                  </option>
                  <option value="肩が落ちるような大きなオーバーサイズ">
                    肩が落ちるようなオーバーサイズ
                  </option>
                </select>
              </label>

              <label className="form-control w-full mb-7">
                <div className="label">
                  <span className="label-text">
                    どのようなTシャツ生地がお好みですか？
                  </span>
                </div>
                <select
                  className="select select-bordered bg-neutral-900"
                  onChange={(e) => setFavoriteTshirt(e.target.value)}
                >
                  <option value="特にない">特にない</option>
                  <option value="軽くて圧迫感のない薄手の生地">
                    軽くて圧迫感のない薄手の生地
                  </option>
                  <option value="透けにくく重厚感のある厚手の生地">
                    透けにくく重厚感のある厚手の生地
                  </option>
                  <option value="体の動きを妨げない伸縮性の高い生地">
                    体の動きを妨げない伸縮性の高い生地
                  </option>
                  <option value="パリッとした硬めのしっかりした生地">
                    パリッとした硬めのしっかりした生地
                  </option>
                  <option value="柔らかく滑らかで肌心地の良い生地">
                    柔らかく滑らかで肌心地の良い生地
                  </option>
                  <option value="吸収速乾やUVカットなどの高機能な生地">
                    吸収速乾やUVカットなどの高機能な生地
                  </option>
                  <option value="型崩れやシワが少ないポリエステルなどの生地">
                    型崩れやシワが少ないポリエステルなどの生地
                  </option>
                </select>
              </label>
            </div>
            <div className="w-full md:w-1/2">
              <label className="form-control">
                <div className="label">
                  <span className="label-text">
                    お客様のご要望を考慮してパターンを制作しますので、ご希望や留意してほしい点などありましたら出来るだけ具体的にご記入ください
                  </span>
                </div>
                <textarea
                  className="textarea textarea-bordered h-48 bg-neutral-900"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
                <div className="flex justify-between items-center mt-5">
                  <div className="form-control">
                    <label className="label cursor-pointer">
                      <input
                        type="checkbox"
                        className="checkbox mr-3 bg-gray-300"
                        checked={isAdmit}
                        onChange={() => setIsAdmit(!isAdmit)}
                        disabled={!isCheckTerms}
                      />
                      <span className="label-text">
                        テイラーTシャツ利用規約を承諾する
                      </span>
                    </label>
                  </div>

                  <button
                    data-theme="lemonade"
                    className="btn btn-accent btn-sm font-medium"
                    onClick={checkTermsHandler}
                  >
                    規約を確認する
                  </button>
                  <dialog id="my_modal_3" className="modal">
                    <div data-theme="lofi" className="modal-box">
                      <form method="dialog">
                        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                          ✕
                        </button>
                      </form>
                      <h3 className="font-bold text-lg my-5">
                        テイラーTシャツ利用規約
                      </h3>
                      <p className="text-sm mb-5">
                        テイラーTシャツのご利用に関して予め下記項目についてご了承くださいますようよろしくお願いいたします。
                      </p>
                      <ul className="py-4 list-decimal text-sm p-5">
                        <li className="mb-3">
                          オーダー製作開始後の途中キャンセル・製作中断によるご返金は承っておりません。
                        </li>
                        <li className="mb-3">
                          製作過程に入った商品の生地や仕様の変更はできません。
                        </li>
                        <li className="mb-3">
                          Tシャツからかけ離れたデザイン、製作困難なサイズ・形状は当店の判断で注文をキャンセルさせていただく場合がございます。
                        </li>
                        <li className="mb-3">
                          Tシャツは伸縮素材ため生地の状態や計測の仕方によりサイズが異なります。1cm未満のサイズは反映されない場合があります。
                        </li>
                        <li className="mb-3">
                          洗濯や乾燥により綿素材は縮みますので、試着用サンプルを使ってお客様の洗濯・乾燥の環境で事前に縮み幅をお確かめください。また本製作は縮みを考慮したサイズでご指定ください。
                        </li>
                        <li className="mb-3">
                          生地在庫について万全を期すよう努めておりますが、万一在庫がない場合はご変更やキャンセルをお願いさせていただく場合がございます。その際は改めてご連絡いたします。
                        </li>
                        <li className="mb-3">
                          当店にお持ちのTシャツをお送りいただく事はお断りしております。
                        </li>
                        <li className="mb-3">
                          納品後の返品・交換・作り直し等は不可とさせていただきます。
                        </li>
                        <li className="mb-3">
                          テイラーTシャツのご注文確定は以上のお願いを承諾いただいたものとさせていただきます。
                        </li>
                      </ul>
                    </div>
                  </dialog>
                </div>
                <button
                  onClick={commentHandler}
                  className={`mt-10 btn btn-sm btn-outline rounded-full w-full bg-neutral-800 py-1 border-gray-500 hover:text-white hover:bg-neutral-700 ${
                    tailorItems.tshirtSize &&
                    'bg-neutral-600 border border-white hover:border-white hover:bg-neutral-600'
                  }`}
                  disabled={!isAdmit}
                >
                  決定する
                </button>
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MeasureSize
