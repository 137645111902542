const orderMail = (userInfo, order) => {
  return `
  ${userInfo.lastname} ${userInfo.firstname} 様

  この度はm&s Fabricをご利用いただき誠にありがとうございます。
  ご注文につきまして下記の通り承りましたのでご連絡いたします。

  ■ご注文内容
  --------------------------------------------------------------------------------------------------------
  注文日：${new Date().getFullYear()}年${
    new Date().getMonth() + 1
  }月${new Date().getDate()}日
  注文ID：${order?._id}
  --------------------------------------------------------------------------------------------------------

  【お名前】${userInfo.lastname} ${userInfo.firstname}（${
    userInfo.lastnameYomi
  } ${userInfo.firstnameYomi}）
  【メールアドレス】${userInfo.email}

  【お支払い方法】${order.paymentMethod}
  ${
    order.paymentMethod === '銀行振込'
      ? '［三菱UFJ銀行 日本橋支店 普通）5138870 マツオタカヒロ］ ※お手数ですが記載の口座に一週間以内にお振り込みいただけますようお願いいたします。'
      : ''
  }
  【商品価格】${order.itemsPrice.toLocaleString()}円
  【配送料】${order.shippingPrice.toLocaleString()}円
  【合計価格】${order.totalPrice.toLocaleString()}円

  【電話番号】${order.shippingAddress.phoneNum}
  【配送先住所】〒${order.shippingAddress.postalCode} ${
    order.shippingAddress.prefecture
  }${order.shippingAddress.city}${order.shippingAddress.address} ${
    order.shippingAddress.building
  }
  【コメント】${order.comment}

  ■ご注文商品
  --------------------------------------------------------------------------------------------------------
  ${order.orderItems.map((item, index) => {
    return item.category === 'Tailor T-shirts' && item.isNew
      ? `
    カテゴリー：${item.category}
    商品名：${item.name}
    価格：${item.price.toLocaleString()}円
    カラー：${item.color}
    ネック：${item.neck}
    ネックリブ：${item.neckLib}cm
    -----------------------------------------------------
    身長：${item.bodySize.height}cm
    胸囲：${item.bodySize.chest}cm
    ウエスト：${item.bodySize.waist}cm
    腕回り：${item.bodySize.arm}cm
    肩幅：${item.shoulderSize.shoulderLength}
    肩傾斜：${item.shoulderSize.shoulderLine}
    -----------------------------------------------------
    着丈：${item.tshirtSize.length === '0.0' ? '' : item.tshirtSize.length}${
          item.tshirtSize.length === '0.0' ? '' : 'cm'
        }
    着幅：${
      item.tshirtSize.chestWidth === '0.0' ? '' : item.tshirtSize.chestWidth
    }${item.tshirtSize.chestWidth === '0.0' ? '' : 'cm'}
    ウエスト：${
      item.tshirtSize.waistWidth === '0.0' ? '' : item.tshirtSize.waistWidth
    }${item.tshirtSize.waistWidth === '0.0' ? '' : 'cm'}
    裾幅：${
      item.tshirtSize.hemWidth === '0.0' ? '' : item.tshirtSize.hemWidth
    }${item.tshirtSize.hemWidth === '0.0' ? '' : 'cm'}
    肩幅：${
      item.tshirtSize.shoulderWidth === '0.0'
        ? ''
        : item.tshirtSize.shoulderWidth
    }${item.tshirtSize.shoulderWidth === '0.0' ? '' : 'cm'}
    脇高：${
      item.tshirtSize.armpitHeight === '0.0' ? '' : item.tshirtSize.armpitHeight
    }${item.tshirtSize.armpitHeight === '0.0' ? '' : 'cm'}
    袖丈：${
      item.type === 'T-shirts'
        ? item.tshirtSize.sleeveLength === '0.0'
          ? ''
          : item.tshirtSize.sleeveLength
        : item.tshirtSize.longSleeveLength === '0.0'
        ? ''
        : item.tshirtSize.longSleeveLength
    }${
          item.tshirtSize.sleeveLength === '0.0' ||
          item.tshirtSize.longSleeveLength === '0.0'
            ? ''
            : 'cm'
        }
    袖幅：${
      item.type === 'T-shirts'
        ? item.tshirtSize.armWidth === '0.0'
          ? ''
          : item.tshirtSize.armWidth
        : item.tshirtSize.wristWidth === '0.0'
        ? ''
        : item.tshirtSize.wristWidth
    }${
          item.tshirtSize.armWidth === '0.0' ||
          item.tshirtSize.wristWidth === '0.0'
            ? ''
            : 'cm'
        }
    -----------------------------------------------------
    目的：${item.questions.isYourPurpose}
    好みの生地：${item.questions.favoriteTshirt}
    理想のサイズ：${item.questions.favoriteSize}
    コメント：${item.questions.comment}
      `
      : item.category === 'Tailor T-shirts' && !item.isNew
      ? `
    【商品 - ${index + 1}】
    カテゴリー：${item.category}
    商品名：${item.name}
    価格：${item.price.toLocaleString()}円 x ${item.qty}枚 = ${(
          item.price * item.qty
        ).toLocaleString()}円
    サイズ：${item.size}
    カラー：${item.color}
    タグ：${item.tag}
    リブ：${item.lib}
    ポケット：${
      item.pocket.pocketType !== '付けない'
        ? `${item.pocket.pocketType} / ${item.pocket.pocketPosition}`
        : '付けない'
    }
    名入れ：${
      item.embroidery.isEmbroidery !== '無し'
        ? `${item.embroidery.embroideryChar} / ${item.embroidery.embroideryColor}`
        : `無し`
    }
    サイズ変更：${item.fixSize !== '' ? item.fixSize : 'なし'}
      `
      : `
    【商品 - ${index + 1}】
    カテゴリー：${item.category}
    商品名：${item.name}
    価格：${item.price.toLocaleString()}円 x ${item.qty}枚 = ${(
          item.price * item.qty
        ).toLocaleString()}円
    サイズ：${item.size}
    スタイル：${item.style}
    カラー：${item.color}
    ネック：${item.neck}
    着丈：${item.length}
    ${item.type === 'Long T-shirts' ? `袖丈：${item.sleeveLength}` : ''}
    リブ：${item.lib}
    ポケット：${item.pocket.pocketType}
    ${
      item.pocket.pocketType !== '付けない'
        ? `ポケット位置：${item.pocket.pocketPosition}`
        : ''
    }
    `
  })}
  --------------------------------------------------------------------------------------------------------

  ご注文内容の確認後、担当者より改めてご連絡させていただきます。
  48時間以内に返信がない場合は、お手数ですが<info@mnsfabric.com>宛までご連絡ください。
  引き続きよろしくお願いいたします。
  `
}

export { orderMail }
