import { rootApi } from './rootApi'
import { USERS_URL } from '../../constants'

export const usersApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/auth`,
        method: 'POST',
        body: data,
      }),
      keepUnusedDataFor: 5,
    }),
    register: builder.mutation({
      query: (data) => ({
        url: USERS_URL,
        method: 'POST',
        body: data,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: `${USERS_URL}/logout`,
        method: 'POST',
      }),
    }),
    profile: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/profile`,
        method: 'PUT',
        body: data,
      }),
    }),
    getUsers: builder.query({
      query: ({ keyword, pageNumber }) => ({
        url: USERS_URL,
        params: {
          keyword,
          pageNumber,
        },
      }),
      providesTags: ['Users'],
      keepUnusedDataFor: 5,
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `${USERS_URL}/${userId}`,
        method: 'DELETE',
      }),
    }),
    getUserDetails: builder.query({
      query: (userId) => ({
        url: `${USERS_URL}/${userId}`,
      }),
      providesTags: ['Users'],
      keepUnusedDataFor: 5,
    }),
    updateUser: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/${data.userId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Users'],
    }),
    createTailorSize: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/${data.userId}/tailor-size`,
        method: 'POST',
        body: data,
      }),
    }),
    getTailorSize: builder.query({
      query: () => ({
        url: `${USERS_URL}/tailor-size`,
      }),
      keepUnusedDataFor: 5,
    }),
    getTailorSizeById: builder.query({
      query: (userId) => ({
        url: `${USERS_URL}/${userId}/tailor-size`,
      }),
      keepUnusedDataFor: 5,
    }),
    updateTailorSize: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/${data.user}/tailor-size`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Users'],
      keepUnusedDataFor: 5,
    }),
    deleteTailorSize: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/${data.user}/tailor-size`,
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: ['Users'],
      keepUnusedDataFor: 5,
    }),
  }),
})

export const {
  useLoginMutation,
  useRegisterMutation,
  useLogoutMutation,
  useProfileMutation,
  useGetUsersQuery,
  useDeleteUserMutation,
  useGetUserDetailsQuery,
  useUpdateUserMutation,
  useCreateTailorSizeMutation,
  useGetTailorSizeQuery,
  useGetTailorSizeByIdQuery,
  useUpdateTailorSizeMutation,
  useDeleteTailorSizeMutation,
} = usersApi
