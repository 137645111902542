import React from 'react'

const TailorNeckSizeV = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="\u30EC\u30A4\u30E4\u30FC_1"
      x={0}
      y={0}
      style={{
        enableBackground: 'new 0 0 450 180',
      }}
      viewBox="0 0 450 180"
    >
      <style>
        {
          '.st00{stroke:#000;stroke-linejoin:round}.st00,.st11,.st22{fill:none;stroke-miterlimit:10}.st11{stroke:#000;stroke-linecap:round;stroke-linejoin:round}.st22{stroke:#aaa}.st33{fill:#aaa}.st44{fill:none;stroke:#aaa;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.st55{font-family:"NotoSansCJKjp-Regular-83pv-RKSJ-H"}.st66{font-size:13px}.st77{font-size:10px}.st88{fill:none;stroke:#000;stroke-width:.3607;stroke-miterlimit:10}'
        }
      </style>
      <path
        d="M281 44.8S268.4 56 226.4 56h.6c-41.9 0-54.6-11.2-54.6-11.2"
        className="st00"
      />
      <path
        d="M429.5 123c-21.8-32-44.6-42.3-44.6-42.3L281.1 38.9h-.1s-12.6 11.2-54.6 11.2h.6c-41.9 0-54.6-11.2-54.6-11.2L68.6 80.7S45.8 91 24 123"
        className="st00"
      />
      <path
        d="M290.6 42.9c2.7 7.2 3.1 13 3.1 17.3 0 17.5-16.1 39.6-29.7 55.2-6.8 7.8-14.2 15.4-21 21.4-10.7 9.5-13.9 10.3-16.6 10.3s-5.9-.8-16.6-10.3c-6.8-6.1-14.2-13.7-21-21.4-13.5-15.5-29.7-37.7-29.7-55.2 0-4.4.4-10.2 3.2-17.5"
        className="st00"
      />
      <path
        d="M281.1 39.2c4.5 8.6 5.4 15.1 5.4 21.1 0 28.2-54.4 79.7-60.1 79.7s-60.1-51.5-60.1-79.7c0-5.9 1-12.4 5.4-21.1"
        className="st00"
      />
      <path
        d="M73.5 162.5c-4.9-33.6-12.2-62-12.2-62M392.2 100.5s-7.3 28.4-12.2 62"
        className="st11"
      />
      <path d="M226.5 50.1V97M226.5 118.5v21.4" className="st22" />
      <circle cx={226.5} cy={139.9} r={2.5} className="st33" />
      <path d="M166.5 63.3h11.4M219.1 63.3h68.2" className="st22" />
      <circle cx={166.5} cy={63.5} r={2.5} className="st33" />
      <circle cx={286} cy={63.4} r={2.5} className="st33" />
      <circle cx={226.5} cy={49.7} r={2.5} className="st33" />
      <path d="M72.2 38.9h77.2L76.1 68.7" className="st44" />
      <path d="M127.8 38.9c0 2.9.6 5.6 1.6 8.1" className="st44" />
      <text className="st55 st66" transform="translate(81.215 54.553)">
        {size.shoulderLine}
      </text>
      <text transform="translate(64.571 53.29)">
        <tspan x={0} y={0} className="st55 st77">
          {'1'}
        </tspan>
        <tspan x={4.8} y={0} className="st55 st77">
          {'1'}
        </tspan>
      </text>
      <path d="M63.7 43.7h12v12h-12z" className="st88" />
      <text className="st55 st66" transform="translate(219.56 111.345)">
        {size.neckHeight}
      </text>
      <text transform="translate(202.98 110.083)">
        <tspan x={0} y={0} className="st55 st77">
          {'1'}
        </tspan>
        <tspan x={4.8} y={0} className="st55 st77">
          {'0'}
        </tspan>
      </text>
      <path d="M202.2 100.5h12v12h-12z" className="st88" />
      <text className="st55 st66" transform="translate(199.812 68.147)">
        {size.neckWidth}
      </text>
      <text className="st55 st77" transform="translate(185.699 66.885)">
        {'9'}
      </text>
      <path d="M182.5 57.3h12v12h-12z" className="st88" />
    </svg>
  )
}

export default TailorNeckSizeV
