import { Link } from 'react-router-dom'
import Meta from '../components/Meta'

const ResizeScreen = () => {
  return (
    <>
      <Meta title="Tシャツサイズ無料調整サービスについて | m&s Fabric" />
      <div className="flex justify-center grow">
        <div className="screen-container mt-5 mb-14 w-full">
          <div className="text-sm breadcrumbs text-gray-500 mb-10">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>無料調整サービスについて</li>
            </ul>
          </div>
          <div className="container">
            <h2 className="page-title">About Resize service</h2>
            <p className="page-description">無料調整サービスについて</p>
            <div className="mb-2 lg:mb-10">
              <h3 className="text-2xl/10 mb-10">
                体型や好みが変わっても購入毎に修正できる。いつでもベストのTシャツをお届けします
              </h3>
              <img className="mb-7" src="/images/resize_img.jpg" alt="" />
              <div className="flex flex-col md:gap-10 w-full md:flex-row">
                <div className="w-full md:w-1/2">
                  <p className="text-sm/6 mb-3">
                    せっかくオーダーメイドでTシャツを作ったのに体型が変わってしまった。Tシャツを作った時はタイトが好みだったけど、今は少しルーズに着たい。着用している中でやっぱり着丈がもう少し長い方が良かったな...
                    など、時間が経てば体型や好みが変わる事もあります。
                  </p>
                  <p className="text-sm/6 mb-3">
                    そのようなお客様の悩みを解決するために当店のオーダーTシャツでは購入毎にTシャツのサイズを無料で修正できる無料調整サービスをご用意しております。
                  </p>
                </div>
                <div className="w-full md:w-1/2">
                  <p className="text-sm/6 mb-3">
                    テイラーTシャツをご購入のお客様は、お客様の型紙を保管しておりますので追加注文時に変更したい箇所をご指示いただければ、追加料金なしで修正いたします。
                  </p>
                  <p className="text-sm/6 mb-3">
                    また、カスタマイズTシャツは着丈のみの修正に限りますが、注文時にご指定いただければこちらも追加料金なしで対応させていただきます。
                  </p>
                  <p className="text-sm/6 mb-3">
                    お客様にとっていつでもその時のベストなTシャツをお届けいたします。
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-center lg:mb-20">
              <div className="w-full md:hidden">
                <img src="/images/resize-sm.svg" alt="リサイズサービス図" />
              </div>
              <div className="hidden md:block w-[800px]">
                <img src="/images/resize.svg" alt="リサイズサービス図" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResizeScreen
