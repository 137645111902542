import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  useGetOrderDetailsQuery,
  usePayOrderMutation,
  useDeliverOrderMutation,
} from '../store/apis/ordersApi'
import { toast } from 'react-toastify'

const OrderScreen = () => {
  const { id: orderId } = useParams()
  const { userInfo } = useSelector((state) => state.auth)
  const {
    data: order,
    refetch,
    isLoading,
    error,
  } = useGetOrderDetailsQuery(orderId)

  const [payOrder, { isLoading: loadingPay }] = usePayOrderMutation()

  const [deliverOrder, { isLoading: loadingDeliver }] =
    useDeliverOrderMutation()

  const payOrderHandler = async () => {
    try {
      await payOrder(orderId)
      refetch()
      toast.success('支払いを確認しました')
    } catch (error) {
      toast.error(error.messsage)
    }
  }

  const deliverOrderHandler = async () => {
    try {
      await deliverOrder(orderId)
      refetch()
      toast.success('商品を出荷しました')
    } catch (error) {
      toast.error(error.messsage)
    }
  }

  console.log(order)

  return isLoading ? (
    <Loader />
  ) : error ? (
    <Message variant="alert-error" />
  ) : (
    <div className="flex justify-center">
      <div className="screen-container mt-5 w-full">
        <div className="text-sm breadcrumbs text-gray-500 mb-10">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/profile">My Page</Link>
            </li>
            <li>注文詳細</li>
          </ul>
        </div>
        <h2 className="page-title">
          Order{' '}
          <span className="font-light not-italic text-base ml-5">
            【No. {order._id}】
          </span>
        </h2>
        <div className="flex flex-col items-start gap-10 mb-20 lg:flex-row">
          <div className="basis-3/5">
            <div className="overflow-x-auto mb-12">
              <table className="table">
                <tbody>
                  <tr>
                    <th className="w-32">
                      <h2 className="font-bold">ご注文日</h2>
                    </th>
                    <td>
                      {order.createdAt.substring(0, 4)}年
                      {order.createdAt.substring(5, 7)}月
                      {order.createdAt.substring(8, 10)}日
                    </td>
                  </tr>
                  <tr>
                    <th className="w-32">
                      <h2 className="font-bold">ご注文者</h2>
                    </th>
                    <td>
                      <ul className="text-sm/2">
                        <li>
                          {order.user.lastname} {order.user.firstname} 様
                        </li>
                        <li>{order.user.email}</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <h2 className="font-bold">配送先</h2>
                    </th>
                    <td>
                      <ul className="text-sm/2">
                        <li>〒{order.shippingAddress.postalCode}</li>
                        <li>
                          {order.shippingAddress.prefecture}
                          {order.shippingAddress.city}
                          {order.shippingAddress.address}
                          {order.shippingAddress.building}
                        </li>
                        <li>{order.shippingAddress.phoneNum}</li>
                      </ul>
                    </td>
                    <td className="hidden lg:table-cell">
                      {order.isDelivered ? (
                        <Message variant="alert-success">出荷済み</Message>
                      ) : (
                        <Message variant="alert-warning">出荷準備中</Message>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <h2 className="font-bold">お支払い方法</h2>
                    </th>
                    <td>
                      <ul className="text-sm/2">
                        <li>{order.paymentMethod}</li>
                      </ul>
                    </td>
                    <td className="hidden lg:table-cell">
                      {order.isPaid ? (
                        <Message variant="alert-success">お支払い済み</Message>
                      ) : (
                        <Message variant="alert-warning">支払確認中</Message>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="mt-5 flex gap-3 justify-between lg:hidden">
                <div className="w-1/2">
                  {order.isPaid ? (
                    <Message variant="alert-success">お支払い済み</Message>
                  ) : (
                    <Message variant="alert-warning">支払確認中</Message>
                  )}
                </div>
                <div className="w-1/2">
                  {order.isDelivered ? (
                    <Message variant="alert-success">出荷済み</Message>
                  ) : (
                    <Message variant="alert-warning">出荷準備中</Message>
                  )}
                </div>
              </div>
            </div>

            <div className="text-sm">
              {order.orderItems.map((item) => {
                if (item.category === 'Tailor T-shirts' && item.isNew) {
                  return (
                    <div
                      className="flex flex-col w-full border-b border-gray-300 pb-5 mb-5"
                      key={item.cartId}
                    >
                      <div className="flex items-start">
                        <img
                          src={item.image}
                          alt={item.name}
                          className="w-20 mr-8"
                        />
                        <div className="grow">
                          <p className="text-xs text-white bg-black rounded-full inline-block px-2">
                            {item.category}
                          </p>
                          <h3 className="text-lg font-bold italic">
                            {item.name}
                          </h3>
                          <p className="mb-2">
                            <span className="text-xs">価格：</span>
                            {item.price.toLocaleString()}
                            <span className="text-xs">円（税込）</span>
                          </p>
                          <ul className="text-sm">
                            <li className="inline-block mr-2">
                              枚数：{item.qty}、
                            </li>
                            <li className="inline-block mr-2">
                              カラー：{item.color}、
                            </li>
                            <li className="inline-block mr-2">
                              サイズ：オーダーメイド、
                            </li>
                            <li className="inline-block mr-2">
                              ネック：{item.neck}、
                            </li>
                            <li className="inline-block mr-2">
                              ネックリブ{item.neckLib}cm
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                } else if (item.category === 'Tailor T-shirts' && !item.isNew) {
                  return (
                    <div
                      className="flex flex-col w-full border-b border-gray-300 pb-5 mb-5"
                      key={item.cartId}
                    >
                      <div className="flex items-start">
                        <img
                          src={item.image}
                          alt={item.name}
                          className="w-20 mr-8"
                        />
                        <div className="grow">
                          <p className="text-xs text-white bg-black rounded-full inline-block px-2">
                            {item.category} / Repeat
                          </p>
                          <h3 className="text-lg font-bold italic">
                            {item.name}
                          </h3>
                          <p className="mb-2">
                            <span className="text-xs">価格：</span>
                            {item.price.toLocaleString()}
                            <span className="text-xs">円（税込）</span>
                          </p>
                          <ul className="text-sm">
                            <li className="inline-block mr-2">
                              枚数：{item.qty}、
                            </li>
                            <li className="inline-block mr-2">
                              カラー：{item.color}、
                            </li>
                            <li className="inline-block mr-2">
                              サイズ：{item.size}、
                            </li>
                            <li className="inline-block mr-2">
                              タグ：{item.tag}、
                            </li>
                            <li className="inline-block mr-2">
                              リブ：{item.lib}、
                            </li>
                            <li className="inline-block mr-2">
                              ポケット：
                              {item.pocket.pocketType !== '付けない' &&
                                item.pocket.pocketPosition}
                              {item.pocket.pocketType !== '付けない' && (
                                <span> / </span>
                              )}
                              {item.pocket.pocketType}、
                            </li>
                            {item.isEmbroidery && (
                              <li className="inline-block mr-2">
                                名入れ：
                                {item.embroidery.isEmbroidery !== '無し' &&
                                  item.embroidery.embroideryChar}
                                {item.embroidery.isEmbroidery !== '無し' && (
                                  <span> / </span>
                                )}
                                {item.embroidery.isEmbroidery !== '無し' &&
                                  item.embroidery.embroideryColor}
                                {item.embroidery.isEmbroidery === '無し' &&
                                  item.embroidery.isEmbroidery}
                                、
                              </li>
                            )}

                            <li className="inline-block mr-2">
                              サイズ変更：
                              {item.fixSize === '' ? 'なし' : 'あり'}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                } else if (item.category === 'Customize T-shirts') {
                  return (
                    <div
                      className="flex flex-col w-full border-b border-gray-300 pb-5 mb-5"
                      key={item.cartId}
                    >
                      <div className="flex items-start">
                        <img
                          src={item.image}
                          alt={item.name}
                          className="w-20 mr-8"
                        />
                        <div className="grow">
                          <p className="text-xs border border-gray-400 rounded-full inline-block px-2 bg-white">
                            {item.category}
                          </p>
                          <h3 className="text-lg font-bold italic">
                            {item.name}
                          </h3>
                          <p className="mb-2">
                            <span className="text-xs">価格：</span>
                            {item.price.toLocaleString()}
                            <span className="text-xs">円（税込）</span>
                          </p>
                          <ul className="text-sm">
                            <li className="inline-block mr-2">
                              枚数：{item.qty}、
                            </li>
                            <li className="inline-block mr-2">
                              カラー：{item.color}、
                            </li>
                            <li className="inline-block mr-2">
                              サイズ：{item.size}、
                            </li>
                            <li className="inline-block mr-2">
                              ネック：{item.neck}、
                            </li>
                            <li className="inline-block mr-2">
                              スタイル：{item.style}、
                            </li>
                            <li className="inline-block mr-2">
                              着丈：{item.length}、
                            </li>
                            {item.sleeveLength && (
                              <li className="inline-block mr-2">
                                袖丈：{item.sleeveLength}、
                              </li>
                            )}
                            <li className="text-[13px] inline-block mr-2">
                              リブ：{item.lib}、
                            </li>
                            <li className="text-[13px] inline-block mr-2">
                              ポケット：
                              {item.pocket.pocketType !== '付けない' &&
                                item.pocket.pocketPosition}
                              {item.pocket.pocketType !== '付けない' && (
                                <span> / </span>
                              )}
                              {item.pocket.pocketType}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                } else {
                  return <Message>該当しない商品です</Message>
                }
              })}
            </div>
          </div>

          <div className="w-full lg:basis-2/5 bg-gray-100 p-5">
            <div className="text-right">
              <ul className="text-sm">
                <li>
                  合計枚数：
                  <span className="text-xl mr-1">
                    {order.orderItems.reduce(
                      (acc, item) => acc + Number(item.qty),
                      0
                    )}
                  </span>
                  枚
                </li>
                <li>
                  小計：
                  <span className="text-xl mr-1">
                    {order.orderItems
                      .reduce(
                        (acc, item) => acc + Number(item.qty) * item.price,
                        0
                      )
                      .toLocaleString()}
                  </span>
                  円
                </li>
                <li>
                  配送料：
                  <span className="text-xl mr-1">
                    {order.shippingPrice.toLocaleString()}
                  </span>
                  円
                </li>
                <li className="mt-3">
                  合計（税込）：
                  <span className="text-3xl font-semibold mr-1">
                    {order.totalPrice.toLocaleString()}
                  </span>
                  円
                </li>
                {loadingPay && <Loader />}
                {loadingDeliver && <Loader />}
                {userInfo && userInfo.isAdmin && !order.isPaid && (
                  <li className="mt-5">
                    <button
                      onClick={payOrderHandler}
                      className="btn btn-primary w-full"
                    >
                      支払い済みにする
                    </button>
                  </li>
                )}
                {userInfo &&
                  userInfo.isAdmin &&
                  order.isPaid &&
                  !order.isDelivered && (
                    <li className="mt-5">
                      <button
                        onClick={deliverOrderHandler}
                        className="btn btn-primary w-full"
                      >
                        配送済みにする
                      </button>
                    </li>
                  )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderScreen
