import { useState } from 'react'
import { Link } from 'react-router-dom'
import CustomizeListScreen from './CustomizeListScreen'

const ProductListScreen = () => {
  const [tabState, setTabState] = useState('tab1')

  return (
    <div className="flex justify-center">
      <div className="screen-container mt-5 w-full">
        <div className="text-sm breadcrumbs text-gray-500 mb-10">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Products list</li>
          </ul>
        </div>

        <div className="container">
          <div
            role="tablist"
            className="tabs tabs-boxed mb-14 overflow-x-scroll"
          >
            <Link
              role="tab"
              className={`tab ${
                tabState === 'tab1' ? 'tab-active' : ''
              } text-xs md:text-sm whitespace-nowrap`}
              onClick={() => setTabState('tab1')}
            >
              カスタマイズTシャツ
            </Link>
            <Link
              role="tab"
              className={`tab ${
                tabState === 'tab2' ? 'tab-active' : ''
              } text-xs md:text-sm whitespace-nowrap`}
              onClick={() => setTabState('tab2')}
            >
              テイラーTシャツ
            </Link>
          </div>
          <div>{tabState === 'tab1' ? <CustomizeListScreen /> : 'tailor'}</div>
        </div>
      </div>
    </div>
  )
}

export default ProductListScreen
