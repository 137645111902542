import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { changeCustomSlideState } from '../../store/slices/customSlideSlice'

const CustomNeck = ({ changeNeck, selectedValue }) => {
  const [sizeSpecTshirts, setSizeSpecTshirts] = useState()
  const [selectedNeckType, setSelectedNeckType] = useState(
    selectedValue.selectedNeck
  )
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchSize = async () => {
      const { data } = await axios.get('/api/tshirts-size')
      setSizeSpecTshirts(data)
    }
    fetchSize()
  }, [])

  const filteredNeck =
    sizeSpecTshirts &&
    sizeSpecTshirts
      .find((size) => size.name === selectedValue.selectedSize)
      .neck.find((neck) => neck.name === selectedNeckType)

  const changeNeckHandler = () => {
    changeNeck(selectedNeckType)
    dispatch(changeCustomSlideState())
  }

  return (
    <div className="w-full h-full pb-28 lg:pb-10 overflow-y-auto flex flex-col items-center">
      <div className="md:w-4/6 lg:w-full grid grid-cols-2 gap-5 mb-10 lg:grid-cols-3">
        <div className="text-center mb-5">
          <img src="/images/custom_items/neck_o-small.png" alt="o-neck" />
          <input
            type="radio"
            name="neck"
            id="o-small"
            value="O-Small"
            onChange={(e) => setSelectedNeckType(e.target.value)}
            checked={selectedNeckType === 'O-Small'}
            className="hidden"
          />
          <label
            className={`btn btn-sm btn-outline rounded-full w-32 border-gray-400 ${
              selectedNeckType === 'O-Small' && 'bg-black text-white'
            }`}
            htmlFor="o-small"
          >
            O-Small
          </label>
        </div>
        <div className="text-center mb-5">
          <img src="/images/custom_items/neck_o-medium.png" alt="o-neck" />
          <input
            type="radio"
            name="neck"
            id="o-medium"
            value="O-Medium"
            onChange={(e) => setSelectedNeckType(e.target.value)}
            checked={selectedNeckType === 'O-Medium'}
            className="hidden"
          />
          <label
            className={`btn btn-sm btn-outline rounded-full w-32 border-gray-400 ${
              selectedNeckType === 'O-Medium' && 'bg-black text-white'
            }`}
            htmlFor="o-medium"
          >
            O-Medium
          </label>
        </div>
        <div className="text-center mb-">
          <img src="/images/custom_items/neck_o-large.png" alt="o-wide" />
          <input
            type="radio"
            name="neck"
            id="o-large"
            value="O-Large"
            onChange={(e) => setSelectedNeckType(e.target.value)}
            checked={selectedNeckType === 'O-Large'}
            className="hidden"
          />
          <label
            className={`btn btn-sm btn-outline rounded-full w-32 border-gray-400 ${
              selectedNeckType === 'O-Large' && 'bg-black text-white'
            }`}
            htmlFor="o-large"
          >
            O-Large
          </label>
        </div>

        <div className="text-center">
          <img src="/images/custom_items/neck_v-small.png" alt="v-neck" />
          <input
            type="radio"
            name="neck"
            id="v-small"
            value="V-Small"
            onChange={(e) => setSelectedNeckType(e.target.value)}
            checked={selectedNeckType === 'V-Small'}
            className="hidden"
          />
          <label
            className={`btn btn-sm btn-outline rounded-full w-32 border-gray-400 ${
              selectedNeckType === 'V-Small' && 'bg-black text-white'
            }`}
            htmlFor="v-small"
          >
            V-Small
          </label>
        </div>
        <div className="text-center">
          <img src="/images/custom_items/neck_v-medium.png" alt="v-neck" />
          <input
            type="radio"
            name="neck"
            id="v-medium"
            value="V-Medium"
            onChange={(e) => setSelectedNeckType(e.target.value)}
            checked={selectedNeckType === 'V-Medium'}
            className="hidden"
          />
          <label
            className={`btn btn-sm btn-outline rounded-full w-32 border-gray-400 ${
              selectedNeckType === 'V-Medium' && 'bg-black text-white'
            }`}
            htmlFor="v-medium"
          >
            V-Medium
          </label>
        </div>
        <div className="text-center">
          <img src="/images/custom_items/neck_v-large.png" alt="v-neck" />
          <input
            type="radio"
            name="neck"
            id="v-large"
            value="V-Large"
            onChange={(e) => setSelectedNeckType(e.target.value)}
            checked={selectedNeckType === 'V-Large'}
            className="hidden"
          />
          <label
            className={`btn btn-sm btn-outline rounded-full w-32 border-gray-400 ${
              selectedNeckType === 'V-Large' && 'bg-black text-white'
            }`}
            htmlFor="v-large"
          >
            V-Large
          </label>
        </div>
      </div>
      <div className="w-full  mb-10">
        <table className="table text-center">
          {/* head */}
          <thead>
            <tr>
              <th className="font-normal text-black">サイズ</th>
              <th className="font-normal text-black">タイプ</th>
              <th className="font-normal text-black">ネックの横幅</th>
              <th className="font-normal text-black">ネックの深さ</th>
            </tr>
          </thead>
          <tbody>
            <>
              <tr>
                <th rowSpan="2">{selectedValue.selectedSize}</th>
                <td>{filteredNeck && filteredNeck.name}</td>
                <td className="text-base">
                  {filteredNeck && filteredNeck.size.width}{' '}
                  <span className="text-xs">cm</span>
                </td>
                <td className="text-base">
                  {filteredNeck && filteredNeck.size.height}{' '}
                  <span className="text-xs">cm</span>
                </td>
              </tr>
            </>
          </tbody>
        </table>
        <p className="text-xs text-right mt-3">
          ※数値は生地の状態や計測方法によって異なる事があります。
        </p>
      </div>
      <div className="w-full text-center">
        <button
          className="btn btn-primary btn-sm w-72"
          onClick={changeNeckHandler}
        >
          決定する
        </button>
      </div>
    </div>
  )
}

export default CustomNeck
