import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useProfileMutation } from '../../store/apis/usersApi'
import { setCredentials } from '../../store/slices/authSlice'

const CustomerInfo = () => {
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [firstnameYomi, setFirstnameYomi] = useState('')
  const [lastnameYomi, setLastnameYomi] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const dispatch = useDispatch()

  const { userInfo } = useSelector((state) => state.auth)

  const [updateProfile, { isLoading: loadingUpdateProfile }] =
    useProfileMutation()

  useEffect(() => {
    if (userInfo) {
      setFirstname(userInfo.firstname)
      setLastname(userInfo.lastname)
      setFirstnameYomi(userInfo.firstnameYomi)
      setLastnameYomi(userInfo.lastnameYomi)
      setEmail(userInfo.email)
    }
  }, [userInfo])

  const submitHandler = async (e) => {
    e.preventDefault()

    if (password !== confirmPassword) {
      toast.error('パスワードが一致していません')
    } else {
      try {
        const res = await updateProfile({
          _id: userInfo._id,
          firstname,
          lastname,
          firstnameYomi,
          lastnameYomi,
          email,
          password,
        }).unwrap()
        dispatch(setCredentials(res))
        toast.success('お客様情報が更新されました')
      } catch (error) {
        toast.error(error.data.message)
      }
    }
  }

  return (
    <div className="flex w-full justify-center mb-20">
      <form onSubmit={submitHandler}>
        <div className="form-control w-full mb-5">
          <label className="label">
            <span className="label-text text-xs">お名前</span>
          </label>
          <div className="flex gap-3">
            <input
              type="text"
              placeholder="姓"
              className="input input-bordered w-full text-sm"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              pattern="[\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFF9F\u4E00-\u9FFF]*"
              title="日本語で入力してください"
            />
            <input
              type="text"
              placeholder="名"
              className="input input-bordered w-full text-sm"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              pattern="[\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFF9F\u4E00-\u9FFF]*"
              title="日本語で入力してください"
            />
          </div>
        </div>
        <div className="form-control w-full mb-5">
          <label className="label">
            <span className="label-text text-xs">お名前（フリガナ）</span>
          </label>
          <div className="flex gap-3">
            <input
              type="text"
              placeholder="セイ"
              className="input input-bordered w-full text-sm"
              value={lastnameYomi}
              onChange={(e) => setLastnameYomi(e.target.value)}
              pattern="[\u30A1-\u30F6]*"
              title="全角カタカナで入力してください"
            />
            <input
              type="text"
              placeholder="メイ"
              className="input input-bordered w-full text-sm"
              value={firstnameYomi}
              onChange={(e) => setFirstnameYomi(e.target.value)}
              pattern="[\u30A1-\u30F6]*"
              title="全角カタカナで入力してください"
            />
          </div>
        </div>
        <div className="form-control w-full mb-5">
          <label className="label">
            <span className="label-text text-xs">メールアドレス</span>
          </label>
          <input
            type="email"
            className="input input-bordered w-full text-sm"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-control w-full mb-5">
          <label className="label">
            <span className="label-text text-xs">パスワード</span>
          </label>
          <input
            type="password"
            className="input input-bordered w-full text-sm"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            minLength="6"
          />
        </div>
        <div className="form-control w-full mb-5">
          <label className="label">
            <span className="label-text text-xs">パスワード確認</span>
          </label>
          <input
            type="password"
            className="input input-bordered w-full text-sm"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            minLength="6"
          />
        </div>

        {loadingUpdateProfile ? (
          <button className="btn w-full mt-5">
            <span className="loading loading-spinner"></span>
            ローディング
          </button>
        ) : (
          <button type="submit" className="btn btn-primary w-full mt-5">
            更新する
          </button>
        )}
      </form>
    </div>
  )
}

export default CustomerInfo
