import { Link } from 'react-router-dom'

const TailorProcess = ({ step1, step2, step3, step4, step5 }) => {
  return (
    <div className="text-center">
      <ul className="steps text-xs mb-14 w-full lg:w-1/2">
        <li className={`step ${step1 && 'step-accent'}`}>
          {step2 ? <Link to="/tailor-tshirts/body">ボディ</Link> : 'ボディ'}
        </li>
        <li className={`step ${step1 && step2 && 'step-accent'}`}>
          {step2 ? <Link to="/tailor-tshirts/neck">ネック</Link> : 'ネック'}
        </li>
        <li className={`step ${step1 && step2 && step3 && 'step-accent'}`}>
          {step2 ? <Link to="/tailor-tshirts/size">サイズ</Link> : 'サイズ'}
        </li>
        <li
          className={`step ${
            step1 && step2 && step3 && step4 && 'step-accent'
          }`}
        >
          確認
        </li>
      </ul>
    </div>
  )
}

export default TailorProcess
