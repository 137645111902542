import { Link } from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Meta from '../components/Meta'

const ShoppingGuideScreen = () => {
  return (
    <>
      <Meta title="ショッピングガイド | m&s Fabric" />
      <div className="flex justify-center grow">
        <div className="screen-container mt-5 mb-14 w-full">
          <div className="text-sm breadcrumbs text-gray-500 mb-10">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>ショッピングガイド</li>
            </ul>
          </div>
          <div className="container my-14 lg:my-20 flex justify-center">
            <div className="w-full lg:w-[768px]">
              <h2 className="page-title">Shopping Guide</h2>
              <p className="page-description">ショッピングガイド</p>
              <div className="grid gap-3 grid-cols-2 mb-20">
                <AnchorLink offset="30" href="#payment" className="btn">
                  お支払いについて
                </AnchorLink>
                <AnchorLink offset="30" href="#shipping" className="btn">
                  配送について
                </AnchorLink>
                <AnchorLink offset="30" href="#return" className="btn">
                  返品・交換について
                </AnchorLink>
                <AnchorLink offset="30" href="#deliver" className="btn">
                  納期について
                </AnchorLink>
              </div>
              <div id="payment" className="prose-sm w-full">
                <h2 className="font-bold">お支払いについて</h2>
                <p>
                  お支払いはクレジットカードもしくは銀行振込をご利用ください。
                </p>
                <h4 className="font-bold">■ クレジットカード決済について</h4>
                <span>
                  下記に表示されているカード会社のクレジットカードがご利用いただけます。
                </span>
                <div className="flex flex-row">
                  <img
                    className="h-6 mr-2"
                    src="/images/creditcard/visa.svg"
                    alt="visa"
                  />
                  <img
                    className="h-6 mr-2"
                    src="/images/creditcard/mastercard.svg"
                    alt="master"
                  />
                  <img
                    className="h-6 mr-2"
                    src="/images/creditcard/jcb.svg"
                    alt="jcb"
                  />
                  <img
                    className="h-6 mr-2"
                    src="/images/creditcard/amex.svg"
                    alt="amex"
                  />
                  <img
                    className="h-6 mr-2"
                    src="/images/creditcard/diners.svg"
                    alt="diners"
                  />
                  <img
                    className="h-6 mr-2"
                    src="/images/creditcard/google.svg"
                    alt="google"
                  />
                  <img
                    className="h-6 mr-2"
                    src="/images/creditcard/apple-pay.svg"
                    alt="apple-pay"
                  />
                </div>
                <h4 className="font-bold">■ 銀行振込について</h4>
                <p>
                  ご注文後、ご請求金額及び入金先口座をご連絡させていただきますので７日以内にお振り込み下さい。7日以上経っても入金が確認できない場合は注文をキャンセルとさせていただく場合がございますので、予めご了承ください。
                </p>
                <small className="block">
                  ※金融機関への振込手数料はお客様にご負担いただいております。
                </small>
                <h2 id="return" className="font-bold">
                  商品の返品・交換について
                </h2>
                <p>
                  商品到着後、7日以内に「商品にキズや不具合がないか」「注文内容と違いがないか」を必ずご確認ください。不良品や注文した商品と違うなど当店の不手際による場合は返品・交換とも迅速に対応させていただきます。
                </p>
                <p className="border border-black bg-blue-50 py-4 px-6">
                  納品したTシャツサイズとサイズ表で表記しているサイズが違う場合は
                  <span className="font-bold px-1">±1cm</span>
                  までを誤差とさせていただいております。伸縮性のある生地のため、一度でも着用したり、商品の状態や計測の仕方によって数値は変わってしまうため、ご理解くださいますようお願いいたします。
                </p>
                <p>
                  「注文を間違えた」「必要がなくなった」など、お客様のご都合による返品・交換は不可とさせていただいております。当店の商品は全てオーダーメイドによる製作となりますので、予めご理解の上ご注文をお願いいたします。
                </p>

                <h4 className="font-bold">■ 返品・交換にかかる送料について</h4>
                <p>
                  不良品や当店の不手際による場合は、送料・手数料等全て当店負担にて対応をさせていただきます。
                </p>
                <h2 id="shipping" className="font-bold">
                  商品の配送について
                </h2>
                <p>
                  商品が出来ましたらクロネコヤマトにて配送の手配をいたします。地域により下記の配送料をご負担いただいております。
                </p>
                <div className="overflow-x-auto">
                  <table className="table text-center">
                    {/* head */}
                    <thead>
                      <tr className="text-center">
                        <th className="w-1/5">地域</th>
                        <th className="w-3/5">配送場所</th>
                        <th className="w-1/5">配送料</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>北海道</th>
                        <td className="text-left">北海道</td>
                        <td>1,430円</td>
                      </tr>
                      <tr>
                        <th>東北</th>
                        <td className="text-left">青森、秋田、岩手</td>
                        <td>880円</td>
                      </tr>
                      <tr>
                        <th>東北</th>
                        <td className="text-left">宮城、山形、福島、新潟</td>
                        <td>770円</td>
                      </tr>
                      <tr>
                        <th>北関東</th>
                        <td className="text-left">
                          茨城、栃木、群馬、山梨、新潟、長野
                        </td>
                        <td>770円</td>
                      </tr>
                      <tr>
                        <th>南関東</th>
                        <td className="text-left">東京、神奈川、千葉、埼玉</td>
                        <td>770円</td>
                      </tr>
                      <tr>
                        <th>東海</th>
                        <td className="text-left">静岡、岐阜、愛知、三重</td>
                        <td>770円</td>
                      </tr>
                      <tr>
                        <th>北陸</th>
                        <td className="text-left">富山、石川、福井</td>
                        <td>770円</td>
                      </tr>
                      <tr>
                        <th>近畿</th>
                        <td className="text-left">
                          大阪、京都、滋賀、奈良、和歌山、兵庫
                        </td>
                        <td>880円</td>
                      </tr>
                      <tr>
                        <th>中国</th>
                        <td className="text-left">
                          岡山、広島、山口、鳥取、島根
                        </td>
                        <td>1,155円</td>
                      </tr>
                      <tr>
                        <th>四国</th>
                        <td className="text-left">香川、徳島、愛媛、高知</td>
                        <td>1,155円</td>
                      </tr>
                      <tr>
                        <th>九州</th>
                        <td className="text-left">
                          福岡、佐賀、長崎、大分、熊本、宮崎、鹿児島
                        </td>
                        <td>1,430円</td>
                      </tr>
                      <tr>
                        <th>沖縄・離島</th>
                        <td className="text-left">
                          沖縄、離島（長崎・鹿児島など一部）
                        </td>
                        <td>1,914円</td>
                      </tr>
                    </tbody>
                  </table>
                  <small>
                    ※上記は全て税込価格です。離島など一部地域で配送困難な場合はご連絡させていただきます。
                  </small>
                </div>
                <h2 id="deliver" className="font-bold">
                  納期について
                </h2>
                <p>
                  当店商品は全てオーダーメイドでの製作となりますので、ご注文いただいてから土日を除く10日程のお時間をいただいております。全ての商品は通常土日を除く10日以内に発送いたしますが、多忙期や注文が集中した場合は10日以上のお時間をいただく事がございます。その際は改めて発送日時をご連絡させていただきます。
                </p>
                <h4 className="font-bold">■ 初回テイラーTシャツ</h4>
                <p>
                  ご注文後に決済または入金の確認が取れましたら土日を除く10日以内にサンプルTシャツを発送いたします。本商品の発送は、チェックフォームからご返信いただいた後のサイズ確定後土日を除く10日以内に発送いたします。
                </p>
                <h4 className="font-bold">
                  ■ カスタマイズTシャツ / テイラーTシャツ追加購入
                </h4>
                <p>
                  ご注文後に決済または入金の確認が取れましたら土日を除く10日以内に商品を発送いたします。
                </p>

                <h4 className="font-bold">■ 生地見本サンプル請求</h4>
                <p>
                  生地見本サンプルのご請求は数日内に発送いたします。ポスト投函となりますので10日以上経っても届かない場合はお手数ですがご連絡ください。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShoppingGuideScreen
