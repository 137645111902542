import { rootApi } from './rootApi'
import { FORGOTPASSWORD_URL } from '../../constants'

export const forgotPasswordApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: `${FORGOTPASSWORD_URL}/forgot-password`,
        method: 'POST',
        body: email,
      }),
    }),
    verifyToken: builder.query({
      query: () => ({
        url: `${FORGOTPASSWORD_URL}/verify-token`,
      }),
    }),
    resetPassword: builder.mutation({
      query: (password) => ({
        url: `${FORGOTPASSWORD_URL}/reset-password`,
        method: 'PUT',
        body: password,
      }),
    }),
  }),
})

export const {
  useForgotPasswordMutation,
  useVerifyTokenQuery,
  useResetPasswordMutation,
} = forgotPasswordApi
