import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { savePaymentMethod, saveComment } from '../store/slices/cartSlice'
import Message from '../components/Message'
import Meta from '../components/Meta'

const PaymentScreen = () => {
  const [paymentMethod, setPaymentMethod] = useState('クレジットカード')
  const [comment, setComment] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart
  const { cartItems } = cart
  const { userInfo } = useSelector((state) => state.auth)

  useEffect(() => {
    if (!shippingAddress) {
      navigate('/shipping')
    }
  }, [shippingAddress, navigate])

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(savePaymentMethod(paymentMethod))
    dispatch(saveComment(comment))
    navigate('/placeorder')
  }

  return (
    <>
      <Meta title="お支払い方法 | m&s Fabric" />
      <div className="flex justify-center">
        <div className="screen-container mt-5 w-full">
          <div className="text-sm breadcrumbs text-gray-500 mb-10">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/cart">Shopping Cart</Link>
              </li>
              <li>
                <Link to="/shipping">Shipping Address</Link>
              </li>
              <li>Payment</li>
            </ul>
          </div>
          <h2 className="page-title">Payment</h2>
          <p className="page-description">お支払い方法</p>
          <div className="flex flex-col items-start gap-10 mb-20 lg:flex-row">
            <div className="w-full lg:basis-3/5">
              <div className="overflow-x-auto mb-12">
                <table className="table">
                  <tbody>
                    {/* row 1 */}
                    <tr>
                      <th className="w-32">
                        <h2 className="font-bold">ご注文者</h2>
                      </th>
                      <td>
                        <ul className="text-sm/2">
                          <li>
                            {userInfo.lastname} {userInfo.firstname}（
                            {userInfo.lastnameYomi} {userInfo.firstnameYomi}）
                            様
                          </li>
                          <li>{userInfo.email}</li>
                        </ul>
                      </td>
                    </tr>
                    {/* row 2 */}
                    <tr>
                      <th>
                        <h2 className="font-bold">配送先</h2>
                      </th>
                      <td>
                        <ul className="text-sm/2">
                          <li>{cart.shippingAddress.postalCode}</li>
                          <li>
                            {cart.shippingAddress.prefecture}
                            {cart.shippingAddress.city}
                            {cart.shippingAddress.address}
                            {cart.shippingAddress.building}
                          </li>
                          <li>{cart.shippingAddress.phoneNum}</li>
                        </ul>
                      </td>
                    </tr>
                    {/* row 3 */}
                    <tr>
                      <th>
                        <h2 className="font-bold">コメント</h2>
                      </th>
                      <td>
                        <textarea
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                          className="textarea textarea-bordered w-full"
                        ></textarea>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {cartItems.length === 0 ? (
                <Message>カートの中身はありません</Message>
              ) : (
                <div className="text-sm">
                  {cartItems.map((item) => {
                    if (item.category === 'Tailor T-shirts' && item.isNew) {
                      return (
                        <div
                          className="flex flex-col w-full border-b border-gray-300 pb-5 mb-5"
                          key={item.cartId}
                        >
                          <div className="flex gap-5">
                            <div className="flex-none w-20">
                              <Link to="/tailor-tshirts/body">
                                <img src={item.image} alt={item.name} />
                              </Link>
                            </div>
                            <div className="flex-1">
                              <p className="text-xs rounded-full inline-block px-2 bg-black text-white">
                                {item.category}
                              </p>
                              <h3 className="text-lg font-bold italic">
                                {item.name}
                              </h3>
                              <p className="mb-2">
                                <span className="text-xs">価格：</span>
                                {item.price.toLocaleString()}
                                <span className="text-xs">円（税込）</span>
                              </p>
                              <ul className="text-sm">
                                <li className="inline-block mr-2">
                                  カラー：{item.color}、
                                </li>
                                <li className="inline-block mr-2">
                                  サイズ：オーダーメイド、
                                </li>
                                <li className="inline-block mr-2">
                                  ネック：{item.neck}、
                                </li>
                                <li className="inline-block mr-2">
                                  ネックリブ{item.neckLib}cm
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )
                    } else if (
                      item.category === 'Tailor T-shirts' &&
                      !item.isNew
                    ) {
                      return (
                        <div
                          className="flex flex-col w-full border-b border-gray-300 pb-5 mb-5"
                          key={item.cartId}
                        >
                          <div className="flex gap-5">
                            <div className="flex-none w-20">
                              <Link to={`/tailor-tshirts/${item._id}`}>
                                <img src={item.image} alt={item.name} />
                              </Link>
                            </div>
                            <div className="flex-1">
                              <p className="text-xs rounded-full inline-block px-2 bg-black text-white">
                                {item.category} / Repeat
                              </p>
                              <h3 className="text-lg font-bold italic">
                                {item.name}
                              </h3>
                              <p className="mb-2">
                                <span className="text-xs">価格：</span>
                                {item.price.toLocaleString()}
                                <span className="text-xs">円（税込）</span>
                              </p>
                              <ul className="text-sm">
                                <li className="inline-block mr-2">
                                  枚数：{item.qty}、
                                </li>
                                <li className="inline-block mr-2">
                                  カラー：{item.color}、
                                </li>
                                <li className="inline-block mr-2">
                                  サイズ：{item.size}、
                                </li>
                                <li className="inline-block mr-2">
                                  タグ：{item.tag}、
                                </li>
                                <li className="inline-block mr-2">
                                  リブ：{item.lib}、
                                </li>
                                <li className="inline-block mr-2">
                                  ポケット：
                                  {item.pocket.pocketType !== '付けない' &&
                                    item.pocket.pocketPosition}
                                  {item.pocket.pocketType !== '付けない' && (
                                    <span> / </span>
                                  )}
                                  {item.pocket.pocketType}、
                                </li>
                                <li className="inline-block mr-2">
                                  名入れ：
                                  {item.embroidery.isEmbroidery !== '無し' &&
                                    item.embroidery.embroideryChar}
                                  {item.embroidery.isEmbroidery !== '無し' && (
                                    <span> / </span>
                                  )}
                                  {item.embroidery.isEmbroidery !== '無し' &&
                                    item.embroidery.embroideryColor}
                                  {item.embroidery.isEmbroidery === '無し' &&
                                    item.embroidery.isEmbroidery}
                                  、
                                </li>
                                <li className="inline-block mr-2">
                                  サイズ変更：
                                  {item.fixSize === '' ? 'なし' : 'あり'}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )
                    } else if (item.category === 'Customize T-shirts') {
                      return (
                        <div
                          className="flex flex-col w-full border-b border-gray-300 pb-5 mb-5"
                          key={item.cartId}
                        >
                          <div className="flex gap-5">
                            <div className="flex-none w-20">
                              <Link to={`/customize-tshirts/${item._id}`}>
                                <img src={item.image} alt={item.name} />
                              </Link>
                            </div>

                            <div className="flex-1">
                              <p className="text-xs border border-gray-400 rounded-full inline-block px-2 bg-white">
                                {item.category}
                              </p>
                              <h3 className="text-lg font-bold italic">
                                {item.name}
                              </h3>
                              <p className="mb-2">
                                <span className="text-xs">価格：</span>
                                {item.price.toLocaleString()}
                                <span className="text-xs">円（税込）</span>
                              </p>
                              <ul className="text-sm">
                                <li className="inline-block mr-2">
                                  枚数：{item.qty}、
                                </li>
                                <li className="inline-block mr-2">
                                  カラー：{item.color}、
                                </li>
                                <li className="inline-block mr-2">
                                  サイズ：{item.size}、
                                </li>
                                <li className="inline-block mr-2">
                                  ネック：{item.neck}、
                                </li>
                                <li className="inline-block mr-2">
                                  スタイル：{item.style}、
                                </li>
                                <li className="inline-block mr-2">
                                  着丈：{item.length}、
                                </li>
                                {item.type === 'Long T-shirts' && (
                                  <li className="inline-block mr-2">
                                    袖丈：{item.sleeveLength}、
                                  </li>
                                )}
                                <li className="text-[13px] inline-block mr-2">
                                  リブ：{item.lib}、
                                </li>
                                <li className="text-[13px] inline-block mr-2">
                                  ポケット：
                                  {item.pocket.pocketType !== '付けない' &&
                                    item.pocket.pocketPosition}
                                  {item.pocket.pocketType !== '付けない' && (
                                    <span> / </span>
                                  )}
                                  {item.pocket.pocketType}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )
                    } else {
                      return <Message>該当しない商品です</Message>
                    }
                  })}
                </div>
              )}
            </div>

            <div className="w-full lg:basis-2/5 bg-gray-100 p-5">
              <form onSubmit={submitHandler}>
                <div className="flex justify-between mb-5 gap-5 w-full">
                  <div className="text-center w-1/2">
                    <input
                      type="radio"
                      name="payment-method"
                      id="creditcard"
                      value="クレジットカード"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      checked={paymentMethod === 'クレジットカード'}
                      className="hidden"
                    />
                    <label
                      className={`w-full btn btn-sm h-14 ${
                        paymentMethod === 'クレジットカード'
                          ? 'btn-primary'
                          : 'btn-outline bg-white'
                      }`}
                      htmlFor="creditcard"
                    >
                      クレジットカード
                    </label>
                  </div>

                  <div className="text-center w-1/2">
                    <input
                      type="radio"
                      name="payment-method"
                      id="bank"
                      value="銀行振込"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      checked={paymentMethod === '銀行振込'}
                      className="hidden"
                    />
                    <label
                      className={`w-full btn btn-sm h-14 ${
                        paymentMethod === '銀行振込'
                          ? 'btn-primary'
                          : 'btn-outline bg-white'
                      }`}
                      htmlFor="bank"
                    >
                      銀行振込
                    </label>
                  </div>
                </div>

                <div className="mb-8 mt-5 flex flex-col xl:flex-row justify-between">
                  {paymentMethod === 'クレジットカード' ? (
                    <div className="flex flex-row mt-2 mb-5">
                      <img
                        className="h-6 mr-2"
                        src="/images/creditcard/visa.svg"
                        alt="visa"
                      />
                      <img
                        className="h-6 mr-2"
                        src="/images/creditcard/mastercard.svg"
                        alt="master"
                      />
                      <img
                        className="h-6 mr-2"
                        src="/images/creditcard/jcb.svg"
                        alt="jcb"
                      />
                      <img
                        className="h-6 mr-2"
                        src="/images/creditcard/amex.svg"
                        alt="amex"
                      />
                      <img
                        className="h-6 mr-2"
                        src="/images/creditcard/diners.svg"
                        alt="diners"
                      />
                      <img
                        className="h-6 mr-2"
                        src="/images/creditcard/google.svg"
                        alt="google"
                      />
                      <img
                        className="h-6 mr-2"
                        src="/images/creditcard/apple-pay.svg"
                        alt="apple-pay"
                      />
                    </div>
                  ) : (
                    <div className="mb-3">
                      <img
                        className="w-64"
                        src="/images/mufg_logo.png"
                        alt="三菱東京UFJ銀行"
                      />
                    </div>
                  )}

                  <ul className="text-sm text-right">
                    <li>
                      小計：
                      <span className="text-xl mr-1">
                        {cartItems
                          .reduce(
                            (acc, item) => acc + Number(item.qty) * item.price,
                            0
                          )
                          .toLocaleString()}
                      </span>
                      円
                    </li>
                    <li>
                      配送料：
                      <span className="text-xl mr-1">
                        {cart.shippingPrice.toLocaleString()}
                      </span>
                      円
                    </li>
                    <li className="mt-3">
                      合計（税込）：
                      <span className="text-3xl font-semibold mr-1">
                        {cart.totalPrice.toLocaleString()}
                      </span>
                      円
                    </li>
                  </ul>
                </div>
                <button type="submit" className="btn btn-primary w-full">
                  お支払いに進む
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentScreen
