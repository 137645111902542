import { useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import gsap from 'gsap'
import CustomNeck from './customizeItems/CustomNeck'
import CustomSize from './customizeItems/CustomSize'
import CustomStyle from './customizeItems/CustomStyle'
import CustomLength from './customizeItems/CustomLength'
import CustomSleeveLength from './customizeItems/CustomSleeveLength'
import CustomTag from './customizeItems/CustomTag'
import CustomSleeveLib from './customizeItems/CustomSleeveLib'
import CustomPocket from './customizeItems/CustomPocket'
import CustomEmbroidery from './customizeItems/CustomEmbroidery'
import { changeCustomSlideState } from '../store/slices/customSlideSlice'

const CustomSlide = ({
  changeNeck,
  changeSize,
  changeStyle,
  changeLength,
  changeSleeveLength,
  changeTag,
  changeSleeveLib,
  changePocket,
  changeEmbroidery,
  selectedValue,
  type,
  selectedColor,
}) => {
  const { isCustomSlideOpen, customItem } = useSelector(
    (state) => state.customSlide
  )
  const comp = useRef()
  const ctx = useRef()
  const tl = useRef()
  const dispatch = useDispatch()

  useEffect(() => {
    ctx.current = gsap.context(() => {
      tl.current = gsap.timeline({ pause: true })
      tl.current
        .to('.background-dark', { opacity: 0.3, duration: 0.1 })
        .to('.slide-menu', {
          xPercent: -100,
          duration: 0.3,
          ease: 'power2.inOut',
        })
    }, comp)
  }, [ctx])

  useEffect(() => {
    ctx.current.add(() => {
      isCustomSlideOpen ? tl.current.play() : tl.current.reverse()
    })
    window.scrollTo(0, 0)
    isCustomSlideOpen
      ? (document.body.style.position = 'fixed')
      : (document.body.style.position = 'static')
  }, [isCustomSlideOpen])

  const checkingCustomItem = () => {
    switch (customItem) {
      case 'size':
        return (
          <CustomSize
            changeSize={changeSize}
            selectedValue={selectedValue}
            type={type}
          />
        )
      case 'neck':
        return (
          <CustomNeck changeNeck={changeNeck} selectedValue={selectedValue} />
        )
      case 'style':
        return (
          <CustomStyle
            changeStyle={changeStyle}
            selectedValue={selectedValue}
            type={type}
          />
        )
      case 'length':
        return (
          <CustomLength
            changeLength={changeLength}
            selectedValue={selectedValue}
            type={type}
          />
        )
      case 'sleeveLength':
        return (
          <CustomSleeveLength
            changeSleeveLength={changeSleeveLength}
            selectedValue={selectedValue}
          />
        )
      case 'tag':
        return <CustomTag changeTag={changeTag} selectedValue={selectedValue} />
      case 'lib':
        return (
          <CustomSleeveLib
            changeSleeveLib={changeSleeveLib}
            selectedValue={selectedValue}
            type={type}
          />
        )
      case 'pocket':
        return (
          <CustomPocket
            changePocket={changePocket}
            selectedValue={selectedValue}
          />
        )
      case 'embroidery':
        return (
          <CustomEmbroidery
            changeEmbroidery={changeEmbroidery}
            selectedValue={selectedValue}
            selectedColor={selectedColor}
          />
        )
      default:
        return ''
    }
  }

  return (
    <div
      ref={comp}
      className={`overflow-hidden fixed top-14 left-0 z-20 w-full calc-height-screen ${
        !isCustomSlideOpen && 'pointer-events-none'
      }`}
    >
      <div
        className="absolute top-0 left-0 background-dark bg-black w-full h-full opacity-0"
        onClick={() => dispatch(changeCustomSlideState())}
      ></div>
      <div
        data-theme="lofi"
        className="slide-menu w-full bg-white lg:w-1/2 h-full flex flex-col items-center p-5 pt-20 lg:p-14 lg:pt-20 absolute top-0 -right-full lg:-right-1/2"
      >
        {checkingCustomItem()}
        <div
          className="absolute inset-5 cursor-pointe w-10 h-10 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => dispatch(changeCustomSlideState())}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default CustomSlide
