import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

const SearchBox = () => {
  const navigate = useNavigate()
  const { keyword: urlKeyword } = useParams()
  const [keyword, setKeyword] = useState(urlKeyword || '')

  const submitHandler = (e) => {
    e.preventDefault()

    if (keyword.trim()) {
      navigate(`/admin/userlist/search/${keyword}`)
      setKeyword('')
    } else {
      navigate('/admin/userlist')
    }
  }

  return (
    <form onSubmit={submitHandler}>
      <div className="join">
        <input
          type="text"
          className="input input-bordered join-item text-sm w-72"
          placeholder="苗字（一部でも可）をカタカナで入力"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <button className="btn join-item rounded-r-full">検索</button>
      </div>
    </form>
  )
}

export default SearchBox
