import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isCustomSlideOpen: false,
  customItem: '',
}

export const customSlideSlice = createSlice({
  name: 'customSlide',
  initialState,
  reducers: {
    changeCustomSlideState: (state, action) => {
      state.isCustomSlideOpen = !state.isCustomSlideOpen
      state.customItem = action.payload
    },
  },
})

export const { changeCustomSlideState } = customSlideSlice.actions

export default customSlideSlice.reducer
