import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useCreateTailorSizeMutation } from '../../store/apis/usersApi'
import { toast } from 'react-toastify'

const CreateTailorSize = () => {
  const { id: userId } = useParams()
  const [bodyType, setBodyType] = useState('Tシャツ')
  const [name, setName] = useState('')
  const [length, setLength] = useState('')
  const [chest, setChest] = useState('')
  const [waist, setWaist] = useState('')
  const [hem, setHem] = useState('')
  const [shoulder, setShoulder] = useState('')
  const [shoulderLine, setShoulderLine] = useState('')
  const [armpit, setArmpit] = useState('')
  const [sleeveHalf, setSleeveHalf] = useState('')
  const [sleeveLong, setSleeveLong] = useState('')
  const [arm, setArm] = useState('')
  const [wrist, setWrist] = useState('')
  const [neckType, setNeckType] = useState('O-neck')
  const [neckWidth, setNeckWidth] = useState('')
  const [neckHeight, setNeckHeight] = useState('')
  const [comment, setComment] = useState('')

  const [createTailorSize, { isLoading }] = useCreateTailorSizeMutation()

  useEffect(() => {
    let date = new Date()
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9) // JSTに変換
    setName(
      date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        ' - ' +
        bodyType
    )
  }, [bodyType])

  const submitHandler = async (e) => {
    e.preventDefault()

    try {
      await createTailorSize({
        userId,
        bodyType,
        name,
        length,
        chest,
        waist,
        hem,
        shoulder,
        shoulderLine,
        armpit,
        sleeveHalf,
        sleeveLong,
        arm,
        wrist,
        neckType,
        neckWidth,
        neckHeight,
        comment,
      }).unwrap()
      toast.success('サイズは追加されました')
      setLength('')
      setChest('')
      setWaist('')
      setHem('')
      setShoulder('')
      setShoulderLine('')
      setArmpit('')
      setSleeveHalf('')
      setSleeveLong('')
      setArm('')
      setWrist('')
      setNeckWidth('')
      setNeckHeight('')
      setComment('')
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  return (
    <div className="container mt-14 lg:mt-20 flex justify-center mb-24">
      <form onSubmit={submitHandler} className="lg:w-[70%]">
        <div className="flex gap-20 ">
          <div className="w-1/2">
            <div className="form-control w-full mb-5 flex flex-row items-center">
              <label className="text-sm w-32 text-right mr-3">
                サイズネーム
              </label>
              <input
                type="text"
                className="input input-bordered w-full text-sm"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-control w-full mb-5 flex flex-row items-center">
              <label className="text-sm w-32 text-right mr-3">
                ボディタイプ
              </label>
              <select
                className="select select-bordered w-full max-w-xs"
                value={bodyType}
                onChange={(e) => setBodyType(e.target.value)}
              >
                <option value="Tシャツ">Tシャツ</option>
                <option value="ロングTシャツ">ロングTシャツ</option>
              </select>
            </div>
            <div className="form-control w-full mb-5 flex flex-row items-center">
              <label className="text-sm w-32 text-right mr-3">着丈</label>
              <input
                type="number"
                className="input input-bordered w-full text-sm"
                value={length}
                onChange={(e) => setLength(e.target.value)}
              />
            </div>
            <div className="form-control w-full mb-5 flex flex-row items-center">
              <label className="text-sm w-32 text-right mr-3">着幅</label>
              <input
                type="number"
                className="input input-bordered w-full text-sm"
                value={chest}
                onChange={(e) => setChest(e.target.value)}
              />
            </div>
            <div className="form-control w-full mb-5 flex flex-row items-center">
              <label className="text-sm w-32 text-right mr-3">ウエスト</label>
              <input
                type="number"
                className="input input-bordered w-full text-sm"
                value={waist}
                onChange={(e) => setWaist(e.target.value)}
              />
            </div>
            <div className="form-control w-full mb-5 flex flex-row items-center">
              <label className="text-sm w-32 text-right mr-3">裾幅</label>
              <input
                type="number"
                className="input input-bordered w-full text-sm"
                value={hem}
                onChange={(e) => setHem(e.target.value)}
              />
            </div>
            <div className="form-control w-full mb-5 flex flex-row items-center">
              <label className="text-sm w-32 text-right mr-3">肩幅</label>
              <input
                type="number"
                className="input input-bordered w-full text-sm"
                value={shoulder}
                onChange={(e) => setShoulder(e.target.value)}
              />
            </div>
            <div className="form-control w-full mb-5 flex flex-row items-center">
              <label className="text-sm w-32 text-right mr-3">肩傾斜</label>
              <input
                type="number"
                className="input input-bordered w-full text-sm"
                placeholder="角度"
                value={shoulderLine}
                onChange={(e) => setShoulderLine(e.target.value)}
              />
            </div>
          </div>
          <div className="w-1/2">
            <div className="form-control w-full mb-5 flex flex-row items-center">
              <label className="text-sm w-32 text-right mr-3">脇高</label>
              <input
                type="number"
                className="input input-bordered w-full text-sm"
                value={armpit}
                onChange={(e) => setArmpit(e.target.value)}
              />
            </div>
            <div className="form-control w-full mb-5 flex flex-row items-center">
              <label className="text-sm w-32 text-right mr-3">
                袖丈（半袖）
              </label>
              <input
                type="number"
                className="input input-bordered w-full text-sm"
                value={sleeveHalf}
                onChange={(e) => setSleeveHalf(e.target.value)}
                disabled={bodyType === 'ロングTシャツ'}
              />
            </div>
            <div className="form-control w-full mb-5 flex flex-row items-center">
              <label className="text-sm w-32 text-right mr-3">
                袖丈（長袖）
              </label>
              <input
                type="number"
                className="input input-bordered w-full text-sm"
                value={sleeveLong}
                onChange={(e) => setSleeveLong(e.target.value)}
                disabled={bodyType === 'Tシャツ'}
              />
            </div>
            <div className="form-control w-full mb-5 flex flex-row items-center">
              <label className="text-sm w-32 text-right mr-3">
                袖幅（半袖）
              </label>
              <input
                type="number"
                className="input input-bordered w-full text-sm"
                value={arm}
                onChange={(e) => setArm(e.target.value)}
                disabled={bodyType === 'ロングTシャツ'}
              />
            </div>
            <div className="form-control w-full mb-5 flex flex-row items-center">
              <label className="text-sm w-32 text-right mr-3">
                袖幅（長袖）
              </label>
              <input
                type="number"
                className="input input-bordered w-full text-sm"
                value={wrist}
                onChange={(e) => setWrist(e.target.value)}
                disabled={bodyType === 'Tシャツ'}
              />
            </div>
            <div className="form-control w-full mb-5 flex flex-row items-center">
              <label className="text-sm w-32 text-right mr-3">
                ネックタイプ
              </label>
              <select
                className="select select-bordered w-full max-w-xs"
                value={neckType}
                onChange={(e) => setNeckType(e.target.value)}
              >
                <option value="O-neck">O-neck</option>
                <option value="V-neck">V-neck</option>
              </select>
            </div>
            <div className="form-control w-full mb-5 flex flex-row items-center">
              <label className="text-sm w-32 text-right mr-3">首幅</label>
              <input
                type="number"
                className="input input-bordered w-full text-sm"
                value={neckWidth}
                onChange={(e) => setNeckWidth(e.target.value)}
              />
            </div>
            <div className="form-control w-full mb-5 flex flex-row items-center">
              <label className="text-sm w-32 text-right mr-3">首深</label>
              <input
                type="number"
                className="input input-bordered w-full text-sm"
                value={neckHeight}
                onChange={(e) => setNeckHeight(e.target.value)}
              />
            </div>
          </div>
        </div>

        <textarea
          className="textarea textarea-bordered w-full my-5"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="コメント"
        ></textarea>

        <button type="submit" className="btn btn-primary w-full mt-5">
          追加する
        </button>
      </form>
    </div>
  )
}

export default CreateTailorSize
