import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { changeCustomSlideState } from '../../store/slices/customSlideSlice'

const CustomPocket = ({ changePocket, selectedValue }) => {
  const [selectedPocket, setSelectedPocket] = useState(
    selectedValue.selectedPocket
  )
  const dispatch = useDispatch()

  const changePocketHandler = () => {
    changePocket(
      selectedPocket,
      selectedPocket.pocketType === '付けない' ? 0 : 1320
    )
    dispatch(changeCustomSlideState())
  }
  return (
    <div className="w-full h-full pb-28 lg:pb-10 overflow-y-auto">
      <div className="flex flex-col justify-center mb-10">
        <div className="grid grid-cols-2 gap-10 w-full mb-10">
          <div className="flex flex-col items-center">
            <img
              className="w-full lg:w-[75%] mb-2"
              src="/images/custom_items/pocket-none.png"
              alt="Tシャツポケットなし"
            />
            <input
              type="radio"
              name="pocket"
              id="none"
              value="付けない"
              checked={selectedPocket.pocketType === '付けない'}
              className="hidden"
              onChange={(e) =>
                setSelectedPocket({
                  ...selectedPocket,
                  pocketType: e.target.value,
                })
              }
            />
            <label
              className={`text-xs xl:text-sm btn btn-sm btn-outline rounded-full border-gray-400 w-full xl:w-64 ${
                selectedPocket.pocketType === '付けない' &&
                'bg-black text-white'
              }`}
              htmlFor="none"
            >
              付けない
            </label>
          </div>
          <div className="flex flex-col items-center">
            <img
              className="w-full lg:w-[75%] mb-2"
              src="/images/custom_items/pocket-square.png"
              alt="Tシャツポケットスクエア"
            />
            <input
              type="radio"
              name="pocket"
              id="square"
              value="スクエア"
              checked={selectedPocket.pocketType === 'スクエア'}
              className="hidden"
              onChange={(e) =>
                setSelectedPocket({
                  ...selectedPocket,
                  pocketType: e.target.value,
                })
              }
            />
            <label
              className={`text-xs xl:text-sm btn btn-sm btn-outline rounded-full border-gray-400 w-full xl:w-64 ${
                selectedPocket.pocketType === 'スクエア' &&
                'bg-black text-white'
              }`}
              htmlFor="square"
            >
              スクエア （+1,320円）
            </label>
          </div>
          <div className="flex flex-col items-center">
            <img
              className="w-full lg:w-[75%] mb-2"
              src="/images/custom_items/pocket-homebase.png"
              alt="Tシャツポケットホームベース"
            />
            <input
              type="radio"
              name="pocket"
              id="homebase"
              value="ホームベース"
              checked={selectedPocket.pocketType === 'ホームベース'}
              className="hidden"
              onChange={(e) =>
                setSelectedPocket({
                  ...selectedPocket,
                  pocketType: e.target.value,
                })
              }
            />
            <label
              className={`text-xs xl:text-sm btn btn-sm btn-outline rounded-full border-gray-400 w-full xl:w-64 ${
                selectedPocket.pocketType === 'ホームベース' &&
                'bg-black text-white'
              }`}
              htmlFor="homebase"
            >
              ホームベース （+1,320円）
            </label>
          </div>
          <div className="flex flex-col items-center">
            <img
              className="w-full lg:w-[75%] mb-2"
              src="/images/custom_items/pocket-cutedge.png"
              alt="Tシャツポケットカットエッジ"
            />
            <input
              type="radio"
              name="pocket"
              id="cutcorner"
              value="カットエッジ"
              checked={selectedPocket.pocketType === 'カットエッジ'}
              className="hidden"
              onChange={(e) =>
                setSelectedPocket({
                  ...selectedPocket,
                  pocketType: e.target.value,
                })
              }
            />
            <label
              className={`text-xs xl:text-sm btn btn-sm btn-outline rounded-full border-gray-400 w-full xl:w-64 ${
                selectedPocket.pocketType === 'カットエッジ' &&
                'bg-black text-white'
              }`}
              htmlFor="cutcorner"
            >
              カットエッジ （+1,320円）
            </label>
          </div>
        </div>

        {selectedPocket.pocketType !== '付けない' && (
          <div className="flex flex-row items-center justify-center gap-10 w-full">
            <div>
              <input
                type="radio"
                name="postion"
                id="left"
                value="左胸"
                checked={selectedPocket.pocketPosition === '左胸'}
                className="hidden"
                onChange={(e) =>
                  setSelectedPocket({
                    ...selectedPocket,
                    pocketPosition: e.target.value,
                  })
                }
              />
              <label
                className={`btn btn-sm btn-outline rounded-full border-gray-400 w-full xl:w-64 ${
                  selectedPocket.pocketPosition === '左胸' &&
                  'bg-black text-white'
                }`}
                htmlFor="left"
              >
                左胸
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="postion"
                id="right"
                value="右胸"
                checked={selectedPocket.pocketPosition === '右胸'}
                className="hidden"
                onChange={(e) =>
                  setSelectedPocket({
                    ...selectedPocket,
                    pocketPosition: e.target.value,
                  })
                }
              />
              <label
                className={`btn btn-sm btn-outline rounded-full border-gray-400 w-full xl:w-64 ${
                  selectedPocket.pocketPosition === '右胸' &&
                  'bg-black text-white'
                }`}
                htmlFor="right"
              >
                右胸
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="w-full text-center">
        <button
          className="btn btn-primary btn-sm w-72"
          onClick={changePocketHandler}
        >
          決定する
        </button>
      </div>
    </div>
  )
}

export default CustomPocket
