const SizeChartHalf = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="\u30EC\u30A4\u30E4\u30FC_1"
    x={0}
    y={0}
    style={{
      enableBackground: 'new 0 0 450 400',
    }}
    viewBox="0 0 450 400"
  >
    <style>
      {
        '.st1{stroke:#000;stroke-linejoin:round}.st1,.st2,.st3{fill:none;stroke-miterlimit:10}.st2{stroke:#000;stroke-linecap:round;stroke-linejoin:round}.st3{stroke:#aaa}.st4{font-family:"NotoSansCJKjp-Regular-83pv-RKSJ-H"}.st5{font-size:13px}.st6{fill:#aaa}'
      }
    </style>
    <path
      d="M138.2 168.8c1.2 26.4-.5 183.1-.5 183.1h176.8s-1.7-156.7-.5-183.1l14.4 17.6 45.7-24.8c-5-18.5-19.6-52.3-29.8-67.3-12.1-17.8-24.8-23.5-24.8-23.5l-63.2-23.2s-7 7.9-30.3 7.9h.3c-23.3 0-30.3-7.9-30.3-7.9l-63.2 23.2s-12.6 5.7-24.8 23.5c-10.2 15-24.8 48.8-29.8 67.3l45.7 24.8 14.3-17.6z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M256.3 47.6c2.4 4.7 3.8 10 3.8 15.7 0 18.9-15.3 34.2-34.2 34.2h.3c-18.9 0-34.2-15.3-34.2-34.2 0-5.6 1.4-11 3.8-15.7"
      className="st1"
    />
    <path
      d="M256.3 51.4s-7 7.9-30.3 7.9h.3c-23.3 0-30.3-7.9-30.3-7.9"
      className="st1"
    />
    <path
      d="M261.2 49.4c1.6 4.2 2.7 9.4 2.7 13.9 0 20.8-16.9 37.8-37.7 38h-.3c-20.8-.2-37.7-17.1-37.7-38 0-4.4 1-9.5 2.5-13.8M79.2 158.1l47.1 25.6"
      className="st1"
    />
    <path
      d="m314.1 168.8 14.4 17.6 45.7-24.8c-5-18.5-19.6-52.3-29.8-67.3-12.1-17.8-24.8-23.5-24.8-23.5l-63.2-23.2s-7 7.9-30.3 7.9h.3c-23.3 0-30.3-7.9-30.3-7.9l-63.2 23.2s-12.6 5.7-24.8 23.5c-10.2 15-24.8 48.8-29.8 67.3l45.7 24.8 14.4-17.6M373.1 158.1l-47.2 25.6"
      className="st1"
    />
    <path
      d="M323.6 81.8s-7.8 30.6-9.1 57c-1.2 26.4 0 213.1 0 213.1H137.7s1.2-186.7 0-213.1-9.1-57-9.1-57M314.5 347.9H137.7"
      className="st2"
    />
    <path
      d="M336.1 74.8h75.5M374 169.1h37.6M129.1 30.2h77.4M245 30.2h78.5M397.6 72v38.4M397.6 136.7v32.8M127.6 15.2v34.3M137.7 356v30M314.5 356v30M59.7 193.7l14.5-26.2M106.4 219.7l14.5-26.3M92.6 53.4l26 15M41.3 140.9l26 15M323.5 15v44.2M86.9 93.7l19.3-32.4M80.6 187.6l-13.3-7.4M114.3 206.3l-14.2-7.9M138.2 370.9h67.5M247.7 370.9h66.8"
      className="st3"
    />
    <text className="st4 st5" transform="translate(389.877 127.824)">
      {size.armpit}
    </text>
    <text className="st4 st5" transform="translate(218.785 35.308)">
      {size.shoulder}
    </text>
    <text className="st4 st5" transform="translate(73.436 109.072)">
      {size.sleeve}
    </text>
    <text className="st4 st5" transform="translate(82.669 198.398)">
      {size.arm}
    </text>
    <text className="st4 st5" transform="translate(219.878 376.308)">
      {size.hem}
    </text>
    <circle cx={127.6} cy={30.5} r={2.5} className="st6" />
    <circle cx={106.2} cy={61.3} r={2.5} className="st6" />
    <circle cx={323.5} cy={30.5} r={2.5} className="st6" />
    <circle cx={54.3} cy={148.4} r={2.5} className="st6" />
    <circle cx={67.3} cy={180.2} r={2.5} className="st6" />
    <circle cx={114} cy={206.1} r={2.5} className="st6" />
    <circle cx={397.5} cy={169.5} r={2.5} className="st6" />
    <circle cx={397.5} cy={74.5} r={2.5} className="st6" />
    <circle cx={137.7} cy={370.5} r={2.5} className="st6" />
    <circle cx={314.5} cy={370.5} r={2.5} className="st6" />
    <circle cx={226.5} cy={55.5} r={2.5} className="st6" />
    <path d="M226.5 55.5v146.9M226.5 233.4v118.5" className="st3" />
    <text className="st4 st5" transform="translate(219.285 221.342)">
      {size.length}
    </text>
    <circle cx={226.5} cy={351.9} r={2.5} className="st6" />
    <path d="M138.8 168.9h24.5M194.1 168.9H314" className="st3" />
    <text className="st4 st5" transform="translate(171.878 174.307)">
      {size.chest}
    </text>
  </svg>
)
export default SizeChartHalf
