import React from 'react'
import ReactDOM from 'react-dom/client'
import { store } from './store'
import { Provider } from 'react-redux'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { HelmetProvider } from 'react-helmet-async'

// screens & components
import ErrorScreen from './screens/ErrorScreen'
import HomeScreen from './screens/HomeScreen'
import TailorTshirtsScreen from './screens/TailorTshirtsScreen'
import TailorBodyScreen from './screens/tailor/TailorBodyScreen'
import TailorNeckScreen from './screens/tailor/TailorNeckScreen'
import TailorSizeScreen from './screens/tailor/TailorSizeScreen'
import TailorConfirmScreen from './screens/tailor/TailorConfirmScreen'
import CustomizeTshirtsScreen from './screens/CustomizeTshirtsScreen'
import CustomizeTshirtDetailScreen from './screens/CustomizeTshirtDetailScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ShoppingGuideScreen from './screens/ShoppingGuideScreen'
import CartScreen from './screens/CartScreen'
import ShippingScreen from './screens/ShippingScreen'
import PaymentScreen from './screens/PaymentScreen'
import PrivateRoute from './components/PrivateRoute'
import AdminRoute from './components/AdminRoute'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderScreen from './screens/OrderScreen'
import ContactScreen from './screens/Contact'
import CheckoutSuccess from './screens/CheckoutSuccess'
import ProfileScreen from './screens/ProfileScreen'
import OrderListScreen from './screens/admin/OrderListScreen'
import UserListScreen from './screens/admin/UserListScreen'
import UserEditScreen from './screens/admin/UserEditScreen'
import TransactionLawScreen from './screens/TransactionLawScreen'
import ForgotPasswordScreen from './screens/ForgotPasswordScreen'
import PasswordResetScreen from './screens/PasswordResetScreen'
import TailorRepeatOrderScreen from './screens/tailor/TailorReaptOrderScreen'
import TailorTshirtsDetailScreen from './screens/tailor/TailorTshirtsDetailScreen'
import OrderTshirtsScreen from './screens/OrderTshirtsScreen'
import TryonScreen from './screens/TryonScreen'
import ResizeScreen from './screens/ResizeScreen'
import FabricSampleScreen from './screens/FabricSampleScreen'
import FabricSampleFormScreen from './screens/FabricSampleFormScreen'
import ProductListScreen from './screens/admin/ProductListScreen'
import CustomizeEditScreen from './screens/admin/CustomizeEditScreen'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorScreen />,
    children: [
      {
        path: '/',
        element: <HomeScreen />,
      },
      {
        path: '/login',
        element: <LoginScreen />,
      },
      {
        path: '/forgot-password',
        element: <ForgotPasswordScreen />,
      },
      {
        path: '/reset-password',
        element: <PasswordResetScreen />,
      },
      {
        path: '/register',
        element: <RegisterScreen />,
      },
      {
        path: '/tailor-tshirts',
        element: <TailorTshirtsScreen />,
      },
      {
        path: '/tailor-tshirts/body',
        element: <TailorBodyScreen />,
      },
      {
        path: '/tailor-tshirts/neck',
        element: <TailorNeckScreen />,
      },
      {
        path: '/tailor-tshirts/size',
        element: <TailorSizeScreen />,
      },
      {
        path: '/tailor-tshirts/confirm',
        element: <TailorConfirmScreen />,
      },
      {
        path: '/customize-tshirts',
        element: <CustomizeTshirtsScreen />,
      },
      {
        path: '/customize-tshirts/:id',
        element: <CustomizeTshirtDetailScreen />,
      },
      {
        path: '/cart',
        element: <CartScreen />,
      },
      {
        path: '/contact',
        element: <ContactScreen />,
      },
      {
        path: '/shopping-guide',
        element: <ShoppingGuideScreen />,
      },
      {
        path: '/transaction-law',
        element: <TransactionLawScreen />,
      },
      {
        path: '/checkout-success',
        element: <CheckoutSuccess />,
      },
      {
        path: '/order-tshirts',
        element: <OrderTshirtsScreen />,
      },
      {
        path: '/tryon',
        element: <TryonScreen />,
      },
      {
        path: '/resize',
        element: <ResizeScreen />,
      },
      {
        path: '/fabric-sample',
        element: <FabricSampleScreen />,
      },
      {
        path: '/fabric-sample-form',
        element: <FabricSampleFormScreen />,
      },
      {
        path: '',
        element: <PrivateRoute />,
        children: [
          {
            path: '/shipping',
            element: <ShippingScreen />,
          },
          {
            path: '/payment',
            element: <PaymentScreen />,
          },
          {
            path: '/placeorder',
            element: <PlaceOrderScreen />,
          },
          {
            path: '/orders/:id',
            element: <OrderScreen />,
          },
          {
            path: '/profile',
            element: <ProfileScreen />,
          },
          {
            path: '/repeat-order',
            element: <TailorRepeatOrderScreen />,
          },
          {
            path: '/tailor-tshirts/:id',
            element: <TailorTshirtsDetailScreen />,
          },
        ],
      },
      {
        path: '',
        element: <AdminRoute />,
        children: [
          {
            path: '/admin/orderlist',
            element: <OrderListScreen />,
          },
          {
            path: '/admin/orderlist/page/:pageNumber',
            element: <OrderListScreen />,
          },
          {
            path: '/admin/userlist',
            element: <UserListScreen />,
          },
          {
            path: '/admin/userlist/search/:keyword',
            element: <UserListScreen />,
          },
          {
            path: '/admin/userlist/page/:pageNumber',
            element: <UserListScreen />,
          },
          {
            path: '/admin/userlist/search/:keyword/page/:pageNumber',
            element: <UserListScreen />,
          },
          {
            path: '/admin/user/:id/edit',
            element: <UserEditScreen />,
          },
          {
            path: '/admin/productlist',
            element: <ProductListScreen />,
          },
          {
            path: '/admin/customize/:id/edit',
            element: <CustomizeEditScreen />,
          },
        ],
      },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <HelmetProvider>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </HelmetProvider>
  // </React.StrictMode>
)

reportWebVitals()
