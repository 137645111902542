import { Link, useParams } from 'react-router-dom'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import PaginateOrders from '../../components/PaginateOrders'
import { useGetOrdersQuery } from '../../store/apis/ordersApi'

const OrderListScreen = () => {
  const { pageNumber } = useParams()
  const { data, isLoading, error } = useGetOrdersQuery({ pageNumber })

  return (
    <div className="flex justify-center">
      <div className="screen-container mt-5 w-full mb-20">
        <div className="text-sm breadcrumbs text-gray-500 mb-10">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Order list</li>
          </ul>
        </div>
        <div className="mb-10">
          {' '}
          <h2 className="page-title">All Orders</h2>
        </div>
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant={'alert-error'}>注文がありません</Message>
        ) : (
          <div className="overflow-x-auto mb-20">
            <table className="table">
              {/* head */}
              <thead>
                <tr>
                  <th>注文No</th>
                  <th>顧客</th>
                  <th>日付</th>
                  <th>ご注文金額</th>
                  <th>お支払い</th>
                  <th>発送</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.orders.map((order) => (
                  <tr key={order._id}>
                    <td>{order._id}</td>
                    <td>
                      {order.user && order.user.lastname}{' '}
                      {order.user && order.user.firstname}
                    </td>
                    <td>{order.createdAt.substring(0, 10)}</td>
                    <td>{order.totalPrice.toLocaleString()}円</td>
                    <td className="text-xs">
                      {order.isPaid ? (
                        <span className="text-teal-600 font-bold">支払済</span>
                      ) : (
                        <span className="text-yellow-600 font-bold">
                          確認中
                        </span>
                      )}
                    </td>
                    <td className="text-xs">
                      {order.isDelivered ? (
                        <span className="text-teal-600 font-bold">発送済</span>
                      ) : (
                        <span className="text-yellow-600 font-bold">
                          準備中
                        </span>
                      )}
                    </td>
                    <td>
                      <Link to={`/orders/${order._id}`}>
                        <button className="btn btn-sm btn-ghost text-xs">
                          詳細
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="w-full flex justify-center">
          {data && <PaginateOrders pages={data.pages} page={data.page} />}
        </div>
      </div>
    </div>
  )
}

export default OrderListScreen
