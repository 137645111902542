import { rootApi } from './rootApi'
import { CHECKFORM_URL } from '../../constants'

export const checkFormApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addCheckForm: builder.mutation({
      query: (checkform) => ({
        url: CHECKFORM_URL,
        method: 'POST',
        body: { ...checkform },
      }),
    }),
  }),
})

export const { useAddCheckFormMutation } = checkFormApi
