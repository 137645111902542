import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { FiExternalLink } from 'react-icons/fi'
import { changeTailorSpec } from '../../store/slices/tailorSlice'

const ChangeBodyAndColor = ({ item }) => {
  const [selectedColor, setSelectedColor] = useState('')
  const [selectedImage, setSelectedImage] = useState(item.image)
  const dispatch = useDispatch()
  const { tailorItems } = useSelector((state) => state.tailorSpec)

  useEffect(() => {
    if (item) {
      setSelectedColor(item.colors[0].colorName)
    }
  }, [item])

  useEffect(() => {
    item.colors.map(
      (color) =>
        color.colorName === selectedColor && setSelectedImage(color.image)
    )
  }, [selectedColor])

  const ChangeColorHandler = (e) => {
    setSelectedColor(e.target.value)
  }

  const selectedHandler = () => {
    dispatch(
      changeTailorSpec({
        _id: item._id,
        name: item.name,
        image: selectedImage,
        color: selectedColor,
        category: item.category,
        type: item.type,
        price: item.price,
      })
    )
  }

  return (
    <div className="flex flex-col justify-between mb-5">
      <div>
        <LazyLoadImage
          src={selectedImage}
          alt={item.name}
          className="mb-3"
          height={item.image.height}
          width={item.image.width}
          effect="blur"
        />

        <h3 className="font-semibold italic text-lg text-md flex items-center">
          {item.newItem && (
            <span className="text-xs bg-amber-600 rounded-full px-2 py-0.5 text-white mr-2">
              New
            </span>
          )}
          {item.name}
        </h3>
        <p className="font-bold mb-5 text-right">
          {item.price.toLocaleString()}
          <span className="text-xs">円</span>
        </p>

        {/* 商品説明モーダル */}
        <div className="mb-5">
          <button
            className="w-full border-y border-gray-700 text-sm font-normal flex items-center pt-1.5 pb-1 px-2 hover:border-gray-500"
            onClick={() => document.getElementById(item._id).showModal()}
          >
            <FiExternalLink className="mr-2" /> 商品概要
          </button>
          <dialog id={item._id} className="modal">
            <div data-theme="lofi" className="modal-box">
              <form method="dialog">
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                  ✕
                </button>
              </form>
              <div className="overflow-x-auto p-5">
                <h4 className="font-semibold mb-5">{item.name}の商品概要</h4>
                <table className="table">
                  <tbody>
                    <tr className="border-b border-gray-300">
                      <th className="text-sm w-16">説明</th>
                      <td className="text-sm/6">{item.description}</td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <th className="text-sm w-16">素材</th>
                      <td className="text-sm/6">{item.fabric}</td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <th className="text-sm w-16">取扱</th>
                      <td className="text-sm/6">{item.care}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </dialog>
        </div>

        {/* カラーセレクト */}
        <div className="mb-2">
          <div className="text-sm mb-2">
            <span>カラー：{selectedColor}</span>
          </div>
          <div className="flex">
            {item.colors.map((color) => (
              <div key={color.colorName}>
                <input
                  type="radio"
                  name={item._id}
                  value={color.colorName}
                  onChange={(e) => ChangeColorHandler(e)}
                  id={item._id + color.colorName}
                  className="hidden colorBadge"
                  checked={color.colorName === selectedColor}
                />
                <label
                  htmlFor={item._id + color.colorName}
                  style={{ backgroundColor: `${color.colorBg}` }}
                  className="relative w-6 h-6 inline-block rounded-full border border-gray-500 mr-2 colorBadge-checked:border-black cursor-pointer"
                >
                  <div className="colorChecked2 absolute inset-0 w-[22px] h-[22px] rounded-full border border-gray-700 hidden"></div>
                </label>
              </div>
            ))}
          </div>
        </div>

        <p className="my-3">
          {item.features.map((feature, index) => (
            <span
              key={index}
              className="text-xs border border-gray-700 py-0.5 px-2 rounded-full mr-1 inline-block mb-1"
            >
              {feature}
            </span>
          ))}
        </p>
      </div>

      <button
        onClick={selectedHandler}
        className={`btn btn-sm btn-outline rounded-full w-full bg-neutral-800 py-1 border-gray-500 hover:text-white hover:bg-neutral-700 ${
          tailorItems._id === item._id
            ? 'bg-neutral-600 border border-white hover:border-white hover:bg-neutral-600'
            : ''
        }`}
      >
        選択する
      </button>
    </div>
  )
}

export default ChangeBodyAndColor
