import { Link } from 'react-router-dom'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Meta from '../components/Meta'
import FabricSample from '../components/FabricSample'
import { useGetFabricIndexQuery } from '../store/apis/fabricSampleApi'
import { FaRegStar } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { toast } from 'react-toastify'

const FabricSampleScreen = () => {
  const { data: fabricIndex, isLoading, isError } = useGetFabricIndexQuery()

  const { fabricSamples } = useSelector((state) => state.fabricSample)

  useEffect(() => {
    if (fabricSamples.length > 5) {
      toast.error('お届け数の上限を超えましたので、どれかを削除してください。')
    }
  }, [fabricSamples.length])

  return (
    <>
      <Meta title="無料Tシャツ生地サンプル | m&s Fabric" />
      <div className="flex justify-center grow">
        <div className="screen-container mt-5 mb-14 w-full">
          <div className="text-sm breadcrumbs text-gray-500 mb-10">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>生地見本サンプル</li>
            </ul>
          </div>
          <div className="container">
            <h2 className="page-title">Fabric Samples</h2>
            <p className="page-description">生地見本サンプル</p>
            {isLoading ? (
              <Loader />
            ) : isError ? (
              <Message variant={'alert-error'}>
                生地を取得できませんでした
              </Message>
            ) : (
              <>
                <p>
                  実際に確かめたい生地のカラーを選んで
                  <FaRegStar className="inline-block mb-1 mx-1" />
                  マークで選択してください。
                </p>
                <p className="mb-5">
                  定型郵便でのお届けのため、サンプル数は
                  <span className="text-red-700">【５種類まで】</span>
                  とさせていただきます。
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                  {fabricIndex.map((fabric) => (
                    <FabricSample fabric={fabric} key={fabric._id} />
                  ))}
                </div>
              </>
            )}
            <p className="mt-10 text-sm bg-red-100 p-5 rounded-lg border border-neutral-500">
              当店で取り扱うTシャツは綿生地を使用しているため洗濯・乾燥の環境により縮みが起こる事があります。取り扱いに関しては当サイト「オーダーTシャツについて」または商品納品時に同梱される案内をご参考ください。
            </p>

            <button
              className="btn btn-primary w-full my-10"
              disabled={fabricSamples.length === 0 || fabricSamples.length > 5}
            >
              <Link to="/fabric-sample-form" className="w-full">
                選択した生地でお客様情報の入力に移る
              </Link>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default FabricSampleScreen
