import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useGetTailorTshirtsQuery } from '../../store/apis/tailorTshirtsApi'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import TailorIndexItem from '../../components/TailorIndexItem'
import Meta from '../../components/Meta'

const TailorReaptOrderScreen = () => {
  const [filteredItems, setFilteredItems] = useState([])
  const { tailorRepeat } = useSelector((state) => state.tailorSpec)
  const { data: tailorTshirts, isLoading, error } = useGetTailorTshirtsQuery()

  useEffect(() => {
    if (tailorTshirts) {
      if (tailorRepeat.type === 'Tシャツ') {
        const filteredType = tailorTshirts.filter(
          (item) => item.type === 'T-shirts'
        )
        return setFilteredItems(filteredType)
      } else {
        const filteredType = tailorTshirts.filter(
          (item) => item.type === 'Long T-shirts'
        )
        return setFilteredItems(filteredType)
      }
    }
  }, [tailorTshirts, tailorRepeat.type])

  return (
    <>
      <Meta title="テイラーTシャツ追加注文 | m&s Fabric" />
      <div data-theme="black" className="flex justify-center grow">
        <div className="screen-container mt-5 mb-14 w-full">
          <div className="text-sm breadcrumbs text-gray-500 mb-10">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/profile">My Page</Link>
              </li>
              <li>Tailor T-shirts Reorder</li>
            </ul>
          </div>
          {isLoading ? (
            <Loader />
          ) : error ? (
            <Message variant={'alert-error'}>{error.data.message}</Message>
          ) : (
            <>
              <h2 className="page-title text-white">
                {tailorRepeat.type === 'Tシャツ'
                  ? 'Tailor T-shirts'
                  : 'Tailor T-shirts Long Sleeve'}
              </h2>
              <p className="page-description">
                オーダーサイズを使用して追加注文
              </p>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {filteredItems.map((item) => (
                  <TailorIndexItem item={item} key={item._id} />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default TailorReaptOrderScreen
