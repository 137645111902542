import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { changeCustomSlideState } from '../../store/slices/customSlideSlice'

const CustomEmbroidery = ({
  changeEmbroidery,
  selectedValue,
  selectedColor,
}) => {
  const [selectedEmbroidery, setSelectedEmbroidery] = useState(
    selectedValue.selectedEmbroidery
  )
  const [inputedChar, setInputedChar] = useState('')
  const [displayChar, setDisplayChar] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch()
  const [fabricColor, setFabricColor] = useState('')
  const [embroideryColor, setEmbroideryColor] = useState('シャンパンゴールド')
  const [isDisplayed, setIsDisplayed] = useState(false)

  useEffect(() => {
    if (selectedColor === 'ホワイト') {
      return setFabricColor('#ffffff')
    } else if (selectedColor === 'ブラック') {
      return setFabricColor('#000000')
    } else if (selectedColor === 'シルバーグレー') {
      return setFabricColor('#aaaaaa')
    } else if (selectedColor === 'チャコールグレー') {
      return setFabricColor('#484442')
    } else if (selectedColor === 'インディゴブルー') {
      return setFabricColor('#263442')
    } else if (selectedColor === 'モスピンク') {
      return setFabricColor('#fdcfcf')
    } else if (selectedColor === 'ホワイトスモーク') {
      return setFabricColor('#e7dfdc')
    } else {
      return setFabricColor('#ffffff')
    }
  }, [selectedColor])

  const changeTextHandler = () => {
    if (!inputedChar.match(/^[A-Za-z0-9 /. /&]*$/)) {
      setInputedChar('')
      setErrorMessage(
        '全角または「.」「&」以外の記号や対象外の文字が含まれています'
      )
    } else if (!inputedChar.match(/\S/g)) {
      setInputedChar('')
      setErrorMessage('文字を入力してください。')
    } else {
      setDisplayChar(inputedChar.trim())
      setSelectedEmbroidery({
        ...selectedEmbroidery,
        embroideryChar: inputedChar,
      })
      setIsDisplayed(true)
      setErrorMessage('')
    }
  }

  const changeEmbroideryHandler = () => {
    if (selectedEmbroidery.isEmbroidery === '有り' && inputedChar === '') {
      return setErrorMessage('文字を入力してください')
    }
    changeEmbroidery(
      { ...selectedEmbroidery, embroideryColor },
      selectedEmbroidery.isEmbroidery === '無し' ? 0 : 1650
    )
    setErrorMessage('')
    dispatch(changeCustomSlideState())
  }
  return (
    <div className="w-full h-full pb-28 lg:pb-10 overflow-y-auto">
      <div className="flex flex-col justify-center mb-10">
        <div className="flex flex-col xl:flex-row items-center gap-5 xl:gap-10">
          <div className="xl:basis-1/2">
            <img
              src="/images/custom_items/embroidery_img.jpg"
              alt="Tシャツ刺繍イメージ"
            />
          </div>
          <div className="xl:basis-1/2 flex-col">
            <p className="text-sm mb-5">
              よりオリジナリティの高まる刺繍を用いた名入れオプションです。
            </p>
            <div>
              <input
                type="radio"
                name="tag"
                id="noembroidery"
                value="無し"
                checked={selectedEmbroidery.isEmbroidery === '無し'}
                className="hidden"
                onChange={(e) =>
                  setSelectedEmbroidery({
                    ...selectedEmbroidery,
                    isEmbroidery: e.target.value,
                  })
                }
              />
              <label
                className={`btn btn-sm btn-outline rounded-full border-gray-400 w-full mb-2 ${
                  selectedEmbroidery.isEmbroidery === '無し' &&
                  'bg-black text-white'
                }`}
                htmlFor="noembroidery"
              >
                名入れしない
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="tag"
                id="embroidery"
                value="有り"
                checked={selectedEmbroidery.isEmbroidery === '有り'}
                className="hidden"
                onChange={(e) =>
                  setSelectedEmbroidery({
                    ...selectedEmbroidery,
                    isEmbroidery: e.target.value,
                  })
                }
              />
              <label
                className={`btn btn-sm btn-outline rounded-full border-gray-400 w-full ${
                  selectedEmbroidery.isEmbroidery === '有り' &&
                  'bg-black text-white'
                }`}
                htmlFor="embroidery"
              >
                名入れする（+1,650円）
              </label>
            </div>
          </div>
        </div>

        {selectedEmbroidery.isEmbroidery === '有り' && (
          <div className="w-full mt-10">
            <label className="form-control w-full xl:px-28 mb-2">
              <div className="label">
                <span className="label-text">
                  文字を入力して、サンプル表示ボタンを押してください
                </span>
              </div>
              <div className="join">
                <input
                  type="text"
                  placeholder="半角英数字・最大20文字"
                  className="input input-bordered w-full"
                  value={inputedChar}
                  maxLength="20"
                  onChange={(e) => setInputedChar(e.target.value)}
                />
                <button
                  onClick={changeTextHandler}
                  className="btn join-item rounded"
                >
                  サンプル表示
                </button>
              </div>
              {errorMessage && (
                <p className="text-sm text-red-500 mt-3">{errorMessage}</p>
              )}
            </label>

            {/* 刺繍カラー */}
            <div className="mt-5 flex flex-col mb-10 md:flex-row items-start md:items-center md:justify-center">
              <span className="text-sm mr-3 mb-3 font-bold md:mb-0">
                刺繍カラー：
              </span>
              <div className="flex flex-wrap">
                <div className="form-control mr-3">
                  <label className="label cursor-pointer">
                    <input
                      type="radio"
                      name="embroidery-color"
                      className="radio checked:bg-[#c9ae94] mr-1 radio-sm"
                      value="シャンパンゴールド"
                      onChange={(e) => setEmbroideryColor(e.target.value)}
                      checked={embroideryColor === 'シャンパンゴールド'}
                    />
                    <span className="label-text">シャンパンゴールド</span>
                  </label>
                </div>
                <div className="form-control mr-3">
                  <label className="label cursor-pointer">
                    <input
                      type="radio"
                      name="embroidery-color"
                      className="radio checked:bg-gray-400 mr-1 radio-sm"
                      value="シルバーグレー"
                      onChange={(e) => setEmbroideryColor(e.target.value)}
                      checked={embroideryColor === 'シルバーグレー'}
                    />
                    <span className="label-text">シルバーグレー</span>
                  </label>
                </div>
                <div className="form-control mr-3">
                  <label className="label cursor-pointer">
                    <input
                      type="radio"
                      name="embroidery-color"
                      className="radio checked:bg-gray-200 mr-1 radio-sm"
                      value="ホワイト"
                      onChange={(e) => setEmbroideryColor(e.target.value)}
                      checked={embroideryColor === 'ホワイト'}
                    />
                    <span className="label-text">ホワイト</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="w-full">
              <div
                className="pt-12 pb-10 h-32 border border-neutral-300"
                style={{ backgroundColor: `${fabricColor}` }}
              >
                <p
                  className="text-center text-4xl xl:text-5xl pinyon-script-regular"
                  style={{
                    color: `${
                      embroideryColor === 'シャンパンゴールド'
                        ? '#c9ae94'
                        : embroideryColor === 'シルバーグレー'
                        ? '#9ca3af'
                        : '#eee'
                    }`,
                  }}
                >
                  {displayChar}
                </p>
              </div>
              <p className="text-sm mt-2 mb-1">
                刺繍の位置は裾の左下になります。また表示の書体と実際の書体は多少異なります。
              </p>
              <p className="text-xs">
                ※デバイスやモニターによって色味は異なります。
              </p>
            </div>
          </div>
        )}
      </div>

      <div className="w-full text-center">
        <button
          onClick={changeEmbroideryHandler}
          className="btn btn-primary btn-sm w-full md:w-72"
          disabled={
            selectedEmbroidery.isEmbroidery === '有り' && !isDisplayed
              ? true
              : false
          }
        >
          決定する
        </button>
      </div>
    </div>
  )
}

export default CustomEmbroidery
