import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { changeCustomSlideState } from '../../store/slices/customSlideSlice'

const CustomLib = ({ changeSleeveLib, selectedValue, type }) => {
  const [selectedSleeveLib, setSelectedSleeveLib] = useState(
    selectedValue.selectedSleeveLib
  )
  const dispatch = useDispatch()

  const changeSleeveLibHandler = () => {
    changeSleeveLib(
      selectedSleeveLib,
      selectedSleeveLib === 'リブ付き' ? 990 : 0
    )
    dispatch(changeCustomSlideState())
  }
  return (
    <div className="w-full overflow-y-auto h-full flex items-center pb-28 lg:pb-10">
      <div className="w-full">
        <div className="flex items-center justify-center mb-10">
          <div className="flex gap-3 w-full">
            <div className="basis-1/2 flex flex-col items-center">
              {type === 'T-shirts' ? (
                <img
                  src="/images/custom_items/sleeveLib_none.svg"
                  alt="Tシャツ袖リブ無し"
                  className="mb-5"
                />
              ) : (
                <img
                  src="/images/custom_items/sleeveLib_long_none.svg"
                  alt="Tシャツ袖リブ無し"
                  className="mb-5"
                />
              )}

              <p className="text-sm text-center mb-5">内側に折り返した袖口</p>
              <input
                type="radio"
                name="sleeveLib"
                id="none"
                value="リブ無し"
                checked={selectedSleeveLib === 'リブ無し'}
                className="hidden"
                onChange={(e) => setSelectedSleeveLib(e.target.value)}
              />
              <label
                className={`btn btn-sm btn-outline rounded-full border-gray-400 w-full xl:w-64 ${
                  selectedSleeveLib === 'リブ無し' && 'bg-black text-white'
                }`}
                htmlFor="none"
              >
                リブ無し
              </label>
            </div>
            <div className="basis-1/2 flex flex-col items-center">
              {type === 'T-shirts' ? (
                <img
                  src="/images/custom_items/sleeveLib_add.svg"
                  alt="Tシャツ袖リブ付き"
                  className="mb-5"
                />
              ) : (
                <img
                  src="/images/custom_items/sleeveLib_long_add.svg"
                  alt="Tシャツ袖リブ付き"
                  className="mb-5"
                />
              )}
              <p className="text-sm text-center mb-5">2cm幅のリブ付きの袖口</p>
              <input
                type="radio"
                name="sleeveLib"
                id="add"
                value="リブ付き"
                checked={selectedSleeveLib === 'リブ付き'}
                className="hidden"
                onChange={(e) => setSelectedSleeveLib(e.target.value)}
              />
              <label
                className={`btn btn-sm btn-outline rounded-full border-gray-400 w-full xl:w-64 ${
                  selectedSleeveLib === 'リブ付き' && 'bg-black text-white'
                }`}
                htmlFor="add"
              >
                リブ付き （+990円）
              </label>
            </div>
          </div>
        </div>
        <div className="w-full text-center">
          <button
            className="btn btn-primary btn-sm w-72"
            onClick={changeSleeveLibHandler}
          >
            決定する
          </button>
        </div>
      </div>
    </div>
  )
}

export default CustomLib
