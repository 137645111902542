import { useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import gsap from 'gsap'
import Meta from '../components/Meta'

const TailorTshirtsScreen = () => {
  const navigate = useNavigate()
  const comp = useRef()

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.fromTo(
        '.g-bg',
        {
          opacity: 1,
          ease: 'power3',
          duration: 5,
        },
        {
          opacity: 0.3,
          ease: 'power3',
          duration: 5,
        }
      )
      gsap.from('.g-items', {
        delay: 2,
        opacity: 0,
        ease: 'power2',
        duration: 7,
      })
    }, comp)
    return () => ctx.revert()
  }, [])

  const nextStepHandler = () => {
    navigate('/tailor-tshirts/body')
  }

  const toMyPageHandler = () => {
    navigate('/login?redirect=/profile')
  }

  return (
    <>
      <Meta title="テイラーTシャツ | m&s Fabric" />
      <div data-theme="black" className="flex justify-center grow">
        <div className="lg:screen-container mt-5 w-full">
          <div ref={comp} className="h-full bg-tailor-main bg-cover bg-center">
            <div className="relative h-full flex flex-col items-center justify-center pb-14">
              <div className="g-bg absolute top-0 left-0 bg-black w-full h-full"></div>
              <div className="g-items z-10 flex flex-col items-center text-center">
                <h1 className="text-8xl lg:text-9xl font-bold italic mb-5 mt-20">
                  Tailor{' '}
                  <span className="lg:hidden">
                    <br />
                  </span>
                  T-shirts
                </h1>
                <h2 className="text-2xl lg:text-3xl font-bold italic tracking-wider">
                  世界で一枚のフルオーダー
                  <span className="lg:hidden">
                    <br />
                  </span>
                  Tシャツをあなたに
                </h2>
                <p className="container text-sm leading-6 mt-10 text-center lg:text-base lg:leading-4 tracking-wider">
                  テイラーTシャツはお客様の体型や好みに合わせてTシャツお仕立てするサービスです。
                  <span className="hidden lg:block">
                    <br />
                  </span>
                  <span className="hidden lg:block">
                    ボディサイズやお気に入りのTシャツのサイズを採寸いただければ、ご希望のサイズのTシャツをお作りいたします。
                  </span>
                  <span className="hidden lg:block">
                    <br />
                  </span>
                  サンプルを試着して実際のサイズを確認できますので、仕上がりが不安の方も安心です。
                </p>
                <p className="mt-10 text-xs">
                  初回試着サンプル込み：
                  <span className="text-xl font-bold mr-1">15,950</span>
                  円（税込）〜
                </p>
                <p className="text-xs">
                  追加購入：
                  <span className="text-xl font-bold mr-1">8,250</span>
                  円（税込）〜
                </p>
                <button
                  onClick={nextStepHandler}
                  className="w-72 lg:w-80 stepbtn mt-10 lg:mt-14 text-white py-3 px-20 border border-gray-500 transition hover:border-gray-300 tracking-wider hover:bg-black"
                >
                  Tシャツを作る
                </button>
                <button
                  onClick={toMyPageHandler}
                  className="text-sm mt-5 font-normal hover:border-b hover:border-b-white"
                >
                  追加購入は「マイページ」 <span className="mx-1">{'>'}</span>{' '}
                  追加購入タブ から
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TailorTshirtsScreen
