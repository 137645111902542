import { Link } from 'react-router-dom'
import { FaArrowDown } from 'react-icons/fa6'
import Meta from '../components/Meta'

const TryonScreen = () => {
  return (
    <>
      <Meta title="Tシャツ試着サービスについて | m&s Fabric" />
      <div className="flex justify-center grow">
        <div className="screen-container mt-5 mb-14 w-full">
          <div className="text-sm breadcrumbs text-gray-500 mb-10">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>試着サービスについて</li>
            </ul>
          </div>
          <div className="container">
            <h2 className="page-title">About Try-on Service</h2>
            <p className="page-description">試着サービスについて</p>
            <div className="mb-14">
              <h3 className="text-2xl/10 mb-10">
                採寸通りがベストなサイズとは限りません。着用して初めて分かる事があります
              </h3>
              <img className="mb-7" src="/images/tryon_img.jpg" alt="" />
              <div className="flex flex-col md:gap-10 w-full md:flex-row">
                <div className="w-full md:w-1/2">
                  <p className="text-sm/6 mb-3">
                    採寸した通りの寸法で作れば身体に合ったTシャツは作れます。しかし身体に合わせただけのものがお客様にとってベストのTシャツになるとは限りません。
                  </p>
                  <p className="text-sm/6 mb-3">
                    体型とは違うスタイルのTシャツを好む方もいますし、こだわりを追求したりコンプレックスを解消するためにオリジナルのサイズを希望する方など一人ひとり仕様が異なります。そのため本当のオーダーTシャツの製作とは体型に合わせた所から始まると当店は考えています。
                  </p>
                </div>
                <div className="w-full md:w-1/2">
                  <p className="text-sm/6 mb-3">
                    Tシャツに限らずオンラインでのオーダーメイド商品は採寸データを元に算出したサイズで納品するという流れが一般的だと思いますが、商品が届くまで仕上がりに不安を感じたり、実際に着用したら期待していたものと違かったりと中々満足するものにならなかったというような声は良く聞きます。
                  </p>
                  <p className="text-sm/6 mb-3">
                    そのような中で、当店ではお客様にベストなTシャツを提供するために、試着サービスを最も重要な工程と位置付けています。
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center justify-center mb-20">
              <div className="w-full md:w-[768px]">
                <h3 className="text-center bg-black text-white p-3 mb-10">
                  テイラーTシャツの受注から納品までの流れ
                </h3>
                <div className="mb-5">
                  <div className="flex flex-col gap-5 mb-5 md:flex-row">
                    <div className="w-full md:w-60 bg-gray-200">
                      <img
                        src="/images/tryon-pattern.jpg"
                        alt="Tシャツパターン作成"
                      />
                    </div>
                    <div className="flex-1">
                      <h4 className="text-lg border-b border-gray-500 pb-2 mb-5">
                        注文内容を元にサンプルTシャツを製作
                      </h4>
                      <p className="text-sm/6">
                        ご注文時にいただいた採寸データを元にお客様専用の型紙を作成し、本商品と同じ生地で試着用のサンプルTシャツを製作します。（サンプルTシャツの色はホワイトになります）
                      </p>
                    </div>
                  </div>
                  <p className="md:text-center text-sm border border-gray-300 p-2">
                    サンプルTシャツはサイズ確認が目的のため、簡易縫製で製作いたします
                  </p>
                </div>
                <FaArrowDown className="w-full flex justify-center mb-5 md:mb-10" />
                <div className="mb-5">
                  <div className="flex flex-col gap-5 mb-5 md:flex-row">
                    <div className="w-full md:w-60 bg-gray-200">
                      <img
                        src="/images/tryon-sample.jpg"
                        alt="Tシャツサンプル送付"
                      />
                    </div>
                    <div className="flex-1">
                      <h4 className="text-lg border-b border-gray-500 pb-2 mb-5">
                        サンプルTシャツを発送・お届け
                      </h4>
                      <p className="text-sm/6">
                        サンプルTシャツが完成しましたら専用パックにてお届けします。到着しましたら内容のご確認をお願いします。
                      </p>
                    </div>
                  </div>
                  <p className="text-center text-sm border border-gray-300 p-2">
                    サンプルTシャツは返却の必要はありません
                  </p>
                </div>
                <FaArrowDown className="w-full flex justify-center mb-5 md:mb-10" />
                <div className="mb-5">
                  <div className="flex flex-col gap-5 mb-5 md:flex-row">
                    <div className="w-full md:w-60 bg-gray-200">
                      <img
                        src="/images/tryon-fit.jpg"
                        alt="Tシャツサンプル試着"
                      />
                    </div>
                    <div className="flex-1">
                      <h4 className="text-lg border-b border-gray-500 pb-2 mb-5">
                        試着・サイズ確認（洗濯）
                      </h4>
                      <p className="text-sm/6">
                        実際にサンプルTシャツをご試着いただき、同封の案内を参考に各箇所のサイズをご確認ください。Tシャツ生地は洗濯すると少なからず縮みますので、必ずいつもと同じ環境で一度洗濯してください。
                      </p>
                    </div>
                  </div>
                  <p className="text-center text-sm border border-gray-300 p-2">
                    一度洗濯いただくと洗濯後の縮みを考慮した上でサイズ修正をする事ができます
                  </p>
                </div>
                <FaArrowDown className="w-full flex justify-center mb-5 md:mb-10" />
                <div className="mb-5">
                  <div className="flex flex-col gap-5 mb-5 md:flex-row">
                    <div className="w-full md:w-60 bg-gray-200">
                      <img
                        src="/images/tryon-form.jpg"
                        alt="Tシャツサンプル試着"
                      />
                    </div>
                    <div className="flex-1">
                      <h4 className="text-lg border-b border-gray-500 pb-2 mb-5">
                        チェックフォームから返信
                      </h4>
                      <p className="text-sm/6">
                        試着して希望のサイズがイメージできましたら、マイページにログインいただき、「フォーム」タブから修正用のチェックフォームを開き、修正が必要な箇所に希望の数値を入力して返信してください。
                      </p>
                    </div>
                  </div>
                  <p className="text-center text-sm border border-gray-300 p-2">
                    日常生活を考慮して、ゆとりのあるサイズを設定ください
                  </p>
                </div>
                <FaArrowDown className="w-full flex justify-center mb-5 md:mb-10" />
                <div className="mb-5">
                  <div className="flex flex-col gap-5 mb-5 md:flex-row">
                    <div className="w-full md:w-60 bg-gray-200">
                      <img
                        src="/images/tryon-deliver.jpg"
                        alt="Tシャツサンプル試着"
                      />
                    </div>
                    <div className="flex-1">
                      <h4 className="text-lg border-b border-gray-500 pb-2 mb-5">
                        商品を製作・発送
                      </h4>
                      <p className="text-sm/6">
                        チェックフォームからの返信内容を反映し、商品用のTシャツを製作します。納品はチェックフォームの返信後、土日を除く10日程のお時間をいただいております。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TryonScreen
