import { Link } from 'react-router-dom'
import { ImArrowRight } from 'react-icons/im'
import { FaArrowDown } from 'react-icons/fa6'
import Meta from '../components/Meta'

const OrderTshirtsScreen = () => {
  return (
    <>
      <Meta title="オーダーTシャツについて | m&s Fabric" />
      <div className="flex justify-center grow">
        <div className="screen-container mt-5 mb-14 w-full">
          <div className="text-sm breadcrumbs text-gray-500 mb-10">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>オーダーTシャツについて</li>
            </ul>
          </div>
          <div className="container">
            <h2 className="page-title">About Order T-shirts</h2>
            <p className="page-description">オーダーTシャツについて</p>
            <div className="border-b border-neutral-300 mb-14 pb-14 lg:mb-20 lg:pb-20">
              <h3 className="text-2xl/10 mb-10">
                オーダーTシャツはお客様のスタイルに合わせた美しいラインを最大限に引き出します
              </h3>
              <div className="flex flex-col gap-10 w-full md:flex-row">
                <div className="w-full md:w-1/2">
                  <figure className="relative mb-3">
                    <img
                      src="/images/order-general.jpg"
                      alt="一般的なTシャツ"
                    />
                    <figcaption className="absolute bottom-3 left-4 text-white text-xs py-1 px-3 bg-black">
                      一般的なTシャツ
                    </figcaption>
                  </figure>
                  <p className="text-sm mb-2">
                    こちらが一般的に量産されているTシャツを着用した場合のシルエットです。
                  </p>
                  <p className="text-sm">
                    全体として肩幅や袖幅、着丈や着幅のバランスが悪いように感じます。オーバーサイズとして着用するには着丈や袖丈が短く、着幅も中途半端なサイズ感となっており、大人カジュアル的に着用するにしても肩幅と袖幅が広すぎるサイズとなっています。
                  </p>
                </div>
                <div className="w-full md:w-1/2">
                  <figure className="relative mb-3">
                    <img src="/images/order-ms.jpg" alt="オーダーTシャツ" />
                    <figcaption className="absolute bottom-3 left-4 text-white text-xs py-1 px-3 bg-black">
                      オーダーTシャツ
                    </figcaption>
                  </figure>
                  <p className="text-sm mb-2">
                    こちらの写真は同じモデルのスタイルに合わせて製作したオーダーTシャツです。
                  </p>
                  <p className="text-sm">
                    体のサイズに合わせて作られているため、体型に合った綺麗なシルエットを実現し、全体的にスッキリしているのが分かるかと思います。お好みにより首回りの広さやネックの形状、着丈や袖丈の長さ、着幅や裾幅など細かく変更することができます。
                  </p>
                </div>
              </div>
            </div>

            <div className="border-b border-neutral-300 mb-14 pb-14 lg:mb-20 lg:pb-20">
              <h3 className="text-2xl/10 mb-14">
                快適な着心地を実現するために、天然素材にこだわり細かいサイズ設定をしています
              </h3>
              <div className="flex flex-col gap-10 w-full md:flex-row">
                <div className="w-full md:w-1/2">
                  <div className="mb-10">
                    <h4 className="text-lg mb-5">
                      ■ 直接肌に触れるものだから肌への配慮を最優先にしています
                    </h4>
                    <p className="text-sm/6 mb-3">
                      当店のTシャツは天然素材の綿をメインに使用しています。綿の最大のメリットは何と言っても肌触りが良いことです。Tシャツは直接肌に触れるアイテムですので、どんなに形やデザインが良くても着ていてチクチクするとか、痒くなるなどの症状があると着用するのは難しいと思います。
                    </p>
                    <p className="text-sm/6 mb-3">
                      ここ最近はポリエステルやナイロンなどの化学繊維が安価で速乾性能や伸縮性などの高機能を持ち合わせていることで多く流通していますが、【化繊負け】と言われるかゆみ、かぶれ、湿疹、接触性皮膚炎などの症状が出ることがあります。当店ではその予防として天然素材の綿を基本として、綿生地のデメリットを補うもしくは機能を追加する目的のみに限り化繊生地を使用しています。必ず化繊負けになりにくい綿との混合とし、化学繊維100%の生地は使用しない事としています。
                    </p>
                  </div>
                  <div className="mb-10">
                    <h4 className="text-lg mb-5">
                      ■ SDGs 目標12「つくる責任」に取り組んでいます
                    </h4>
                    <img
                      className="w-28 float-left mr-5"
                      src="/images/sdg_icon_12.jpeg"
                      alt="SDGs"
                    />
                    <p className="text-sm/6 mb-3">
                      昨今のファストファッションの台頭によって価格競争が加熱し、その結果大量生産・大量処分という悪循環が生まれています。当店ではSDGsの目標12の「つくる責任」に取り組み、全ての商品を受注生産としています。むやみに安価な商品を大量に購入するのではなく、自分にあったこだわりの商品を選び、長く着用して欲しいとの思いでオーダーメイドにこだわって活動しています。
                    </p>
                    <p className="text-sm/6 mb-3">
                      また環境汚染の観点からも化学繊維は出来るだけ使用しないこととしています。洗濯時に化学繊維からマイクロファイバー抜け落ち、海へ流れ海洋汚染につながる問題があるため、当店では天然素材の綿を使用しています。
                    </p>
                  </div>
                  <div>
                    <h4 className="text-lg mb-5">
                      ■ 着用による変形を考慮したサイズ設定をしています
                    </h4>
                    <p className="text-sm/6 mb-3">
                      Tシャツ生地には編み目の向きがあります。通常の天竺素材であれば縦目と横目があり、横目が伸びやすく縦目が伸びにくい仕様となっております。生地はループ状に編まれているので、必然的に横目が伸びれば縦目が縮まってきます。
                    </p>
                    <p className="text-sm/6 mb-3">
                      通常の生活では上下運動よりも左右の動きが多いため、普段Tシャツを着用していると傾向として着幅が広がり、着丈が縮まる事が多いかと思います。
                    </p>
                    <p className="text-sm/6 mb-3">
                      そこで横に伸びやすく縦が縮まる傾向があることを考慮して、当店では予め着丈を長めに設定しています。着丈はTシャツの中でもっともストロークが長い分、縮みの影響を受けやすい箇所です。予め着丈を長めに設定する事で洗濯による縮みや、日常生活での縦目の縮小を軽減する事ができると考えております。
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-1/2">
                  <h4 className="text-lg mb-5">
                    ■ 当店規定のTシャツの採寸方法
                  </h4>
                  <p className="text-sm/6 mb-3">
                    当店では下記の基準で各サイズを採寸しております。テイラーTシャツ・カスタマイズTシャツ共通ですのでサイズ確認の際にご参考ください。
                  </p>
                  <img
                    className="mb-3"
                    src="/images/aboutOrderTshirts_chart.svg"
                    alt=""
                  />
                  <p className="text-sm/6 mb-3">
                    上記イラスト通りに各サイズを計測しております。着丈はネック後ろの一番低い箇所から裾までとしています。着幅の計測位置はシワになりやすいので、袖を上に上げた状態で計測しています。ウエストがシェイプしている場合は一番細い部分としています。
                  </p>
                  <p className="text-sm/6 mb-3">
                    お客様ご自身で計測する際は、Tシャツによれやシワが無いように平らな場所で採寸してください。
                  </p>
                </div>
              </div>
            </div>

            <div className="border-b border-neutral-300 mb-14 pb-14 lg:mb-20 lg:pb-20">
              <h3 className="text-2xl/10 mb-14">
                100%のTシャツを目指してパターンから裁断、縫製まで全て自社で行っております。
              </h3>
              <div className="flex flex-col gap-10 w-full md:flex-row">
                <div className="w-full md:w-1/2">
                  <div className="mb-10">
                    <h4 className="text-lg mb-5">■ お客様一人ひとりのために</h4>
                    <p className="text-sm/6 mb-3">
                      お客様一人ひとりのご要望に沿ったTシャツを作成するために、当店ではTシャツ製作に関わる全ての工程を自社で行っております。
                    </p>
                    <p className="text-sm/6 mb-3">
                      自社で一枚一枚生産することで、大量生産では出来ないお客様の細かいニーズに答えることができ、業界初の試着サービスやお直しサービスなどの他店にはないサービスを実現することができました。
                    </p>
                  </div>
                  <div>
                    <h4 className="text-lg mb-5">
                      ■ Tシャツもオーダーメイドの時代へ
                    </h4>
                    <p className="text-sm/6 mb-3">
                      市販のTシャツの数種類しかないサイズの中で、どれだけの人が自分の体に合ったTシャツを着用しているのでしょうか？
                    </p>
                    <p className="text-sm/6 mb-3">
                      伸び縮みするTシャツだからそれなりに着用できてしまいますが、オーダーメイドでTシャツを作ることで得られるフィット感や着心地、体のラインに合ったシルエットの美しさなどは市販のTシャツでは味わえないものがあります。
                    </p>
                    <p className="text-sm/6 mb-3">
                      私たちはオーダーTシャツの素晴らしさを多くの人に知ってもらうために日々活動しております。
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-1/2">
                  <h4 className="text-lg mb-5">■ 製作から納品まで</h4>
                  <p className="text-sm/6 mb-3">
                    テイラーTシャツをご注文いただいてから、まずはお送りいただいた情報を元にお客様の体型や好みを考慮しながらパターン（型紙）を作成していきます。次にパターンを元にご注文と同じ生地を使用して裁断・縫製し、サンプルTシャツを製作します。
                  </p>
                  <p className="text-sm/6 mb-3">
                    この段階で注文通りの寸法と希望通りのデザインになっているかの確認をし、修正が必要な箇所があれば再度調整していきます。お客様のご要望に合ったと判断できるサンプルTシャツが完成しましたらお客様にお送り致します。
                  </p>
                  <p className="text-sm/6 mb-3">
                    お客様にサンプルTシャツをご試着いただき、希望通りのサイズ感になっていれば商品用Tシャツの製作を開始します。変更したい箇所があれば再度パターンを修正します。アパレル業界で長年の経験のある熟練した職人が心を込めて一点ずつ製作していますので、細部にまでこだわった妥協のない商品として自信を持ってお届けしております。
                  </p>
                </div>
              </div>
            </div>

            <div className="pb-14 lg:pb-20">
              <h3 className="text-2xl/10 mb-14">
                洗濯や乾燥などのメンテナンスの仕方でTシャツの着心地やサイズ感は維持できます
              </h3>
              <div className="flex flex-col gap-10 w-full md:flex-row">
                <div className="w-full md:w-1/2">
                  <div className="mb-10">
                    <h4 className="text-lg mb-5">
                      ■ 洗濯は手洗いをオススメしています
                    </h4>
                    <p className="text-sm/6 mb-3">
                      洗濯方法について当店では生地の劣化がもっとも少ない手洗いをお勧めしております。
                    </p>
                    <p className="text-sm/6 mb-3">
                      Tシャツが入る大きさのある容器に水と洗剤を入れ、浸して軽くすすいでください。洗濯機を使用する場合は洗濯ネットを使用することでTシャツにかかる負担を軽くすることができます。毛羽立ちや劣化の原因の一つは洗濯時の摩擦にありますので洗濯機を使用する場合はもちろん、手洗い時もTシャツを裏返しにしてから洗うようにしてください。
                    </p>
                    <p className="text-sm/6 mb-3">
                      濃い色（ブラックやネイビー）のTシャツは洗濯の環境により色落ちしてしまうことがあります。白い素材のものや色が付着しやすい衣類との洗濯は避けるようにしてください。同様にデニムなどの色落ちする物と一緒に洗うと染料により変色してしまいますのでご注意願います。また漂白剤の入った洗剤は生地を傷めてしまったり、色むらの原因となりますので使用しないでください。
                    </p>
                    <img src="/images/howtoDry.svg" alt="Tシャツの干し方" />
                  </div>
                  <div className="mb-10">
                    <h4 className="text-lg mb-5">
                      ■ 乾燥機は使用しないでください
                    </h4>
                    <p className="text-sm/6 mb-3">
                      ドライクリーニングや乾燥機を使用すると、自然乾燥に比べて極端に生地が縮んでしまう可能性があります。さらに生地を傷めてしまうこともありますので、乾燥機は使用しないでください。
                    </p>
                  </div>
                  <div className="mb-10">
                    <h4 className="text-lg mb-5">■ Tシャツの保管方法</h4>
                    <p className="text-sm/6 mb-3">
                      Ｔシャツの保管は、シワがつかないようにたたんでタンスなどに収納することをお勧めします。ハンガーを使用すると型崩れの原因になりますのでご注意ください。直射日光に長時間さらすと変色することがあるので、日光があたらないような場所で保管してください。
                    </p>
                  </div>
                  <div>
                    <h4 className="text-lg mb-5">
                      ■ 化学繊維生地のメンテナンスと取り扱いについて
                    </h4>
                    <p className="text-sm/6 mb-3">
                      化繊生地はサイズ変化が少なくシワのつきにくいのが特徴の素材です。洗濯後、きれいに整えて干すことで変形やシワの少ない状態でご着用いただけます。シワが出来てしまいアイロン掛けをする際はあて布を使用して適正な温度で使用してください。化繊の生地はアイロン温度により生地焼けやテカリが起こる可能性がございますのでご注意ください。
                    </p>
                    <p className="text-sm/6 mb-3">
                      また化繊生地は非常に伸縮性がありますので生地を大きく伸ばしたり、強い力を加えてしまうと糸切れを起こしてしまう恐れがございます。一般的な着用では問題ありませんが故意に生地を伸ばすなど無理な力を加える行為はしないでください。
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-1/2">
                  <div className="mb-10">
                    <h4 className="text-lg mb-5">
                      ■ 綿素材のTシャツのシワやヨレはスチームでリセット
                    </h4>
                    <p className="text-sm/6 mb-3">
                      綿素材は肌触りの良さや通気性や吸水性に優れるなどのメリットがある反面、縮みやすくシワが付いたりヨレやすいなどのデメリットもあります。当店取り扱いの綿100%素材のTシャツも同様に洗濯後はシワやヨレが起こります。
                    </p>
                    <p className="text-sm/6 mb-3">
                      洗濯後についたシワやヨレをはスチームアイロンでリセットできます。市販のスチームアイロンで軽く整えるだけで簡単にシワやヨレを解消することができます。
                    </p>
                    <p className="text-sm/6 mb-3">
                      またスチームアイロンでシワやヨレを取り除いた後にスチームをオフにして生地の湿気がなくなるまでアイロンをかけるとシワのない状態を長く保つことができます。
                    </p>
                    <p className="text-sm/6 mb-3">
                      スチームアイロンを使用する際は生地にスチームを軽く当てるように行なってください。Tシャツの生地とアイロン面が触る程度で使用するのが良いスチームのかけ方です。アイロンを強く押し付けると生地が変色したり変形することがあります。生地自体を傷めることにもなりますのでご注意ください。
                    </p>
                    <p className="text-sm/6 mb-3">
                      また取り扱いに関しましては、高温となりますので火傷や火災などに十分気を付けてご使用ください。
                    </p>
                    <div className="flex flex-col items-center lg:flex-row">
                      <figure className="relative mb-3 flex-1">
                        <img
                          src="/images/before_iron.jpg"
                          alt="Tシャツにアイロンをかける前"
                        />
                        <figcaption className="absolute bottom-2 left-2 text-white text-xs py-1 px-3 bg-black">
                          洗濯・乾燥直後の状態
                        </figcaption>
                      </figure>
                      <div className="mb-3 lg:hidden">
                        <FaArrowDown className="w-10 text-xl" />
                      </div>
                      <div className="hidden lg:block">
                        <ImArrowRight className="w-10 text-xl" />
                      </div>
                      <figure className="relative mb-3 flex-1">
                        <img
                          src="/images/after_iron.jpg"
                          alt="Tシャツにアイロンをかけた後"
                        />
                        <figcaption className="absolute bottom-2 left-2 text-white text-xs py-1 px-3 bg-black">
                          スチームアイロンでリセット
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                  <div className="mb-10">
                    <h4 className="text-lg mb-5">
                      ■ Tシャツが変形してしまったときは
                    </h4>
                    <p className="text-sm/6 mb-3">
                      当店では綿素材などの縮みやすい生地に対して縫製前にウォッシュ加工を施し、初回洗濯時に起こる生地の縮みを最小限に抑えるよう処理しております。しかし綿は天然素材ですので性質上、洗濯・乾燥の仕方や保管の方法などでサイズが変わってしまうことがあります。
                    </p>
                    <p className="text-sm/6 mb-3">
                      Tシャツのサイズ変わってしまった時は、洗濯後に吊り干しをする際、縮んでしまった向きとは逆の方向に少し伸ばしながら形を整えて干していただくと繊維の縮みが少しずつ戻り変形が解消される事があります。変形が気になる方は一度お試しください。
                    </p>
                  </div>
                  <div>
                    <h4 className="text-lg mb-5">
                      ■ Tシャツにシミや黄ばみができてしまったら
                    </h4>
                    <p className="text-sm/6 mb-3">
                      日常生活によるTシャツの襟やワキの下などの黄ばみや汚れは程度に応じて酸素系漂白剤でケアをしてください。漂白剤の種類によって効果が異なりますが、塩素系漂白剤は脱色（色はげ）などを起こしやすく生地が傷んでしまうのでご使用は避けて下さい。
                    </p>
                    <p className="text-sm/6 mb-3">
                      つけ置きをする場合は、約30～40℃のぬるま湯に粉末タイプの洗剤を溶かし入れ（1Lの水に5gが目安）、約30分のつけ置きが効果的です。シミが出来てしまった場合は、シミ抜きのプロ（クリーング店など）におまかせしましょう。黄ばみや汚れは汗などが主な原因ですが、収納時の高温多湿により発生しやすくなりますので出来るだけこのような条件を作らない工夫をすることも大切です。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderTshirtsScreen
