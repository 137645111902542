import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSendMailMutation } from '../store/apis/sendMailApi'
import { contactMail } from '../mail/contactMail'
import Meta from '../components/Meta'

const Contact = () => {
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [firstnameYomi, setFirstnameYomi] = useState('')
  const [lastnameYomi, setLastnameYomi] = useState('')
  const [email, setEmail] = useState('')
  const [comment, setComment] = useState('')

  const [sendMail, { isLoading }] = useSendMailMutation()
  const navigate = useNavigate()

  const msg = {
    to: email,
    from: 'info@mnsfabric.com',
    bcc: 'info@mnsfabric.com',
    subject: `${lastname} ${firstname} 様 お問い合わせありがとうございます`,
    text: contactMail({
      firstname,
      lastname,
      firstnameYomi,
      lastnameYomi,
      email,
      comment,
    }),
  }

  const sendMailHandler = async (e) => {
    e.preventDefault()

    try {
      await sendMail(msg)
      navigate('/')
      toast.success('お問い合わせ内容が送信されました')
    } catch (error) {
      toast.error('送信できませんでした')
    }
  }
  return (
    <>
      <Meta title="お問い合わせ | m&s Fabric" />
      <div className="flex justify-center grow">
        <div className="screen-container mt-5 mb-14 w-full">
          <div className="text-sm breadcrumbs text-gray-500 mb-10">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>お問い合わせ</li>
            </ul>
          </div>
          <div className="container mt-14 lg:mt-20 flex justify-center mb-24">
            <div className="w-full md:w-96 lg:w-1/2">
              <h2 className="page-title">Contact</h2>
              <form onSubmit={sendMailHandler}>
                <div className="form-control w-full mb-5">
                  <label className="label">
                    <span className="label-text">お名前</span>
                  </label>
                  <div className="flex gap-3">
                    <input
                      type="text"
                      placeholder="姓"
                      className="input input-bordered w-full"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                      pattern="[\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFF9F\u4E00-\u9FFF]*"
                      title="日本語で入力してください"
                      required
                    />
                    <input
                      type="text"
                      placeholder="名"
                      className="input input-bordered w-full"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                      pattern="[\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFF9F\u4E00-\u9FFF]*"
                      title="日本語で入力してください"
                      required
                    />
                  </div>
                </div>
                <div className="form-control w-full mb-5">
                  <label className="label">
                    <span className="label-text">お名前（フリガナ）</span>
                  </label>
                  <div className="flex gap-3">
                    <input
                      type="text"
                      placeholder="セイ"
                      className="input input-bordered w-full"
                      value={lastnameYomi}
                      onChange={(e) => setLastnameYomi(e.target.value)}
                      pattern="[\u30A1-\u30F6]*"
                      title="全角カタカナで入力してください"
                      required
                    />
                    <input
                      type="text"
                      placeholder="メイ"
                      className="input input-bordered w-full"
                      value={firstnameYomi}
                      onChange={(e) => setFirstnameYomi(e.target.value)}
                      pattern="[\u30A1-\u30F6]*"
                      title="全角カタカナで入力してください"
                      required
                    />
                  </div>
                </div>
                <div className="form-control w-full mb-5">
                  <label className="label">
                    <span className="label-text">メールアドレス</span>
                  </label>
                  <input
                    type="email"
                    className="input input-bordered w-full"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <label className="form-control">
                  <div className="label">
                    <span className="label-text">お問い合わせ内容</span>
                  </div>
                  <textarea
                    className="textarea textarea-bordered h-32"
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                    required
                  ></textarea>
                </label>

                {isLoading ? (
                  <button className="btn w-full mt-10">
                    <span className="loading loading-spinner"></span>
                    ローディング
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary w-full mt-10"
                  >
                    送信
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact
