import { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSendMailMutation } from '../store/apis/sendMailApi'
import { FaInstagram } from 'react-icons/fa'
import { newsletterInform } from '../mail/newsletterInform'

const Footer = () => {
  const currentYear = new Date().getFullYear()
  const [email, setEmail] = useState('')
  const [sendMail] = useSendMailMutation()

  const msg = {
    from: 'info@mnsfabric.com',
    template_id: 'd-edbc9876a5dc45238fe79b003ec3b3ee',
    personalizations: [
      {
        to: [{ email }],
        dynamic_template_data: { email },
      },
    ],
  }

  const inform = {
    to: 'info@mnsfabric.com',
    from: 'info@mnsfabric.com', // Use the email address or domain you verified above
    subject: `${email} よりニュースレターの登録がありました`,
    text: newsletterInform({
      email,
    }),
  }

  const newsLetterHandler = async (e) => {
    e.preventDefault()

    try {
      await sendMail(msg)
      await sendMail(inform)
      toast.success('ニュースレターのお申し込みを受け付けました')
    } catch (error) {
      toast.error('送信できませんでした')
    }
    setEmail('')
  }
  return (
    <footer data-theme="black" className="flex justify-center">
      <div className="screen-container pt-14 pb-10 text-white border-t border-gray-500 w-full">
        <div className="flex flex-col md:flex-row justify-between lg:items-center mb-10 lg:mb-14 border-b border-gray-500 pb-10 lg:pb-14">
          <div className="mb-10 md:mb-0 flex items-center justify-between">
            <div>
              <h2 className="text-3xl font-bold tracking-[1px] italic">
                m&s Fabric
              </h2>
              <p className="text-xs tracking-[1px] italic">
                ORDER T-SHIRTS SHOP
              </p>
            </div>
            <Link
              to="https://www.instagram.com/mnsfabric.tshirts/"
              target="_blank"
              rel="noopener noreferrer"
              className="mb-4 mr-2 md:hidden"
            >
              <FaInstagram className="text-3xl" />
            </Link>
          </div>
          <div>
            <form
              onSubmit={newsLetterHandler}
              className="flex flex-col md:flex-row md:items-center"
            >
              <p className="mr-5 text-sm mb-2 md:mb-0 lg:mb-0">
                ニュースレター登録
              </p>
              <div className="join w-full md:w-96">
                <input
                  type="email"
                  className="input input-bordered join-item w-full bg-white border-black text-black"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button
                  type="submit"
                  className="btn join-item bg-gray-800 hover:bg-gray-500 w-20 font-light"
                >
                  購 読
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between">
          <div>
            <div className="flex text-sm mb-10">
              <ul className="mr-10 lg:mr-14">
                <li className="italic mb-5 text-lg text-gray-400 font-bold">
                  Company
                </li>
                <li className="footer-menulist">
                  <Link to="/order-tshirts">オーダーTシャツについて</Link>
                </li>
                <li className="footer-menulist">
                  <Link to="/shopping-guide">ショッピングガイド</Link>
                </li>
                <li className="footer-menulist">
                  <Link to="/contact">お問い合わせ</Link>
                </li>
                <li className="footer-menulist">
                  <Link to="/transaction-law">特定商取引法に基づく表記</Link>
                </li>
              </ul>
              <ul className="mr-10 lg:mr-14">
                <li className="italic mb-5 text-lg text-gray-400 font-bold">
                  Services
                </li>
                <li className="footer-menulist">
                  <Link to="/tryon">試着サービス</Link>
                </li>
                <li className="footer-menulist">
                  <Link to="/resize">無料調整サービス</Link>
                </li>
                <li className="footer-menulist">
                  <Link to="/fabric-sample">生地見本サンプル</Link>
                </li>
                <li className="footer-menulist">
                  <Link to="/login">ログイン</Link>
                </li>
              </ul>
              <ul className="hidden md:block">
                <li className="italic mb-5 text-lg text-gray-400 font-bold">
                  SNS
                </li>
                <li className="footer-menulist flex items-center">
                  <FaInstagram className="mr-1 text-lg" />
                  <Link
                    to="https://www.instagram.com/mnsfabric.tshirts/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    インスタグラム
                  </Link>
                </li>
              </ul>
            </div>
            <div className="hidden lg:block">
              <Link
                to="https://leap-dog.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="w-full md:w-96 border border-neutral-500 px-5 py-4 bg-neutral-950 hover:bg-neutral-900 cursor-pointer flex items-center"
              >
                <img className="w-10 mr-4" src="/images/dog.svg" alt="" />
                <div className="flex-1">
                  <p className="text-sm mb-1">愛犬にもTシャツを！</p>
                  <p className="text-sm">
                    ヒューマンライクなドッグウェア{' '}
                    <img
                      className="w-10 inline ml-1"
                      src="/images/leap.svg"
                      alt="ドッグウェア Leap"
                    />
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className="text-left lg:text-right">
            <h3 className="text-3xl/tight lg:text-5xl/tight italic font-bold">
              We'll adjust it to your size
              <br />
              and deliver it anytime!
            </h3>
            <p className="text-xl lg:text-2xl italic mt-5">
              いつでも最適なサイズに調整してお届けします！
            </p>
          </div>
          <div className="lg:hidden mt-10">
            <a
              href="https://leap-dog.com/"
              className="w-full md:w-96 border border-neutral-500 px-5 py-4 bg-neutral-950 hover:bg-neutral-900 cursor-pointer flex items-center"
            >
              <img className="w-10 mr-4" src="/images/dog.svg" alt="" />
              <div className="flex-1">
                <p className="text-sm mb-1">愛犬にもTシャツを！</p>
                <p className="text-sm">
                  ヒューマンライクなドッグウェア{' '}
                  <img
                    className="w-10 inline ml-1"
                    src="/images/leap.svg"
                    alt=""
                  />
                </p>
              </div>
            </a>
          </div>
        </div>
        <small className="block italic mt-14">
          m&s Fabric &copy; {currentYear}
        </small>
      </div>
    </footer>
  )
}

export default Footer
