import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useGetMyOrdersQuery } from '../../store/apis/ordersApi'
import { useAddCheckFormMutation } from '../../store/apis/checkFormApi'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { useSendMailMutation } from '../../store/apis/sendMailApi'
import { checkformMail } from '../../mail/checkformMail'
import { toast } from 'react-toastify'

const Checkform = () => {
  const { userInfo } = useSelector((state) => state.auth)
  const { data: myOrder, isLoading, error } = useGetMyOrdersQuery()
  const [addCheckForm] = useAddCheckFormMutation()

  const [length, setLength] = useState(0)
  const [chest, setChest] = useState(0)
  const [waist, setWaist] = useState(0)
  const [hem, setHem] = useState(0)
  const [shoulder, setShoulder] = useState(0)
  const [armpit, setArmpit] = useState(0)
  const [sleeve, setSleeve] = useState(0)
  const [sleeveEnd, setSleeveEnd] = useState(0)
  const [neckWidth, setNeckWidth] = useState(0)
  const [neckHeight, setNeckHeight] = useState(0)
  const [comment, setComment] = useState('')
  const [notDeliveredItems, setNotDeliveredItems] = useState([])
  const [sendMail] = useSendMailMutation()
  const [isLaundry, setIsLaundry] = useState(false)

  const [isNewTailor, setIsNewTailor] = useState(false)

  useEffect(() => {
    if (myOrder && myOrder.length !== 0) {
      const filterDeliverYet = myOrder.filter(
        (order) => order.isDelivered === false
      )
      setNotDeliveredItems(filterDeliverYet)
    } else {
      console.log('error')
    }
  }, [myOrder])

  useEffect(() => {
    const filterTailor = notDeliveredItems.filter((order) =>
      order.orderItems.find((item) => item.isNew === true)
    )
    setIsNewTailor(filterTailor)
  }, [notDeliveredItems])

  const msg = {
    to: userInfo.email,
    from: 'info@mnsfabric.com',
    bcc: 'info@mnsfabric.com',
    subject: `${userInfo.lastname} ${userInfo.firstname} 様 チェックフォームの返信ありがとうございます`,
    text: checkformMail(userInfo, {
      length,
      chest,
      waist,
      hem,
      shoulder,
      armpit,
      sleeve,
      sleeveEnd,
      neckWidth,
      neckHeight,
      comment,
    }),
  }

  const sendMailHandler = async (e) => {
    e.preventDefault()

    try {
      await addCheckForm({
        length,
        chest,
        waist,
        hem,
        shoulder,
        armpit,
        sleeve,
        sleeveEnd,
        neckWidth,
        neckHeight,
        comment,
      })
      await sendMail(msg)
      setLength(0)
      setChest(0)
      setWaist(0)
      setHem(0)
      setShoulder(0)
      setArmpit(0)
      setSleeve(0)
      setSleeveEnd(0)
      setNeckWidth(0)
      setNeckHeight(0)
      setComment('')
      setIsLaundry(false)
      toast.success('送信ありがとうございます')
    } catch (error) {
      toast.error('送信できませんでした')
    }
  }

  return (
    <div className="flex justify-center w-full mb-20">
      {isLoading ? (
        <Loader />
      ) : error ? (
        <div className="w-full">
          <Message variant="alert-error">
            オーダー情報を取得できませんでした
          </Message>
        </div>
      ) : isNewTailor === false || isNewTailor.length === 0 ? (
        <div className="w-full">
          <Message variant="alert-info">
            進行中のテイラーTシャツはありません
          </Message>
        </div>
      ) : (
        <form
          onSubmit={sendMailHandler}
          className="w-full flex flex-col md:flex-row md:gap-14"
        >
          <div className="w-full lg:w-1/2 mb-10">
            <p className="text-sm/6 mb-5 p-5 bg-sky-100 xl:hidden">
              サイズ確認の際はいつもの洗濯環境で一度洗濯し、縮みを確認した上でご確認ください。生地の種類や洗濯環境によって縮み率が異なることがあります。
            </p>
            <img
              src="/images/tailorImg/checkform_body_half.svg"
              alt="Tシャツ寸法図"
            />
            <img
              src="/images/tailorImg/checkform_neck_o.svg"
              alt="Tシャツ寸法図"
            />
          </div>
          <div className="w-full lg:w-1/2">
            <p className="hidden text-sm/6 mt-5 mb-10 p-5 bg-sky-100 xl:block">
              サイズ確認の際はいつもの洗濯環境で一度洗濯し、縮みを確認した上でご確認ください。生地の種類や洗濯環境によって縮み率が異なることがあります。
            </p>
            <div className="flex flex-col xl:flex-row xl:gap-14 mb-5">
              <div className="xl:w-1/2 text-sm">
                <div className="w-full flex flex-row items-center border-b pb-2 mb-5">
                  <img
                    className="w-4 mr-2"
                    src="/images/numbers/1.svg"
                    alt="1"
                  />
                  <p className="w-24 flex-auto">着丈</p>

                  <button
                    type="button"
                    className="btn btn-sm w-10 flex-none"
                    onClick={() => setLength((prev) => prev - 1)}
                    disabled={length <= -10}
                  >
                    ー
                  </button>
                  <p
                    className={`w-20 text-center text-lg flex-none ${
                      length < 0
                        ? 'text-red-500'
                        : length > 0
                        ? 'text-blue-500'
                        : 'black'
                    }`}
                  >
                    {length > 0 && '+'}
                    {length}
                    <span className="text-sm ml-1">cm</span>
                  </p>
                  <button
                    type="button"
                    className="btn btn-sm w-10 flex-none"
                    onClick={() => setLength((prev) => prev + 1)}
                    disabled={length >= 10}
                  >
                    ＋
                  </button>
                </div>
                <div className="w-full flex flex-row items-center border-b pb-2 mb-5">
                  <img
                    className="w-4 mr-2"
                    src="/images/numbers/2.svg"
                    alt="2"
                  />
                  <p className="w-24 flex-auto">着幅</p>

                  <button
                    type="button"
                    className="btn btn-sm w-10 flex-none"
                    onClick={() => setChest((prev) => prev - 1)}
                    disabled={chest <= -10}
                  >
                    ー
                  </button>
                  <p
                    className={`w-20 text-center text-lg flex-none ${
                      chest < 0
                        ? 'text-red-500'
                        : chest > 0
                        ? 'text-blue-500'
                        : 'black'
                    }`}
                  >
                    {chest > 0 && '+'}
                    {chest}
                    <span className="text-sm ml-1">cm</span>
                  </p>
                  <button
                    type="button"
                    className="btn btn-sm w-10 flex-none"
                    onClick={() => setChest((prev) => prev + 1)}
                    disabled={chest >= 10}
                  >
                    ＋
                  </button>
                </div>
                <div className="w-full flex flex-row items-center border-b pb-2 mb-5">
                  <img
                    className="w-4 mr-2"
                    src="/images/numbers/3.svg"
                    alt="3"
                  />
                  <p className="w-24 flex-auto">ウエスト</p>

                  <button
                    type="button"
                    className="btn btn-sm w-10 flex-none"
                    onClick={() => setWaist((prev) => prev - 1)}
                    disabled={waist <= -10}
                  >
                    ー
                  </button>
                  <p
                    className={`w-20 text-center text-lg flex-none ${
                      waist < 0
                        ? 'text-red-500'
                        : waist > 0
                        ? 'text-blue-500'
                        : 'black'
                    }`}
                  >
                    {waist > 0 && '+'}
                    {waist}
                    <span className="text-sm ml-1">cm</span>
                  </p>
                  <button
                    type="button"
                    className="btn btn-sm w-10 flex-none"
                    onClick={() => setWaist((prev) => prev + 1)}
                    disabled={waist >= 10}
                  >
                    ＋
                  </button>
                </div>
                <div className="w-full flex flex-row items-center border-b pb-2 mb-5">
                  <img
                    className="w-4 mr-2"
                    src="/images/numbers/4.svg"
                    alt="4"
                  />
                  <p className="w-24 flex-auto">裾幅</p>

                  <button
                    type="button"
                    className="btn btn-sm w-10 flex-none"
                    onClick={() => setHem((prev) => prev - 1)}
                    disabled={hem <= -10}
                  >
                    ー
                  </button>
                  <p
                    className={`w-20 text-center text-lg flex-none ${
                      hem < 0
                        ? 'text-red-500'
                        : hem > 0
                        ? 'text-blue-500'
                        : 'black'
                    }`}
                  >
                    {hem > 0 && '+'}
                    {hem}
                    <span className="text-sm ml-1">cm</span>
                  </p>
                  <button
                    type="button"
                    className="btn btn-sm w-10 flex-none"
                    onClick={() => setHem((prev) => prev + 1)}
                    disabled={hem >= 10}
                  >
                    ＋
                  </button>
                </div>
                <div className="w-full flex flex-row items-center border-b pb-2 mb-5">
                  <img
                    className="w-4 mr-2"
                    src="/images/numbers/5.svg"
                    alt="5"
                  />
                  <p className="w-24 flex-auto">肩幅</p>

                  <button
                    type="button"
                    className="btn btn-sm w-10 flex-none"
                    onClick={() => setShoulder((prev) => prev - 1)}
                    disabled={shoulder <= -10}
                  >
                    ー
                  </button>
                  <p
                    className={`w-20 text-center text-lg flex-none ${
                      shoulder < 0
                        ? 'text-red-500'
                        : shoulder > 0
                        ? 'text-blue-500'
                        : 'black'
                    }`}
                  >
                    {shoulder > 0 && '+'}
                    {shoulder}
                    <span className="text-sm ml-1">cm</span>
                  </p>
                  <button
                    type="button"
                    className="btn btn-sm w-10 flex-none"
                    onClick={() => setShoulder((prev) => prev + 1)}
                    disabled={shoulder >= 10}
                  >
                    ＋
                  </button>
                </div>
              </div>
              <div className="xl:w-1/2 text-sm">
                <div className="w-full flex flex-row items-center border-b pb-2 mb-5">
                  <img
                    className="w-4 mr-2"
                    src="/images/numbers/6.svg"
                    alt="6"
                  />
                  <p className="w-24 flex-auto">脇高</p>

                  <button
                    type="button"
                    className="btn btn-sm w-10 flex-none"
                    onClick={() => setArmpit((prev) => prev - 1)}
                    disabled={armpit <= -10}
                  >
                    ー
                  </button>
                  <p
                    className={`w-20 text-center text-lg flex-none ${
                      armpit < 0
                        ? 'text-red-500'
                        : armpit > 0
                        ? 'text-blue-500'
                        : 'black'
                    }`}
                  >
                    {armpit > 0 && '+'}
                    {armpit}
                    <span className="text-sm ml-1">cm</span>
                  </p>
                  <button
                    type="button"
                    className="btn btn-sm w-10 flex-none"
                    onClick={() => setArmpit((prev) => prev + 1)}
                    disabled={armpit >= 10}
                  >
                    ＋
                  </button>
                </div>
                <div className="w-full flex flex-row items-center border-b pb-2 mb-5">
                  <img
                    className="w-4 mr-2"
                    src="/images/numbers/7.svg"
                    alt="7"
                  />
                  <p className="w-24 flex-auto">袖丈</p>

                  <button
                    type="button"
                    className="btn btn-sm w-10 flex-none"
                    onClick={() => setSleeve((prev) => prev - 1)}
                    disabled={sleeve <= -10}
                  >
                    ー
                  </button>
                  <p
                    className={`w-20 text-center text-lg flex-none ${
                      sleeve < 0
                        ? 'text-red-500'
                        : sleeve > 0
                        ? 'text-blue-500'
                        : 'black'
                    }`}
                  >
                    {sleeve > 0 && '+'}
                    {sleeve}
                    <span className="text-sm ml-1">cm</span>
                  </p>
                  <button
                    type="button"
                    className="btn btn-sm w-10 flex-none"
                    onClick={() => setSleeve((prev) => prev + 1)}
                    disabled={sleeve >= 10}
                  >
                    ＋
                  </button>
                </div>
                <div className="w-full flex flex-row items-center border-b pb-2 mb-5">
                  <img
                    className="w-4 mr-2"
                    src="/images/numbers/8.svg"
                    alt="8"
                  />
                  <p className="w-24 flex-auto">袖幅</p>

                  <button
                    type="button"
                    className="btn btn-sm w-10 flex-none"
                    onClick={() => setSleeveEnd((prev) => prev - 1)}
                    disabled={sleeveEnd <= -10}
                  >
                    ー
                  </button>
                  <p
                    className={`w-20 text-center text-lg flex-none ${
                      sleeveEnd < 0
                        ? 'text-red-500'
                        : sleeveEnd > 0
                        ? 'text-blue-500'
                        : 'black'
                    }`}
                  >
                    {sleeveEnd > 0 && '+'}
                    {sleeveEnd}
                    <span className="text-sm ml-1">cm</span>
                  </p>
                  <button
                    type="button"
                    className="btn btn-sm w-10 flex-none"
                    onClick={() => setSleeveEnd((prev) => prev + 1)}
                    disabled={sleeveEnd >= 10}
                  >
                    ＋
                  </button>
                </div>
                <div className="w-full flex flex-row items-center border-b pb-2 mb-5">
                  <img
                    className="w-4 mr-2"
                    src="/images/numbers/9.svg"
                    alt="9"
                  />
                  <p className="w-24 flex-auto">首幅</p>

                  <button
                    type="button"
                    className="btn btn-sm w-10 flex-none"
                    onClick={() => setNeckWidth((prev) => prev - 1)}
                    disabled={neckWidth <= -10}
                  >
                    ー
                  </button>
                  <p
                    className={`w-20 text-center text-lg flex-none ${
                      neckWidth < 0
                        ? 'text-red-500'
                        : neckWidth > 0
                        ? 'text-blue-500'
                        : 'black'
                    }`}
                  >
                    {neckWidth > 0 && '+'}
                    {neckWidth}
                    <span className="text-sm ml-1">cm</span>
                  </p>
                  <button
                    type="button"
                    className="btn btn-sm w-10 flex-none"
                    onClick={() => setNeckWidth((prev) => prev + 1)}
                    disabled={neckWidth >= 10}
                  >
                    ＋
                  </button>
                </div>
                <div className="w-full flex flex-row items-center border-b pb-2 mb-5">
                  <img
                    className="w-4 mr-2"
                    src="/images/numbers/10.svg"
                    alt="10"
                  />
                  <p className="w-24 flex-auto">首深</p>

                  <button
                    type="button"
                    className="btn btn-sm w-10 flex-none"
                    onClick={() => setNeckHeight((prev) => prev - 1)}
                    disabled={neckHeight <= -10}
                  >
                    ー
                  </button>
                  <p
                    className={`w-20 text-center text-lg flex-none ${
                      neckHeight < 0
                        ? 'text-red-500'
                        : neckHeight > 0
                        ? 'text-blue-500'
                        : 'black'
                    }`}
                  >
                    {neckHeight > 0 && '+'}
                    {neckHeight}
                    <span className="text-sm ml-1">cm</span>
                  </p>
                  <button
                    type="button"
                    className="btn btn-sm w-10 flex-none"
                    onClick={() => setNeckHeight((prev) => prev + 1)}
                    disabled={neckHeight >= 10}
                  >
                    ＋
                  </button>
                </div>
              </div>
            </div>
            <div>
              <p className="mb-2 text-sm">コメント</p>
              <textarea
                className="textarea textarea-bordered w-full h-32"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
              <div className="form-control">
                <label className="label cursor-pointer justify-start">
                  <input
                    type="checkbox"
                    className="checkbox mr-3"
                    value={isLaundry}
                    onChange={() => setIsLaundry(!isLaundry)}
                    checked={isLaundry}
                  />
                  <span className="label-text text-red-600">
                    洗濯・乾燥をして縮みや変形を確認しました
                  </span>
                </label>
              </div>
              <button
                type="submit"
                className="btn btn-primary w-full mt-7"
                disabled={!isLaundry}
              >
                送信する
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}

export default Checkform
