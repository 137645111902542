const TailorNeckSizeO = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="\u30EC\u30A4\u30E4\u30FC_1"
      x={0}
      y={0}
      style={{
        enableBackground: 'new 0 0 450 180',
      }}
      viewBox="0 0 450 180"
    >
      <style>
        {
          '.st00{stroke:#000;stroke-linejoin:round}.st00,.st11,.st22{fill:none;stroke-miterlimit:10}.st11{stroke:#000;stroke-linecap:round;stroke-linejoin:round}.st22{stroke:#aaa}.st33{fill:#aaa}.st44{fill:none;stroke:#aaa;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.st55{font-family:"NotoSansCJKjp-Regular-83pv-RKSJ-H"}.st66{font-size:13px}.st77{font-size:10px}.st88{fill:none;stroke:#000;stroke-width:.3607;stroke-miterlimit:10}'
        }
      </style>
      <path
        d="M281.1 38.9c4.4 8.4 6.8 18 6.8 28.2 0 34-27.5 61.5-61.5 61.5h.6c-34 0-61.5-27.5-61.5-61.5 0-10.2 2.5-19.7 6.8-28.2"
        className="st00"
      />
      <path
        d="M281 45.8S268.4 57 226.4 57h.6c-41.9 0-54.6-11.2-54.6-11.2"
        className="st00"
      />
      <path
        d="M289.9 42.1c2.8 7.6 4.9 16.9 4.9 24.9 0 37.5-30.4 68.1-67.8 68.4h-.6c-37.4-.3-67.8-30.9-67.8-68.4 0-8 1.8-17.2 4.6-24.8"
        className="st00"
      />
      <path
        d="M429.5 123c-21.8-32-44.6-42.3-44.6-42.3L281.1 38.9h-.1s-12.6 11.2-54.6 11.2h.6c-41.9 0-54.6-11.2-54.6-11.2L68.6 80.7S45.8 91 24 123"
        className="st00"
      />
      <path
        d="M73.5 162.5c-4.9-33.6-12.2-62-12.2-62M392.2 100.5s-7.3 28.4-12.2 62"
        className="st11"
      />
      <path d="M226.5 50.1V97M226.5 118.5v10.1" className="st22" />
      <circle cx={226.5} cy={128.6} r={2.5} className="st33" />
      <path d="M166.5 68.3h11.4M219.1 68.3h68.2" className="st22" />
      <circle cx={165.6} cy={68.5} r={2.5} className="st33" />
      <circle cx={287.9} cy={68.4} r={2.5} className="st33" />
      <circle cx={226.5} cy={49.7} r={2.5} className="st33" />
      <path d="M72.2 38.9h77.2L76.1 68.7" className="st44" />
      <path d="M127.8 38.9c0 2.9.6 5.6 1.6 8.1" className="st44" />
      <text className="st55 st66" transform="translate(81.215 54.553)">
        {size.shoulderLine}
      </text>
      <text transform="translate(64.571 53.29)">
        <tspan x={0} y={0} className="st55 st77">
          {'1'}
        </tspan>
        <tspan x={4.8} y={0} className="st55 st77">
          {'1'}
        </tspan>
      </text>
      <path d="M63.7 43.7h12v12h-12z" className="st88" />
      <text className="st55 st66" transform="translate(219.56 111.345)">
        {size.neckHeight}
      </text>
      <text transform="translate(202.98 110.083)">
        <tspan x={0} y={0} className="st55 st77">
          {'1'}
        </tspan>
        <tspan x={4.8} y={0} className="st55 st77">
          {'0'}
        </tspan>
      </text>
      <path d="M202.2 100.5h12v12h-12z" className="st88" />
      <text className="st55 st66" transform="translate(199.812 73.147)">
        {size.neckWidth}
      </text>
      <text className="st55 st77" transform="translate(185.699 71.885)">
        {'9'}
      </text>
      <path d="M182.5 62.3h12v12h-12z" className="st88" />
    </svg>
  )
}

export default TailorNeckSizeO
