import React from 'react'

const TailorBodySizeLongV = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="\u30EC\u30A4\u30E4\u30FC_1"
      x={0}
      y={0}
      style={{
        enableBackground: 'new 0 0 450 400',
      }}
      viewBox="0 0 450 400"
    >
      <style>
        {
          '.st1,.st2{stroke:#000;stroke-linejoin:round}.st1{fill:none;stroke-miterlimit:10}.st2{stroke-linecap:round}.st2,.st3,.st4{fill:none;stroke-miterlimit:10}.st3{stroke:#000;stroke-width:.8574;stroke-linejoin:round}.st4{stroke:#aaa}.st5{fill:#aaa}.st6{font-family:"NotoSansCJKjp-Regular-83pv-RKSJ-H"}.st7{font-size:13px}.st8{font-size:10px}.st9{fill:none;stroke:#000;stroke-width:.3607;stroke-miterlimit:10}'
        }
      </style>
      <path
        d="M311 179.2c-.2 63.1.5 172.7.5 172.7H140.7s.7-109.6.5-172.7c0 0-16 35.8-19.9 62.2-3.5 23.6-10.7 110.5-10.7 110.5l-37.3-3s-1.9-77.6 4.1-121.3c6.8-49.9 25.2-107 32.5-130.6 6.4-20.5 22.8-26.3 22.8-26.3l63.2-23.2s7 7.9 30.3 7.9h-.3c23.3 0 30.3-7.9 30.3-7.9l63.2 23.2s16.4 5.7 22.8 26.3c7.3 23.5 25.7 80.7 32.5 130.6 6 43.7 4.1 121.3 4.1 121.3l-37.3 3s-7.2-86.9-10.7-110.5C327 215 311 179.2 311 179.2z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M256.3 51.4s-7 7.9-30.3 7.9h.3c-23.3 0-30.3-7.9-30.3-7.9"
        className="st1"
      />
      <path
        d="M311 179.2s16 35.8 19.9 62.2c3.5 23.6 10.7 110.5 10.7 110.5l37.3-3s1.9-77.6-4.1-121.3c-6.8-49.9-25.2-107-32.5-130.6-6.4-20.5-22.8-26.3-22.8-26.3l-63.2-23.2s-7 7.9-30.3 7.9h.3c-23.3 0-30.3-7.9-30.3-7.9l-63.2 23.2S116.4 76.4 110 97c-7.3 23.5-25.7 80.7-32.5 130.6-6 43.7-4.1 121.3-4.1 121.3l37.3 3s7.2-86.9 10.7-110.5c3.9-26.3 19.9-62.2 19.9-62.2M341.5 347.9l37.4-3M73.4 344.9l37.3 3"
        className="st1"
      />
      <path
        d="M323.6 79.8s-10.8 32.6-12.1 59c-1.2 26.4 0 213.1 0 213.1H140.7s1.2-186.7 0-213.1c-1.2-26.4-12.1-59-12.1-59M311.5 347.9H140.7"
        className="st2"
      />
      <path
        d="M261.9 49.6c1.5 4 1.7 7.2 1.7 9.6 0 9.7-9 22-16.5 30.6-3.8 4.3-7.9 8.5-11.7 11.9-5.9 5.3-7.7 5.7-9.2 5.7s-3.3-.4-9.2-5.7c-3.8-3.4-7.9-7.6-11.7-11.9-7.5-8.6-16.5-20.9-16.5-30.6 0-2.5.2-5.7 1.8-9.7"
        className="st3"
      />
      <path
        d="M256.6 47.6c2.5 4.8 3 8.4 3 11.7 0 15.7-30.2 44.3-33.4 44.3-3.2 0-33.4-28.6-33.4-44.3 0-3.3.5-6.9 3-11.7"
        className="st3"
      />
      <path
        d="M336.1 74.8h75.5M374 169.1h37.6M129.1 30.2h70.4M249.4 30.2h74.1M397.6 72v38.4M397.6 136.7v32.8M127.6 15.2v44M140.7 356v30M311.5 356v30M323.5 15v44.2M141.2 370.9h61.6M250.6 370.9h60.9"
        className="st4"
      />
      <circle cx={127.6} cy={30.5} r={2.5} className="st5" />
      <circle cx={323.5} cy={30.5} r={2.5} className="st5" />
      <circle cx={397.5} cy={169.5} r={2.5} className="st5" />
      <circle cx={397.5} cy={74.5} r={2.5} className="st5" />
      <circle cx={140.7} cy={370.5} r={2.5} className="st5" />
      <circle cx={311.5} cy={370.5} r={2.5} className="st5" />
      <circle cx={226.5} cy={55.5} r={2.5} className="st5" />
      <path d="M226.5 55.5v146.9M226.5 233.4v118.5" className="st4" />
      <circle cx={226.5} cy={351.9} r={2.5} className="st5" />
      <path
        d="M141.3 168.9h15.6M205.9 168.9h105M141.4 259.9h15.5M205.9 259.9h105.4"
        className="st4"
      />
      <circle cx={141.3} cy={168.9} r={2.5} className="st5" />
      <circle cx={141.4} cy={259.9} r={2.5} className="st5" />
      <circle cx={310.9} cy={168.9} r={2.5} className="st5" />
      <circle cx={311.3} cy={259.9} r={2.5} className="st5" />
      <text className="st6 st7" transform="translate(226.012 35.308)">
        {size.shoulder}
      </text>
      <text className="st6 st8" transform="translate(210.704 34.046)">
        {'5'}
      </text>
      <path d="M207.5 24.5h12v12h-12z" className="st9" />
      <text className="st6 st7" transform="translate(219.139 221.664)">
        {size.length}
      </text>
      <text className="st6 st8" transform="translate(203.83 220.402)">
        {'1'}
      </text>
      <path d="M200.6 210.9h12v12h-12z" className="st9" />
      <text className="st6 st7" transform="translate(390.284 127.772)">
        {size.armpit}
      </text>
      <text className="st6 st8" transform="translate(374.976 126.51)">
        {'6'}
      </text>
      <path d="M371.8 117h12v12h-12z" className="st9" />
      <text className="st6 st7" transform="translate(183.617 173.887)">
        {size.chest}
      </text>
      <text className="st6 st8" transform="translate(168.31 172.625)">
        {'2'}
      </text>
      <path d="M165.1 163.1h12v12h-12z" className="st9" />
      <text className="st6 st8" transform="translate(86.197 385.353)">
        {'8'}
      </text>
      <path d="M83 375.8h12v12H83z" className="st9" />
      <text className="st6 st7" transform="translate(47.418 207.248)">
        {size.sleeveLong}
      </text>
      <text className="st6 st8" transform="translate(32.11 205.986)">
        {'7'}
      </text>
      <path d="M28.9 196.4h12v12h-12z" className="st9" />
      <text className="st6 st7" transform="translate(183.617 264.749)">
        {size.waist}
      </text>
      <text className="st6 st8" transform="translate(168.31 263.487)">
        {'3'}
      </text>
      <path d="M165.1 253.9h12v12h-12z" className="st9" />
      <text className="st6 st7" transform="translate(228.433 375.808)">
        {size.hem}
      </text>
      <text className="st6 st8" transform="translate(213.125 374.545)">
        {'4'}
      </text>
      <path d="M209.9 365h12v12h-12z" className="st9" />
      <path
        d="m70 383.4 1.8-29.9M109.9 385.8l1.8-30M77.7 68.2l28.8 8.2M29 345.1l29.9 2.6M79.1 368.4l-8-.5M111.3 370.3l-8.1-.5"
        className="st4"
      />
      <text className="st6 st7" transform="translate(83.653 370.02)">
        {size.wrist}
      </text>
      <circle cx={92.8} cy={72.5} r={2.5} className="st5" />
      <circle cx={44} cy={346.4} r={2.5} className="st5" />
      <circle cx={71.1} cy={367.9} r={2.5} className="st5" />
      <circle cx={110.9} cy={370.3} r={2.5} className="st5" />
      <path
        d="M51.1 215.9C44.6 255 44 292.7 44 346.4M92.8 72.5S71 119.1 56.2 189.1"
        className="st4"
      />
    </svg>
  )
}

export default TailorBodySizeLongV
