import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  removeFabricSample,
  deleteFabricSample,
} from '../store/slices/fabricSampleSlice'
import Meta from '../components/Meta'
import { useSendMailMutation } from '../store/apis/sendMailApi'
import { fabricSampleMail } from '../mail/fabricSampleMail'
import { toast } from 'react-toastify'

const FabricSampleFormScreen = () => {
  const { fabricSamples } = useSelector((state) => state.fabricSample)
  const dispatch = useDispatch()

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [firstnameYomi, setFirstnameYomi] = useState('')
  const [lastnameYomi, setLastnameYomi] = useState('')
  const [email, setEmail] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [prefecture, setPrefecture] = useState('都道府県を選択してください')
  const [city, setCity] = useState('')
  const [address, setAddress] = useState('')
  const [building, setBuilding] = useState('')
  const [phoneNum, setPhoneNum] = useState('')

  const [sendMail, { isLoading }] = useSendMailMutation()
  const navigate = useNavigate()

  const removeHandler = (id) => {
    dispatch(removeFabricSample(id))
  }

  const msg = {
    to: email,
    from: 'info@mnsfabric.com',
    bcc: 'info@mnsfabric.com',
    subject: `${lastname} ${firstname} 様 生地見本サンプルのご請求ありがとうございます`,
    text: fabricSampleMail({
      firstname,
      lastname,
      firstnameYomi,
      lastnameYomi,
      email,
      postalCode,
      prefecture,
      city,
      address,
      building,
      phoneNum,
      fabricSamples,
    }),
  }

  const submitHandler = async (e) => {
    e.preventDefault()

    try {
      await sendMail(msg)
      dispatch(deleteFabricSample())
      navigate('/')
      toast.success('生地見本サンプルのご請求が送信されました')
    } catch (error) {
      toast.error('送信できませんでした')
    }
  }

  // Request fabric samples for Google Ad
  const script = `gtag('event', 'conversion', {'send_to': 'AW-16646522219/VLi6CMyCxMcZEOuK14E-'});`

  return (
    <>
      <Meta title="無料Tシャツ生地サンプル | m&s Fabric" script={script} />
      <div className="flex justify-center grow">
        <div className="screen-container mt-5 mb-14 w-full">
          <div className="text-sm breadcrumbs text-gray-500 mb-10">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/fabric-sample">生地見本サンプル</Link>
              </li>
              <li>生地見本サンプル請求フォーム</li>
            </ul>
          </div>
          <div className="container mt-14 lg:mt-20 flex flex-col lg:flex-row justify-center mb-24 gap-20">
            <div className="basis-1/2">
              <h2 className="text-lg font-bold mb-10">生地見本</h2>
              <ul>
                {fabricSamples.map((samp, index) => (
                  <li
                    key={index}
                    className="flex items-center justify-between border-b pb-3 mb-3"
                  >
                    <div className="flex">
                      <div
                        style={{ backgroundColor: `${samp.bg}` }}
                        className="w-16 h-16 mr-5 border border-neutral-500"
                      ></div>
                      <div>
                        <p className="text-xl italic font-bold mb-1">
                          {samp.fabric}
                        </p>
                        <p className="text-sm">{samp.color}</p>
                      </div>
                    </div>
                    <button
                      className="btn-sm btn-ghost"
                      onClick={() => removeHandler(samp.id)}
                    >
                      削除
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full md:basis-1/2">
              <h2 className="text-lg font-bold mb-10">配送先情報</h2>
              <form onSubmit={submitHandler}>
                <div className="form-control w-full mb-5">
                  <label className="label">
                    <span className="label-text">お名前</span>
                  </label>
                  <div className="flex gap-3">
                    <input
                      type="text"
                      placeholder="姓"
                      className="input input-bordered w-full"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                      pattern="[\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFF9F\u4E00-\u9FFF]*"
                      title="日本語で入力してください"
                      required
                    />
                    <input
                      type="text"
                      placeholder="名"
                      className="input input-bordered w-full"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                      pattern="[\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFF9F\u4E00-\u9FFF]*"
                      title="日本語で入力してください"
                      required
                    />
                  </div>
                </div>
                <div className="form-control w-full mb-5">
                  <label className="label">
                    <span className="label-text">お名前（フリガナ）</span>
                  </label>
                  <div className="flex gap-3">
                    <input
                      type="text"
                      placeholder="セイ"
                      className="input input-bordered w-full"
                      value={lastnameYomi}
                      onChange={(e) => setLastnameYomi(e.target.value)}
                      pattern="^[ァ-ヶー]+$"
                      title="全角カタカナで入力してください"
                      required
                    />
                    <input
                      type="text"
                      placeholder="メイ"
                      className="input input-bordered w-full"
                      value={firstnameYomi}
                      onChange={(e) => setFirstnameYomi(e.target.value)}
                      pattern="^[ァ-ヶー]+$"
                      title="全角カタカナで入力してください"
                      required
                    />
                  </div>
                </div>
                <div className="form-control w-full mb-5">
                  <label className="label">
                    <span className="label-text">メールアドレス</span>
                  </label>
                  <input
                    type="email"
                    className="input input-bordered w-full"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <div className="flex flex-row gap-5">
                    <label className="form-control mb-3 basis-1/2">
                      <div className="label">
                        <span className="label-text">郵便番号</span>
                      </div>
                      <input
                        type="text"
                        className="input input-bordered w-full text-sm"
                        minLength="7"
                        maxLength="8"
                        autoComplete="postal-code"
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        required
                      />
                    </label>
                    <label className="form-control mb-3 basis-1/2">
                      <div className="label">
                        <span className="label-text">都道府県</span>
                      </div>
                      <select
                        className="select select-bordered"
                        value={prefecture}
                        onChange={(e) => setPrefecture(e.target.value)}
                        autoComplete="address-level1"
                        required
                      >
                        <option disabled>都道府県を選択してください</option>
                        <option value="北海道">北海道</option>
                        <option value="青森県">青森県</option>
                        <option value="岩手県">岩手県</option>
                        <option value="宮城県">宮城県</option>
                        <option value="秋田県">秋田県</option>
                        <option value="山形県">山形県</option>
                        <option value="福島県">福島県</option>
                        <option value="茨城県">茨城県</option>
                        <option value="栃木県">栃木県</option>
                        <option value="群馬県">群馬県</option>
                        <option value="埼玉県">埼玉県</option>
                        <option value="千葉県">千葉県</option>
                        <option value="東京都">東京都</option>
                        <option value="神奈川県">神奈川県</option>
                        <option value="新潟県">新潟県</option>
                        <option value="富山県">富山県</option>
                        <option value="石川県">石川県</option>
                        <option value="福井県">福井県</option>
                        <option value="山梨県">山梨県</option>
                        <option value="長野県">長野県</option>
                        <option value="岐阜県">岐阜県</option>
                        <option value="静岡県">静岡県</option>
                        <option value="愛知県">愛知県</option>
                        <option value="三重県">三重県</option>
                        <option value="滋賀県">滋賀県</option>
                        <option value="京都府">京都府</option>
                        <option value="大阪府">大阪府</option>
                        <option value="兵庫県">兵庫県</option>
                        <option value="奈良県">奈良県</option>
                        <option value="和歌山県">和歌山県</option>
                        <option value="鳥取県">鳥取県</option>
                        <option value="島根県">島根県</option>
                        <option value="岡山県">岡山県</option>
                        <option value="広島県">広島県</option>
                        <option value="山口県">山口県</option>
                        <option value="徳島県">徳島県</option>
                        <option value="香川県">香川県</option>
                        <option value="愛媛県">愛媛県</option>
                        <option value="高知県">高知県</option>
                        <option value="福岡県">福岡県</option>
                        <option value="佐賀県">佐賀県</option>
                        <option value="長崎県">長崎県</option>
                        <option value="熊本県">熊本県</option>
                        <option value="大分県">大分県</option>
                        <option value="宮崎県">宮崎県</option>
                        <option value="鹿児島県">鹿児島県</option>
                        <option value="沖縄県">沖縄県</option>
                      </select>
                    </label>
                  </div>
                  <div className="flex flex-col lg:flex-row lg:gap-5">
                    <label className="form-control w-full mb-3 basis-1/2">
                      <div className="label">
                        <span className="label-text">市区町村</span>
                      </div>
                      <input
                        type="text"
                        className="input input-bordered w-full text-sm"
                        autoComplete="address-level2"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        required
                      />
                    </label>
                    <label className="form-control w-full mb-3 basis-1/2">
                      <div className="label">
                        <span className="label-text">町名・番地</span>
                      </div>
                      <input
                        type="text"
                        className="input input-bordered w-full text-sm"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        autoComplete="address-line1"
                        required
                      />
                    </label>
                  </div>
                  <div className="flex flex-col lg:flex-row lg:gap-5">
                    <label className="form-control w-full mb-3 basis-1/2">
                      <div className="label">
                        <span className="label-text">
                          建物（マンション・部屋番号）
                        </span>
                      </div>
                      <input
                        type="text"
                        className="input input-bordered w-full text-sm"
                        autoComplete="address-line2"
                        value={building}
                        onChange={(e) => setBuilding(e.target.value)}
                      />
                    </label>
                    <label className="form-control w-full mb-3 basis-1/2">
                      <div className="label">
                        <span className="label-text">電話番号</span>
                      </div>
                      <input
                        type="tel"
                        minLength="10"
                        className="input input-bordered w-full text-sm"
                        value={phoneNum}
                        onChange={(e) => setPhoneNum(e.target.value)}
                        required
                      />
                    </label>
                  </div>
                </div>
                {isLoading ? (
                  <button className="btn w-full mt-10">
                    <span className="loading loading-spinner"></span>
                    ローディング
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="w-full btn btn-primary mt-10"
                  >
                    生地見本を請求する
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FabricSampleFormScreen
