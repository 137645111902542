import { rootApi } from './rootApi'
import { CUSTOMIZE_TSHIRTS_URL } from '../../constants'

export const customizeTshirtsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomizeTshirts: builder.query({
      query: () => ({
        url: CUSTOMIZE_TSHIRTS_URL,
      }),
      keepUnusedDataFor: 5,
    }),
    getCustomizeTshirtDetails: builder.query({
      query: (tshirtId) => ({
        url: `${CUSTOMIZE_TSHIRTS_URL}/${tshirtId}`,
      }),
      keepUnusedDataFor: 5,
    }),
    createCustomizeTshirt: builder.mutation({
      query: () => ({
        url: CUSTOMIZE_TSHIRTS_URL,
        method: 'POST',
      }),
      invalidatesTags: ['Product'],
    }),
    updateCustomizeTshirt: builder.mutation({
      query: (data) => ({
        url: `${CUSTOMIZE_TSHIRTS_URL}/${data._id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Product'],
    }),
    createCustomeReview: builder.mutation({
      query: (data) => ({
        url: `${CUSTOMIZE_TSHIRTS_URL}/${data.tshirtId}/reviews`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Product'],
    }),
  }),
})

export const {
  useGetCustomizeTshirtsQuery,
  useGetCustomizeTshirtDetailsQuery,
  useCreateCustomizeTshirtMutation,
  useUpdateCustomizeTshirtMutation,
  useCreateCustomeReviewMutation,
} = customizeTshirtsApi
