import { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useRegisterMutation } from '../store/apis/usersApi'
import { setCredentials } from '../store/slices/authSlice'
import { useSendMailMutation } from '../store/apis/sendMailApi'
import { registarMail } from '../mail/registerMail'
import { toast } from 'react-toastify'
import Meta from '../components/Meta'

const RegisterScreen = () => {
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [firstnameYomi, setFirstnameYomi] = useState('')
  const [lastnameYomi, setLastnameYomi] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [register, { isLoading }] = useRegisterMutation()
  const [sendMail] = useSendMailMutation()

  const { userInfo } = useSelector((state) => state.auth)

  const { search } = useLocation()
  const sp = new URLSearchParams(search)
  const redirect = sp.get('redirect') || '/'

  useEffect(() => {
    if (userInfo) {
      navigate(redirect)
    }
  }, [userInfo, redirect, navigate])

  const registerHandler = async (e) => {
    e.preventDefault()

    if (password !== confirmPassword) {
      toast.error('パスワードが一致していません')
    } else {
      try {
        const res = await register({
          firstname,
          lastname,
          firstnameYomi,
          lastnameYomi,
          email,
          password,
        }).unwrap()
        dispatch(setCredentials({ ...res }))

        const msg = {
          to: email,
          from: 'info@mnsfabric.com',
          bcc: 'info@mnsfabric.com',
          subject: `${lastname} ${firstname} 様 新規会員登録ありがとうございます`,
          text: registarMail({
            firstname,
            lastname,
            firstnameYomi,
            lastnameYomi,
            email,
          }),
        }
        await sendMail(msg)
        navigate(redirect)
      } catch (error) {
        toast.error(error.data.message)
      }
    }
  }

  return (
    <>
      <Meta title="サインアップ | m&s Fabric" />
      <div className="container mt-14 lg:mt-20 flex justify-center mb-24">
        <div className="w-full md:w-96">
          <h2 className="page-title">Sign Up</h2>
          <p className="page-description">会員登録</p>
          <form onSubmit={registerHandler}>
            <div className="form-control w-full mb-5">
              <label className="label">
                <span className="label-text">お名前</span>
              </label>
              <div className="flex gap-3">
                <input
                  type="text"
                  placeholder="姓"
                  className="input input-bordered w-full"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  pattern="[\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFF9F\u4E00-\u9FFF]*"
                  title="日本語で入力してください"
                  required
                />
                <input
                  type="text"
                  placeholder="名"
                  className="input input-bordered w-full"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  pattern="[\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFF9F\u4E00-\u9FFF]*"
                  title="日本語で入力してください"
                  required
                />
              </div>
            </div>
            <div className="form-control w-full mb-5">
              <label className="label">
                <span className="label-text">お名前（フリガナ）</span>
              </label>
              <div className="flex gap-3">
                <input
                  type="text"
                  placeholder="セイ"
                  className="input input-bordered w-full"
                  value={lastnameYomi}
                  onChange={(e) => setLastnameYomi(e.target.value)}
                  pattern="^[ァ-ヶー]+$"
                  title="全角カタカナで入力してください"
                  required
                />
                <input
                  type="text"
                  placeholder="メイ"
                  className="input input-bordered w-full"
                  value={firstnameYomi}
                  onChange={(e) => setFirstnameYomi(e.target.value)}
                  pattern="^[ァ-ヶー]+$"
                  title="全角カタカナで入力してください"
                  required
                />
              </div>
            </div>
            <div className="form-control w-full mb-5">
              <label className="label">
                <span className="label-text">メールアドレス</span>
              </label>
              <input
                type="email"
                className="input input-bordered w-full"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-control w-full mb-5">
              <label className="label">
                <span className="label-text">パスワード</span>
              </label>
              <input
                type="password"
                className="input input-bordered w-full"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                minLength="6"
                required
              />
            </div>
            <div className="form-control w-full mb-5">
              <label className="label">
                <span className="label-text">パスワード確認</span>
              </label>
              <input
                type="password"
                className="input input-bordered w-full"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                minLength="6"
                required
              />
            </div>

            {isLoading ? (
              <button className="btn w-full mt-10">
                <span className="loading loading-spinner"></span>
                ローディング
              </button>
            ) : (
              <button type="submit" className="btn btn-primary w-full mt-10">
                サインアップ
              </button>
            )}
          </form>
          <div className="flex items-center mt-5">
            <button className="btn btn-outline btn-sm text-xs mr-3">
              <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
                ログイン
              </Link>
            </button>
            <p className="text-sm">登録済みの方はこちらから</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default RegisterScreen
