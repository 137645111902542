import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useUpdateUserMutation } from '../../store/apis/usersApi'

const CustomerInfoEdit = ({ user }) => {
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [firstnameYomi, setFirstnameYomi] = useState('')
  const [lastnameYomi, setLastnameYomi] = useState('')
  const [email, setEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)

  const [updateUser, { isLoading: loadingUpdate }] = useUpdateUserMutation()

  useEffect(() => {
    if (user) {
      setFirstname(user.firstname)
      setLastname(user.lastname)
      setFirstnameYomi(user.firstnameYomi)
      setLastnameYomi(user.lastnameYomi)
      setEmail(user.email)
      setIsAdmin(user.isAdmin)
    }
  }, [user])

  const submitHandler = async (e) => {
    e.preventDefault()

    try {
      await updateUser({
        userId: user._id,
        firstname,
        lastname,
        firstnameYomi,
        lastnameYomi,
        email,
        isAdmin,
      })
      toast.success('ユーザー情報を更新しました')
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  return (
    <div className="container mt-14 lg:mt-20 flex justify-center mb-24">
      <div className="w-full md:w-96">
        <form onSubmit={submitHandler}>
          <div className="form-control w-full mb-5">
            <label className="label">
              <span className="label-text">お名前</span>
            </label>
            <div className="flex gap-3">
              <input
                type="text"
                placeholder="姓"
                className="input input-bordered w-full text-sm"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                pattern="[\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFF9F\u4E00-\u9FFF]*"
                title="日本語で入力してください"
              />
              <input
                type="text"
                placeholder="名"
                className="input input-bordered w-full text-sm"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                pattern="[\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFF9F\u4E00-\u9FFF]*"
                title="日本語で入力してください"
              />
            </div>
          </div>
          <div className="form-control w-full mb-5">
            <label className="label">
              <span className="label-text">お名前（フリガナ）</span>
            </label>
            <div className="flex gap-3">
              <input
                type="text"
                placeholder="セイ"
                className="input input-bordered w-full text-sm"
                value={lastnameYomi}
                onChange={(e) => setLastnameYomi(e.target.value)}
                pattern="^[ァ-ヶー]+$"
                title="全角カタカナで入力してください"
              />
              <input
                type="text"
                placeholder="メイ"
                className="input input-bordered w-full text-sm"
                value={firstnameYomi}
                onChange={(e) => setFirstnameYomi(e.target.value)}
                pattern="^[ァ-ヶー]+$"
                title="全角カタカナで入力してください"
              />
            </div>
          </div>
          <div className="form-control w-full mb-5">
            <label className="label">
              <span className="label-text">メールアドレス</span>
            </label>
            <input
              type="email"
              className="input input-bordered w-full text-sm"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-control w-20">
            <label className="label cursor-pointer">
              <input
                type="checkbox"
                checked={isAdmin}
                className="checkbox"
                onChange={(e) => setIsAdmin(e.target.checked)}
              />
              <span className="label-text">管理者</span>
            </label>
          </div>

          {loadingUpdate ? (
            <button className="btn w-full mt-5">
              <span className="loading loading-spinner"></span>
              ローディング
            </button>
          ) : (
            <button type="submit" className="btn btn-primary w-full mt-5">
              更新する
            </button>
          )}
        </form>
      </div>
    </div>
  )
}

export default CustomerInfoEdit
