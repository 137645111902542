import { useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import axios from 'axios'
import LengthAdjustment from './LengthAdjustment'
import { changeCustomSlideState } from '../../store/slices/customSlideSlice'

const CustomLength = ({ changeLength, selectedValue, type }) => {
  const [selectedSizeLength, setSelectedSizeLength] = useState()
  const [changedSizeLength, setChangedSizeLength] = useState()
  const [calcSize, setCalcSize] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchSize = async () => {
      const { data } = await axios.get('/api/tshirts-size')
      data.forEach((size) => {
        if (size.name === selectedValue.selectedSize) {
          let fit = size.size.find((style) => style.name === 'Fit')
          setSelectedSizeLength(fit.spec.length)
          setChangedSizeLength(fit.spec.length)
        }
      })
    }
    fetchSize()
  }, [changeLength, selectedValue.selectedSize, setSelectedSizeLength])

  useEffect(() => {
    if (changedSizeLength - selectedSizeLength > 0) {
      setCalcSize(changedSizeLength - selectedSizeLength)
    } else {
      setCalcSize(changedSizeLength - selectedSizeLength)
    }
  }, [changedSizeLength, selectedSizeLength])

  const handleClick = (value) => {
    if (value === 'short') {
      setChangedSizeLength((prev) => prev - 1)
    } else if (value === 'long') {
      setChangedSizeLength((prev) => prev + 1)
    } else {
      setChangedSizeLength(selectedSizeLength)
    }
  }

  const changeLengthHandler = () => {
    changeLength(calcSize)
    dispatch(changeCustomSlideState())
  }

  return (
    <div className="w-full h-full pb-28 lg:pb-10 overflow-y-auto">
      <div className="flex flex-col justify-center items-center">
        <div className="w-[90%] lg:w-[65%] mb-5">
          <LengthAdjustment
            changedSizeLength={changedSizeLength}
            selectedValue={selectedValue}
            type={type}
          />
        </div>

        <div className="text-center text-2xl mb-12">
          <span className="text-sm mr-2">着丈調整サイズ：</span>
          {calcSize === 0 ? `±0` : calcSize > 0 ? `+${calcSize}` : calcSize}
        </div>

        <div className="flex justify-center gap-7 mb-10">
          <button
            className="btn btn-sm btn-outline rounded-full w-32"
            onClick={() => handleClick('short')}
            disabled={calcSize <= -10}
          >
            短くする
          </button>
          <button
            className="btn btn-sm btn-outline rounded-full w-32"
            onClick={() => handleClick('long')}
            disabled={calcSize >= 10}
          >
            長くする
          </button>
        </div>
        <button
          className="btn btn-primary btn-sm w-72"
          onClick={changeLengthHandler}
        >
          決定する
        </button>
      </div>
    </div>
  )
}

export default CustomLength
